import { useEffect, useState } from "react";
import ReactPixel from 'react-facebook-pixel';

function CookieBanner() {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    let cookieStatus = localStorage.getItem("cookieAccept") || "false";
    if (cookieStatus === "true") {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieAccept", JSON.stringify(true));
    setShowBanner(false);
    // ReactPixel.grantConsent()
  };

  const handleCancle = () => {
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <div className="bg-gray-800 text-white py-4 px-6 fixed bottom-0 left-0 right-0 z-50">
          <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
            <p className="text-sm text-justify">
              We use cookies to enhance your experience. By using our website,
              you consent to our use of cookies.
            </p>
            <div className="flex gap-4">
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-2 px-4 rounded focus:outline-none"
                onClick={handleAccept}
              >
                Accept
              </button>
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-2 px-4 rounded focus:outline-none"
                onClick={handleCancle}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CookieBanner;
