// src/actions/addressActions.js
import axios from "axios";
import {
  ADDRESS_CREATE_REQUEST,
  ADDRESS_CREATE_SUCCESS,
  ADDRESS_CREATE_FAIL,
  ADDRESS_LIST_REQUEST,
  ADDRESS_LIST_SUCCESS,
  ADDRESS_LIST_FAIL,
  ADDRESS_DETAILS_REQUEST,
  ADDRESS_DETAILS_SUCCESS,
  ADDRESS_DETAILS_FAIL,
  ADDRESS_UPDATE_REQUEST,
  ADDRESS_UPDATE_SUCCESS,
  ADDRESS_UPDATE_FAIL,
  ADDRESS_DELETE_REQUEST,
  ADDRESS_DELETE_SUCCESS,
  ADDRESS_DELETE_FAIL,
} from "../constants/addressConstants";
import { serverIp } from "../config/conf";

// Create a new address
export const createAddress = (addressData) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADDRESS_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${serverIp}/api/address`,
      { ...addressData, isDefault: false, user: userInfo._id },
      config
    );

    dispatch({ type: ADDRESS_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ADDRESS_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get address for the current user
export const getUserAddresses = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ADDRESS_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${serverIp}/api/address/user/${userInfo._id}`,
      config
    );

    dispatch({ type: ADDRESS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ADDRESS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get address details by ID
export const getAddressDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADDRESS_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${serverIp}/api/address/${id}`, config);

    dispatch({ type: ADDRESS_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ADDRESS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Update an address
export const updateAddress =
  (id, updatedData) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADDRESS_UPDATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${serverIp}/api/address/${id}`,
        updatedData,
        config
      );

      dispatch({ type: ADDRESS_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ADDRESS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Delete an address
export const removeAddress = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADDRESS_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`${serverIp}/api/address/${id}`, config);

    dispatch({ type: ADDRESS_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: ADDRESS_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
