import axios from 'axios';
import {
    SUB_CATEGORY_CREATE_REQUEST,
    SUB_CATEGORY_CREATE_SUCCESS,
    SUB_CATEGORY_CREATE_FAIL,
    SUB_CATEGORY_LIST_REQUEST,
    SUB_CATEGORY_LIST_SUCCESS,
    SUB_CATEGORY_LIST_FAIL,
    SUB_CATEGORY_DETAILS_REQUEST,
    SUB_CATEGORY_DETAILS_SUCCESS,
    SUB_CATEGORY_DETAILS_FAIL,
    SUB_CATEGORY_UPDATE_REQUEST,
    SUB_CATEGORY_UPDATE_SUCCESS,
    SUB_CATEGORY_UPDATE_FAIL,
    SUB_CATEGORY_DELETE_REQUEST,
    SUB_CATEGORY_DELETE_SUCCESS,
    SUB_CATEGORY_DELETE_FAIL
} from '../constants/subCategoryConstants';
import { serverIp } from '../config/conf';

export const createSubCategory = (subCategoryData) => async (dispatch, getState) => {
    try {
        dispatch({ type: SUB_CATEGORY_CREATE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.post(`${serverIp}/api/subcategory`, subCategoryData, config);

        dispatch({
            type: SUB_CATEGORY_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUB_CATEGORY_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const listSubCategory = () => async (dispatch) => {
    try {
        dispatch({ type: SUB_CATEGORY_LIST_REQUEST });

        const { data } = await axios.get(`${serverIp}/api/subcategory`);

        dispatch({
            type: SUB_CATEGORY_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUB_CATEGORY_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getSubCategoryDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: SUB_CATEGORY_DETAILS_REQUEST });

        const { data } = await axios.get(`${serverIp}/api/subcategory/${id}`);

        dispatch({
            type: SUB_CATEGORY_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUB_CATEGORY_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const updateSubCategory = (subCategoryData) => async (dispatch, getState) => {
    try {
        dispatch({ type: SUB_CATEGORY_UPDATE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.put(`${serverIp}/api/subcategory/${subCategoryData._id}`, subCategoryData, config);

        dispatch({
            type: SUB_CATEGORY_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: SUB_CATEGORY_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteSubCategory = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: SUB_CATEGORY_DELETE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`${serverIp}/api/subcategory/${id}`, config);

        dispatch({
            type: SUB_CATEGORY_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SUB_CATEGORY_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
