import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Message from "./Message";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import "./Category.css";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { listCollections } from "../actions/collectionActions";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <MdNavigateNext
      className={className}
      style={{
        color: "gray",
        fontSize: "45px",
        height: "55px",
        width: "55px",
        paddingLeft: "20px",
      }}
      onClick={onClick}
    ></MdNavigateNext>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <MdNavigateBefore
      className={className}
      style={{
        color: "gray",
        fontSize: "45px",
        height: "55px",
        width: "55px",
        paddingRight: "20px",
      }}
      onClick={onClick}
    ></MdNavigateBefore>
  );
}

export default function CollectionsNew() {
  const dispatch = useDispatch();

  const { loading, error, collections } = useSelector(
    (state) => state.collectionList
  );

  useEffect(() => {
    dispatch(listCollections());
  }, [dispatch]);

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    // prevArrow: <SamplePrevArrow />,
    // nextArrow: <SampleNextArrow />,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={`mx-auto max-w-7xl px-4 sm:px-6 lg:px-8`}>
      <p className="text-center text-xl font-semibold py-10">COLLECTIONS</p>
      {loading ? (
        <div></div>
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <Slider
          // prevArrow={<SamplePrevArrow />}
          // nextArrow={<SampleNextArrow />}
          {...settings}
        >
          {collections.map((catObj) => (
            <div className={`cursor-pointer px-3 md:px-6`}>
              <RouterLink
                className="block"
                to={`/product?collections=${catObj._id}`}
              >
                <img
                  src={catObj.thumbImage}
                  alt={catObj.name}
                  loading="lazy"
                  className="ease-in transition hover:scale-105"
                />
              </RouterLink>
              {/* <h1 className="text-[14px] font-[600] text-black text-center uppercase p-3">
                {catObj.name}
              </h1> */}
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
}
