import axios from "axios";
import {
  PRODUCT_SET_CREATE_REQUEST,
  PRODUCT_SET_CREATE_SUCCESS,
  PRODUCT_SET_CREATE_FAIL,
  PRODUCT_SET_LIST_REQUEST,
  PRODUCT_SET_LIST_SUCCESS,
  PRODUCT_SET_LIST_FAIL,
  PRODUCT_SET_DETAILS_REQUEST,
  PRODUCT_SET_DETAILS_SUCCESS,
  PRODUCT_SET_DETAILS_FAIL,
  PRODUCT_SET_UPDATE_REQUEST,
  PRODUCT_SET_UPDATE_SUCCESS,
  PRODUCT_SET_UPDATE_FAIL,
  PRODUCT_SET_DELETE_REQUEST,
  PRODUCT_SET_DELETE_SUCCESS,
  PRODUCT_SET_DELETE_FAIL,
} from "../constants/productSetConstants";
import { serverIp } from "../config/conf";

// Action to create a new Product Set
export const createProductSet =
  (productSetData) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_SET_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${serverIp}/api/productset`,
        productSetData,
        config
      );

      dispatch({
        type: PRODUCT_SET_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_SET_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Action to list all Product Sets
export const listProductSets = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_SET_LIST_REQUEST });

    const { data } = await axios.get(`${serverIp}/api/productset`);

    dispatch({
      type: PRODUCT_SET_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_SET_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Action to get a Product Set by ID
export const getProductSetById = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_SET_DETAILS_REQUEST });

    const { data } = await axios.get(`${serverIp}/api/productset/${id}`);

    dispatch({
      type: PRODUCT_SET_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_SET_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Action to update a Product Set
export const updateProductSet =
  (id, productSetData) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_SET_UPDATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${serverIp}/api/productset/${id}`,
        productSetData,
        config
      );

      dispatch({
        type: PRODUCT_SET_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_SET_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Action to delete a Product Set
export const deleteProductSet = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_SET_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`${serverIp}/api/productset/${id}`, config);

    dispatch({ type: PRODUCT_SET_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: PRODUCT_SET_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
