export const SUPPORT_DETAILS_REQUEST = 'SUPPORT_DETAILS_REQUEST';
export const SUPPORT_DETAILS_SUCCESS = 'SUPPORT_DETAILS_SUCCESS';
export const SUPPORT_DETAILS_FAIL = 'SUPPORT_DETAILS_FAIL';

export const SUPPORT_UPDATE_REQUEST = 'SUPPORT_UPDATE_REQUEST';
export const SUPPORT_UPDATE_SUCCESS = 'SUPPORT_UPDATE_SUCCESS';
export const SUPPORT_UPDATE_FAIL = 'SUPPORT_UPDATE_FAIL';
export const SUPPORT_UPDATE_RESET = 'SUPPORT_UPDATE_RESET';

export const FAQ_LIST_REQUEST = 'FAQ_LIST_REQUEST';
export const FAQ_LIST_SUCCESS = 'FAQ_LIST_SUCCESS';
export const FAQ_LIST_FAIL = 'FAQ_LIST_FAIL';

export const FAQ_DETAILS_REQUEST = 'FAQ_DETAILS_REQUEST';
export const FAQ_DETAILS_SUCCESS = 'FAQ_DETAILS_SUCCESS';
export const FAQ_DETAILS_FAIL = 'FAQ_DETAILS_FAIL';

export const FAQ_DELETE_REQUEST = 'FAQ_DELETE_REQUEST';
export const FAQ_DELETE_SUCCESS = 'FAQ_DELETE_SUCCESS';
export const FAQ_DELETE_FAIL = 'FAQ_DELETE_FAIL';

export const FAQ_CREATE_REQUEST = 'FAQ_CREATE_REQUEST';
export const FAQ_CREATE_SUCCESS = 'FAQ_CREATE_SUCCESS';
export const FAQ_CREATE_FAIL = 'FAQ_CREATE_FAIL';
export const FAQ_CREATE_RESET = 'FAQ_CREATE_RESET';

export const FAQ_UPDATE_REQUEST = 'FAQ_UPDATE_REQUEST';
export const FAQ_UPDATE_SUCCESS = 'FAQ_UPDATE_SUCCESS';
export const FAQ_UPDATE_FAIL = 'FAQ_UPDATE_FAIL';
export const FAQ_UPDATE_RESET = 'FAQ_UPDATE_RESET';