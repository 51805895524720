import { LockClosedIcon } from "@heroicons/react/20/solid";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";

import {
  login,
  register,
  validateOtpUser,
  forgotPassword as forgotpass,
} from "../actions/userActions";
import { USER_OTP_VALIDATE_RESET } from "../constants/userConstants";
import { scrollToTop } from "../components/ScrollToTop";
import Message from "../components/Message";

export default function OtpScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [seconds, setSeconds] = useState(300);
  const [resendTime, setResendTime] = useState(120);

  const [searchParams] = useSearchParams();

  let type = searchParams.get("type");

  const [email, setEmail] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const inputRefs = useRef([]);

  const userOtpValidate = useSelector((state) => state.userOtpValidate);
  const { success, error } = userOtpValidate;

  const forgotPassword = useSelector((state) => state.forgotPassword);
  const { successOTP, email: emailOTP } = forgotPassword;

  const loginDataFromStorage = JSON.parse(localStorage.getItem("userInfo"));

  const resisterDataFromStorage = JSON.parse(
    localStorage.getItem("userInfoRegister")
  );

  useEffect(() => {
    scrollToTop();
    dispatch({ type: USER_OTP_VALIDATE_RESET });
    if (type === "register") {
      if (resisterDataFromStorage && resisterDataFromStorage.email) {
        setEmail(resisterDataFromStorage.email);
      }
    } else if (type === "signup") {
      if (loginDataFromStorage && loginDataFromStorage.email) {
        setEmail(loginDataFromStorage.email);
      }
    } else if (type === "forgotpass") {
      if (successOTP && emailOTP) {
        setEmail(emailOTP);
      }
    }
  }, [successOTP, emailOTP]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    if (seconds === 0) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    const resendInterval = setInterval(() => {
      setResendTime((prevSeconds) => prevSeconds - 1);
    }, 1000);

    if (resendTime === 0) {
      clearInterval(resendInterval);
    }
    return () => {
      clearInterval(resendInterval);
    };
  }, [resendTime]);

  const formattedTime = (seconds) => {
    return `${Math.floor(seconds / 60)}:${(seconds % 60).toLocaleString(
      "en-US",
      {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }
    )}`;
  };

  useEffect(() => {
    if (success) {
      dispatch({ type: USER_OTP_VALIDATE_RESET });
      if (type === "register" || type === "signup") {
        navigate("/signup");
      } else if (type === "forgotpass") {
        navigate("/resetPass");
      }
    }
  }, [success]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(validateOtpUser(email, otpValue, type));
  };

  function handleInput(inputIndex, event) {
    const { value } = event.target;
    const updatedOtpValue =
      otpValue.substring(0, inputIndex) +
      value +
      otpValue.substring(inputIndex + 1);
    setOtpValue(updatedOtpValue);

    const prevInput = inputRefs.current[inputIndex - 1];
    const nextInput = inputRefs.current[inputIndex + 1];

    if (
      event.nativeEvent.inputType === "deleteContentBackward" &&
      !value &&
      prevInput
    ) {
      prevInput.focus();
    } else if (value && nextInput) {
      nextInput.focus();
    }
  }

  function handleKeyDown(event, inputIndex) {
    const input = event.target;
    const maxLength = parseInt(input.getAttribute("maxLength"));

    if (event.key !== "Backspace" && input.value.length >= maxLength) {
      const nextInput = inputRefs.current[inputIndex + 1];

      if (nextInput) {
        event.preventDefault();
        nextInput.focus();
      }
    }
  }

  const handleResend = () => {
    if (resendTime === 0) {
      if (type === "register") {
        dispatch(register("", email, ``, ""));
      } else if (type === "signup") {
        dispatch(login(email, ""));
      } else if (type === "forgotpass") {
        dispatch(forgotpass(email));
      }
      setSeconds(300);
      setResendTime(120);
      dispatch({ type: USER_OTP_VALIDATE_RESET });
    }
  };

  return (
    <div className="flex min-h-full items-center justify-center bg-white mt-2">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">
          Verify with OTP
        </h2>
        <p className="text-gray-600 mb-6">
          Please check your email inbox for the OTP we sent just now. If you do
          not find the OTP in the inbox, please make sure to check the spam box
          and other places for the OTP.
        </p>
        <div className="text-center text-2xl text-[#8e295b] mb-6">
          {formattedTime(seconds)}
        </div>
        <form className="mt-8 space-y-6" onSubmit={submitHandler}>
          <div className="flex gap-4 justify-center mb-8">
            <input
              ref={(ref) => (inputRefs.current[0] = ref)}
              type="text"
              maxLength="1"
              className="w-8 h-8 p-2 border border-solid border-black"
              value={otpValue.charAt(0)}
              onChange={(e) => handleInput(0, e)}
              onKeyDown={(e) => handleKeyDown(e, 0)}
            />
            <input
              ref={(ref) => (inputRefs.current[1] = ref)}
              type="text"
              maxLength="1"
              className="w-8 h-8 p-2 border border-solid border-black"
              value={otpValue.charAt(1)}
              onChange={(e) => handleInput(1, e)}
              onKeyDown={(e) => handleKeyDown(e, 1)}
            />
            <input
              ref={(ref) => (inputRefs.current[2] = ref)}
              type="text"
              maxLength="1"
              className="w-8 h-8 p-2 border border-solid border-black"
              value={otpValue.charAt(2)}
              onChange={(e) => handleInput(2, e)}
              onKeyDown={(e) => handleKeyDown(e, 2)}
            />
            <input
              ref={(ref) => (inputRefs.current[3] = ref)}
              type="text"
              maxLength="1"
              className="w-8 h-8 p-2 border border-solid border-black"
              value={otpValue.charAt(3)}
              onChange={(e) => handleInput(3, e)}
              onKeyDown={(e) => handleKeyDown(e, 3)}
            />
          </div>
          <div className="flex w-full justify-end items-center mb-6">
            <button
              type="button"
              onClick={handleResend}
              disabled={formattedTime(resendTime) !== "0:00"}
              className="flex gap-1 text-[#8e295b] hover:text-[#6b1d45] font-semibold text-sm disabled:text-gray-400 disabled:cursor-not-allowed"
            >
              <p>Resend OTP</p>
              <p
                className={`${
                  formattedTime(resendTime) === "0:00" ? "hidden" : "block"
                }`}
              >
                in {formattedTime(resendTime)}
              </p>
            </button>
          </div>
          <button
            type="submit"
            className="w-full bg-[#8e295b] text-white py-3 rounded-md font-semibold text-lg hover:bg-[#6b1d45]"
          >
            Verify
          </button>
          <div className="text-center text-[#8e295b] mt-4 text-sm">
            <RouterLink to="/supportFAQ" className="hover:underline">
              Having trouble logging in? Get Help
            </RouterLink>
          </div>
          {error && (
            <Message type="error" className="text-red-500 text-sm mt-4">
              {error}
            </Message>
          )}
        </form>
      </div>
    </div>
  );
}
