import React from 'react'

const ImageEngravingSection = ({uploadImageHandler,setengImage}) => {
  return (
    <div className="mb-4">
    <label
      htmlFor="image"
      className="block text-gray-700"
    >
      Upload Image For Engraving
    </label>
    <input
      type="file"
      id="image"
      onChange={(e) =>
        uploadImageHandler(
          e,
          setengImage
        )
      }
      className="w-full p-2 border border-gray-300 rounded"
    />
  </div>
  )
}

export default ImageEngravingSection