import React from 'react'

const MeasuringGuide = () => {
  return (
    <div>
         <p className="flex w-full justify-center items-center text-lg font-bold py-8">
                                        If you dont have ring
                                      </p>
                                      <div className="grid grid-cols-2 gap-8">
                                        <div className="flex justify-center items-center">
                                          <img
                                            src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-2.png"
                                            alt="instructions"
                                            className="h-[120px]"
                                          />
                                        </div>
                                        <div>
                                          Take a small piece of string or thread
                                          or ribbon. Wrap it around your finger.
                                        </div>

                                        <div className="flex justify-center items-center">
                                          <img
                                            src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-3.webp"
                                            alt="instructions"
                                            className="h-[120px]"
                                          />
                                        </div>
                                        <div>
                                          Mark the spot where the thread meets.
                                        </div>

                                        <div className="flex justify-center items-center">
                                          <img
                                            src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-4.webp"
                                            alt="instructions"
                                            className="h-[120px]"
                                          />
                                        </div>
                                        <div>
                                          Measure the length of the string with
                                          your ruler.
                                        </div>
                                      </div>

                                      <p className="flex w-full justify-center items-center pt-8">
                                        --------------------------------------------------------------------------------------------
                                      </p>

                                      <p className="flex w-full justify-center items-center text-lg font-bold pb-8">
                                        If you have a ring
                                      </p>
                                      <div className="grid grid-cols-2 gap-8">
                                        <div className="flex justify-center items-center">
                                          <img
                                            src="https://d8v879q3glvao.cloudfront.net/Static_images/Ring-size-guide-ruler.jpg"
                                            alt="instructions"
                                            className="h-[120px]"
                                          />
                                        </div>
                                        <div>
                                          1 : Place the ring
                                          <br />2 : Measure the inner diameter
                                          within inner edges of your ring with a
                                          ruler.
                                        </div>
                                      </div>
    </div>
  )
}

export default MeasuringGuide