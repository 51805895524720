import Category from "../components/Category";
import Features from "../components/Features";
import Testimonials from "../components/Testimonials";
import ShopInsta from "../components/ShopInsta";
import Hero from "../components/Hero";
import CookieBanner from "../components/CookieBanner";
import { useEffect } from "react";
import { scrollToTop } from "../components/ScrollToTop";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { listProducts } from "../actions/productActions";
import PromoProductCard from "../components/PromoProductList";
import Collections from "../components/Collections";
import Loader from "../components/Loader";
import Message from "../components/Message";
import NewArrivals from "../components/NewArrivals";
import CollectionsNew from "../components/CollectionsNew";
import OccasionalStyles from "../components/OccasionalStyles";
import JewellerySets from "../components/JewellerySets";
import PetCollection from "../components/PetCollection";
import HomeBanner from "../components/HomeBanner";

export default function Home() {
  useEffect(() => {
    scrollToTop();
  }, []);

  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const {
    loading: loadingFruits,
    products: fruits,
    error: errorFruits,
  } = productList;

  const { loading, error, menuLinks } = useSelector(
    (state) => state.menuLinkList
  );

  useEffect(() => {
    dispatch(
      listProducts(
        null,
        null,
        null,
        true,
        null,
        null,
        null,
        null,
        "latest",
        "desc",
        null
      )
    );
  }, [dispatch]);

  return (
    <div className="bg-white">
      <Hero cat={"main"} />

      <HomeBanner category={"category"} />

      <Category />

      <HomeBanner category={"newArrivals"} />

      <NewArrivals />

      <HomeBanner category={"homeJewellerySet"} />

      <JewellerySets />

      <HomeBanner category={"occasional"} />

      <OccasionalStyles />

      <HomeBanner category={"collections"} />

      <CollectionsNew />

      <HomeBanner category={"petCollection"} />

      <PetCollection />

      <HomeBanner category={"latestColl"} />

      {/* {loading ? (
        <Loader />
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        menuLinks
          .filter((menu) => menu.submenu)
          .map((menu) => (
            <PromoProductCard
              name={menu.name}
              products={fruits
                ?.filter((prod) => prod.category === menu.name)
                .slice(0, 10)}
              sts={fruits?.filter((prod) => prod.category === menu.name).length}
              loading={loadingFruits}
              error={errorFruits}
            />
          ))
      )} */}

      <Collections />

      {/* <Testimonials /> */}

      {/* <div className='border-b'>
                <ShopInsta />
            </div> */}

      <Features />

      <CookieBanner />
    </div>
  );
}
