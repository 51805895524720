import React, { useEffect, useState } from "react";
import { MdInfoOutline } from "react-icons/md";

const ProductSet = ({
  product,
  variantId,
  productSet,
  setProductSet,
  selectedItems,
  setSelectedItems,
  selectedItemBasePrice,
  setSelectedItemBasePrice,
  totalPrice,
  totalbasePrice,
  allowRingSize,
  setAllowRingSize,
}) => {
  // const [productSet, setProductSet] = useState([]);
  // const [productSetError, setProductSetError] = useState("");
  // const [productType, setProductType] = useState([]);

  // const [allowRingSize, setAllowRingSize] = useState(false);

  const handleSetSelection = (
    setID,
    sku,
    itemPrice,
    itemBasePrice,
    isChecked
  ) => {
    
    setSelectedItems((prev) => {
      if (isChecked) {
        return [...prev, itemPrice];
      } else {
        return prev.filter((price) => price !== itemPrice);
      }
    });

    setSelectedItemBasePrice((prev) => {
      if (isChecked) {
        return [...prev, itemBasePrice];
      } else {
        return prev.filter((basePrice) => basePrice !== itemBasePrice);
      }
    });

    if (productSet.length > 0) {
      // if(productSet.length)

      if (productSet.some((item) => item === setID)) {
        if (sku.toUpperCase().includes("SAIR")) {
          setAllowRingSize(false);
        }
        setProductSet((prev) => {
          let prevArr = prev.filter((val) => val !== setID);
          // console.log("im in remove", prevArr);

          return prevArr;
        });
      } else {
        if (sku.toUpperCase().includes("SAIR")) {
          setAllowRingSize(true);
        }
        setProductSet((prev) => {
          let prevArr = [...prev];

          prevArr.push(setID);
          // console.log("im in new add", prevArr);

          return prevArr;
        });
      }
    } else {
      setProductSet((prev) => {
        let prevArr = [...prev];

        prevArr.push(setID);
        console.log("im in fresh add", prevArr);

        return prevArr;
      });

      if (sku.toUpperCase().includes("SAIR")) {
        setAllowRingSize(true);
      }
    }
  };


  useEffect(()=>{

    if(productSet.length === product.products.length){
   setProductSet([]);
   setSelectedItems([]);
   setSelectedItemBasePrice([]);
   setAllowRingSize(false);
   console.log('Disable');
}

},[productSet])

  // console.log("Base prices",selectedItemBasePrice);

  return (
    <>
      <div className="">
        <div className="flex flex-col  gap-2">
          <div className="flex items-center gap-2">
            <label htmlFor="quantitySelect">Customized Jewellery Set:</label>
            <MdInfoOutline className="text-[19px] text-zinc-400" />
          </div>
          {/* <p className="text-[14px] text-red-500">
                                {productSetError}
                              </p> */}
        </div>
        <div className="flex flex-col gap-3 pt-4">
          {product?.products?.map((item) => (
            <div className="flex justify-start items-center gap-3">
              <input
                type="checkbox"
                name={item._id}
                value={item._id}
                className="rounded-full text-[#000080]"
                checked={
                  productSet.some((val) => val === item._id)
                  // ,
                  // selectedItems.includes(item.price)
                }
                // disabled={product?.products.length === productSet?.length}
                onChange={(e) =>
                  handleSetSelection(
                    item._id,
                    item.sku,
                    item.price,
                    item.basePrice,
                    e.target.checked
                  )
                }
              />
              <div className="flex justify-center items-center gap-3">
                <div className=" border border-zinc-300">
                  <img
                    src={
                      item.variants?.find((vari) => {
                        // Find the variant that matches the selected variantId
                        const selectedVariant = product.variants?.find(
                          (variant) => variant._id === variantId
                        );

                        if (
                          selectedVariant &&
                          selectedVariant.color === vari.color
                        ) {
                          return true; // Return the matching variant
                        }

                        return false; // If no match, return false
                      })?.images[0]
                    }
                    alt={item.name}
                    className="w-[50px]"
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <h1 className="uppercase">{item.name}</h1>
                  <div className="flex justify-start items-center gap-2">
                    <h1 className="text-sm text-center md:text-base font-bold text-gray-900">
                      <span>₹ {item.price?.toLocaleString("en-IN")}</span>
                    </h1>
                    <p className="inline-block p-1 text-[10px] line-through text-gray-400">
                      {" "}
                      <span>₹ {item.basePrice?.toLocaleString("en-IN")}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {selectedItems.length > 0 && (
          <div className="flex items-center gap-2 pt-4">
            <label htmlFor="quantitySelect">Total Amount:</label>
            <h1 className="text-sm text-center md:text-base font-bold text-gray-900">
              ₹ {totalPrice.toLocaleString("en-IN")}
            </h1>
            {/* <h1 className="text-sm text-center md:text-base font-bold text-gray-900">
              ₹ {totalbasePrice.toLocaleString("en-IN")}
            </h1> */}
          </div>
        )}
      </div>
    </>
  );
};

export default ProductSet;
