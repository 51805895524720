export const COLLECTION_CREATE_REQUEST = 'COLLECTION_CREATE_REQUEST';
export const COLLECTION_CREATE_SUCCESS = 'COLLECTION_CREATE_SUCCESS';
export const COLLECTION_CREATE_FAIL = 'COLLECTION_CREATE_FAIL';
export const COLLECTION_CREATE_RESET = 'COLLECTION_CREATE_RESET';

export const COLLECTION_LIST_REQUEST = 'COLLECTION_LIST_REQUEST';
export const COLLECTION_LIST_SUCCESS = 'COLLECTION_LIST_SUCCESS';
export const COLLECTION_LIST_FAIL = 'COLLECTION_LIST_FAIL';

export const COLLECTION_DETAILS_REQUEST = 'COLLECTION_DETAILS_REQUEST';
export const COLLECTION_DETAILS_SUCCESS = 'COLLECTION_DETAILS_SUCCESS';
export const COLLECTION_DETAILS_FAIL = 'COLLECTION_DETAILS_FAIL';

export const COLLECTION_UPDATE_REQUEST = 'COLLECTION_UPDATE_REQUEST';
export const COLLECTION_UPDATE_SUCCESS = 'COLLECTION_UPDATE_SUCCESS';
export const COLLECTION_UPDATE_FAIL = 'COLLECTION_UPDATE_FAIL';
export const COLLECTION_UPDATE_RESET = 'COLLECTION_UPDATE_RESET';

export const COLLECTION_DELETE_REQUEST = 'COLLECTION_DELETE_REQUEST';
export const COLLECTION_DELETE_SUCCESS = 'COLLECTION_DELETE_SUCCESS';
export const COLLECTION_DELETE_FAIL = 'COLLECTION_DELETE_FAIL';
