import React, { useState, useEffect } from "react";
import axios from "axios";

const ShopInsta = () => {
  const [token, setToken] = useState("");
  const [posts, setPosts] = useState([]);
  const [displayedPosts, setDisplayedPosts] = useState(4);

  useEffect(() => {
    const accessToken =
      "IGQWRQMEtGdF9oTGZADZAk5sUU1QMnd3TUx4STc5T1ZAOQnhYelZALazRrRTZAIN201U1RvUFdxd1JQTW9hQjA2WldxUFk5R2s1bEc0NWp2aHRnN1lydXIyMk4xcnI2VjFvTURISmZARcFNqSEhYdHNOeGhiTXA3OTBKdnMZD";

    const refreshEndpoint = `https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=${accessToken}`;
    const endpoint = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=${accessToken}`;

    axios
      .get(refreshEndpoint)
      .then((response) => {
        setToken(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(endpoint)
      .then((response) => {
        setPosts(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="container mx-auto p-4 md:p-8">
      <p className="text-center text-xl font-semibold pb-4">
        INSTAGRAM LOOKBOOK
      </p>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-8">
        {posts.slice(0, displayedPosts).map((post) => (
          <div
            key={post.id}
            className="bg-white rounded-lg shadow-lg overflow-hidden"
          >
            {post.media_type === "IMAGE" ||
            post.media_type === "CAROUSEL_ALBUM" ? (
              <img
                className="w-full aspect-[1/1] object-cover"
                src={post.media_url}
                alt={post.caption}
              />
            ) : (
              <video className="w-full aspect-[1/1]" controls>
                <source src={post.media_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            <div className="p-4">
              <p className="text-gray-700 text-sm mb-2 line-clamp-4">
                {post.caption}
              </p>
              <a
                href={post.permalink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-pink-500 font-bold hover:underline"
              >
                View on Instagram
              </a>
            </div>
          </div>
        ))}
      </div>
      {displayedPosts < posts.length && (
        <div className="flex w-full justify-center mt-4">
          <a
            href="https://www.instagram.com/sapphire_365/"
            target="_blank"
            className="bg-pink-500 text-white font-bold p-2 rounded-md shadow-md"
          >
            Show More...
          </a>
        </div>
      )}
    </div>
  );
};

export default ShopInsta;
