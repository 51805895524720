import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  Text,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";
import useRazorpay from "react-razorpay";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET,
} from "../constants/orderConstants";

import {
  deliverOrder,
  getOrderDetails,
  payOrder,
} from "../actions/orderActions";
import ReactToPrint from "react-to-print";
import { scrollToTop } from "../components/ScrollToTop";
import Sidebar from "../components/Sidebar";
import Loader from "../components/Loader";

const steps = [
  { title: "Received", description: "" },
  { title: "Confirmed", description: "" },
  { title: "Shipped", description: "" },
  { title: "Delivered", description: "" },
];

class OrderPrint extends React.Component {
  render() {
    // const [engraveMen,setEngraveMen] = useState(0)
    const { order } = this.props;

    console.log(order);

    const totalPrice = order.orderItems?.reduce(
      (sum, orderObj) => sum + orderObj.price * orderObj.qty,
      0
    );

    const totalIGST = order.orderItems?.reduce(
      (sum, orderObj) => sum + orderObj.price * orderObj.qty * 0.03,
      0
    );

    const totalEngravingCharge =
      order.orderItems?.reduce(
        (acc, item) =>
          item?.engraveMen.length === 0
            ? acc + 10 * item?.engraveMen.length * item.qty
            : 0,
        0
      ) +
      order.orderItems?.reduce(
        (acc, item) =>
          item?.engraveWomen.length === 0
            ? acc + 10 * item?.engraveWomen.length * item.qty
            : 0,
        0
      ) +
      order.orderItems?.reduce(
        (acc, item) =>
          item?.backEng.length === 0
            ? acc + 10 * item?.backEng.length * item.qty
            : 0,
        0
      );

    // console.log("Total Engraving: ",(totalEngravingCharge).toFixed(2));

    // const totalGiftingCharges = order.orderItems?.reduce((sum,giftChg)=> sum + giftChg.giftWrapTotalPrice ,0)

    // console.log(totalGiftingCharges);

    const subtotal =
      totalPrice + totalIGST + totalEngravingCharge + order.giftWrapTotalPrice;

    const totalDiscount = order.orderItems?.reduce((sum, orderObj) => {
      const discountPercentage = orderObj.discount || 0; // Default to 0 if discount is missing
      const itemTotal = orderObj.price * orderObj.qty;
      return sum + (itemTotal * discountPercentage) / 100;
    }, 0);

    const discountedPrice = subtotal - totalDiscount;

    const bigTotal = subtotal - discountedPrice;

    console.log("order here", order);
    return (
      <html className="font-sans">
        <head>
          <title>Your Invoice</title>
        </head>

        <body className="p-12 bg-white">
          <div className="relative max-w-800 mx-auto p-[20px] border-1 border-solid border-gray-300">
            <div className="text-center">
              <h1 className="text-4xl">TAX Invoice</h1>
            </div>

            <table className="w-full border-collapse mt-[20px] text-[10px]">
              <tbody>
                <tr>
                  <td colSpan="9" className="border p-[10px]">
                    <div className="w-full flex justify-between item-center">
                      <div>
                        <h1 className="font-[700]">
                          Order ID :{" "}
                          <span className="font-[400]">{order.order_id}</span>
                        </h1>
                      </div>
                      <div>
                        <h1 className="font-[700]">
                          Paid on Date :{" "}
                          <span className="font-[400]">
                            {order.createdAt?.substring(0, 10)}
                          </span>
                        </h1>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="9" className="border p-[10px]">
                    <div className="w-full flex flex-col gap-5">
                      <div className="flex justify-between items-start text-[12px]">
                        <div className="flex flex-col justify-start">
                          <img
                            src="https://d8v879q3glvao.cloudfront.net/products/1730092773960.png"
                            className="w-[150px] h-15"
                          />
                          <p className="max-w-[400px]">
                            A Soradis Group Venture under Soradis Jewellers
                            Private Limited.
                          </p>
                        </div>
                        <div className="w-full flex flex-col items-end pt-[15px]">
                          <p>Invoice: {order.order_id}</p>

                          <p>Created: {order.createdAt?.substring(0, 10)}</p>
                        </div>
                      </div>
                      <div className="flex justify-between items-start text-[12px]">
                        <div className="w-full flex flex-col justify-start">
                          <h1 className="font-[700]">From,</h1>
                          <p>Soradis Jewellers Private Limited,</p>
                          <p>2029, 1Aerocity Business Park,</p>
                          <p>Andheri(E), Mumbai, Maharashtra, India.</p>
                          PIN - 400072.
                          <p>GSTIN 27ABJCS3957R1ZA</p>
                          <p>Importer-Exporter Code (IEC) ABJCS3957R</p>
                        </div>
                        <div className="w-full flex flex-col items-end">
                          <h1 className="font-[700]">To,</h1>
                          <p>{order.user.firstName}</p>
                          <p> {order.user.email}</p>
                          <p className="flex">
                            {order.shippingAddress.address},
                          </p>
                          <p className="flex">
                            {order.shippingAddress.city}-
                            {order.shippingAddress.postalCode},{" "}
                            {order.shippingAddress.country}
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="border p-[10px] font-[700]">Image</td>
                  <td className="border p-[10px] font-[700]">SKU</td>
                  <td className="border p-[10px] font-[700]">HSN</td>
                  <td className="border p-[10px] font-[700]">Product Name</td>
                  <td className="border p-[10px] font-[700]">Quantity</td>
                  <td className="border p-[10px] font-[700]">Size</td>
                  <td className="border p-[10px] font-[700]">Unit Price</td>
                  <td className="border p-[10px] font-[700]">Total</td>
                  <td className="border p-[10px] font-[700]">IGST</td>
                </tr>
                {order.orderItems?.map((orderObj) => (
                  <tr key={orderObj.name}>
                    <td className="border p-[10px]">
                      <img
                        src={orderObj.image}
                        alt="image"
                        height="50px"
                        width="50px"
                      />
                    </td>
                    <td className="border p-[10px]">{orderObj.sku}</td>
                    <td className="border p-[10px]">71131120</td>
                    <td className="border p-[10px]">{orderObj.name}</td>
                    <td className="border p-[10px]">{orderObj.qty}</td>
                    <td className="border p-[10px]">
                      {orderObj.sizeWomen || orderObj.sizeMen}
                    </td>
                    <td className="border p-[10px]">{orderObj.price}</td>
                    <td className="border p-[10px]">
                      ₹ {orderObj.price * orderObj.qty}
                    </td>
                    <td className="border p-[10px]">
                      ₹ {(orderObj.price * orderObj.qty * 0.03).toFixed(2)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={"9"} className="border p-[10px]">
                    <div className="w-full flex flex-col gap-2">
                      <div className="w-full flex justify-between items-center">
                        <h1 className="text-[12px] font-[700]">
                          Item Total Amount :
                        </h1>
                        <p>{totalPrice.toFixed(2)}</p>
                      </div>
                      <div className="w-full flex justify-between items-center">
                        <h1 className="text-[12px] font-[700]">
                          Engraving Charge :
                        </h1>
                        <p>{totalEngravingCharge.toFixed(2)}</p>
                        {/* <p>0.00</p> */}
                      </div>
                      <div className="w-full flex justify-between items-center">
                        <h1 className="text-[12px] font-[700]">
                          Gifting Charge :
                        </h1>
                        <p>{order.giftWrapTotalPrice.toFixed(2)}</p>
                        {/* <p>0.00</p> */}
                      </div>
                      <div className="w-full flex justify-between items-center">
                        <h1 className="text-[12px] font-[700]">
                          Total IGST Incl. :
                        </h1>
                        <p>{totalIGST.toFixed(2)}</p>
                      </div>
                      <div className="w-full h-0 border-t border-gray-900">
                        &nbsp;
                      </div>
                      <div className="w-full flex justify-between items-center ">
                        <h1 className="text-[12px] font-[700]">Sub Total :</h1>
                        <p>{subtotal.toFixed(2)}</p>
                      </div>
                      <div className="w-full flex justify-between items-center">
                        <h1 className="text-[12px] font-[700]">
                          Coupon Discount :
                        </h1>
                        <p>{discountedPrice.toFixed(2)}</p>
                      </div>
                      <div className="w-full h-0 border-t border-gray-900">
                        &nbsp;
                      </div>
                      <div className="w-full flex justify-between items-center ">
                        <h1 className="text-[12px] font-[700]">Total :</h1>
                        <p>{bigTotal.toFixed(2)}</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* <p>Total: ₹ {totalPrice}</p>
            <p>Total IGST: ₹ {totalIGST}</p> */}

            {/* <div className="p-[10px] flex justify-end items-center gap-3">
              <p>Total: ₹ {totalPrice}</p>
              <p>Total IGST: ₹ {totalIGST}</p>
            </div> */}

            <div className="w-full text-[12px] fixed bottom-0 left-0 p-[10px] text-center ">
              <p>
                This is a system generated order receipt created by SAIRAH, a
                venture of Soradis Group under Soradis Jewellers Private
                Limited. This document does not require any digital signature
                for authentication.
              </p>
            </div>
          </div>
        </body>
      </html>
    );
  }
}

const OrderScreen = () => {
  const [Razorpay] = useRazorpay();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: orderId } = useParams();
  const componentRef = useRef();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { data: order, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { success: successDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { activeStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  if (!loading) {
    order.itemsPrice = order.orderItems.reduce(
      (acc, currVal) => acc + currVal.price * currVal.qty,
      0
    );
  }

  useEffect(() => {
    scrollToTop();
    if (!userInfo) {
      navigate(`/signup?redirect=/order/${orderId}`);
    } else {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });

      if (!order || successPay) {
        dispatch({ type: ORDER_PAY_RESET });
        dispatch({ type: ORDER_DELIVER_RESET });
        dispatch(getOrderDetails(orderId));
      }
    }
  }, [dispatch, orderId, successPay, order, successDeliver]);

  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(orderId, paymentResult));
  };

  const handlePayment = useCallback(
    async (order) => {
      // const order = await createOrder(params);

      console.log(order.totalPrice);
      const minimumAmount = 1.0;
      const validatedTotalPrice = Math.max(order.totalPrice, minimumAmount);
      const options = {
        // key: "rzp_test_I4PMvx8lJvNsZf",
        // key: "rzp_test_Ain4xsWuc4rqYm",
        key: "rzp_live_34yru4KVh9F2KW",
        amount: validatedTotalPrice * 100,
        currency: "INR",
        name: "Sairah",
        description: "Transaction",
        image: "https://sairahjewels.com/logo/png/black/black-r@4x.png",
        order_id: order.paymentResult.id,
        handler: (res) => {
          successPaymentHandler({
            order_id: res.razorpay_order_id,
            payment_id: res.razorpay_payment_id,
            update_time: Date.now(),
            email_address: order.user.email,
            razorpay_signature: res.razorpay_signature,
          });
        },
        prefill: {
          name: order.user.firstName,
          email: order.user.email,
          contact: order.user.phone,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#000080",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    },
    [Razorpay]
  );

  const printHandler = () => {
    if (componentRef && componentRef.current) {
      componentRef.current.print();
    }
  };

  return (
    <div>
      {/* <Sidebar menu="My Orders" />
      <section id="content">
        <main> */}
      {loading ? (
        <Loader />
      ) : error ? (
        <div type="error">{error}</div>
      ) : (
        <Flex bgColor="white">
          <Flex w="full" direction="column" p={{ sm: "3", md: "10" }}>
            <Grid
              templateColumns={{ sm: "1fr", md: "3fr 2fr" }}
              gap={{ sm: "8", md: "20" }}
            >
              <Flex direction="column">
                <Box borderBottom="1px" pb="6" borderColor="gray.300">
                  <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                    Shipping
                  </Heading>
                  <Text>
                    Name: <strong>{order.user.firstName}</strong>
                  </Text>
                  <Text>
                    Email:{" "}
                    <strong>
                      <a href={`mailto:${order.user.email}`}>
                        {order.user.email}
                      </a>
                    </strong>
                  </Text>
                  <Text pb="4">
                    Address:{" "}
                    <strong>
                      {order.shippingAddress.address},{" "}
                      {order.shippingAddress.city},{" "}
                      {order.shippingAddress.postalCode},{" "}
                      {order.shippingAddress.country}
                    </strong>
                  </Text>

                  <Stepper
                    display={{ sm: "flex", md: "none" }}
                    orientation="vertical"
                    height="300px"
                    index={
                      steps.findIndex((step) => step.title === order.status) + 1
                    }
                  >
                    {steps.map((step, index) => (
                      <Step key={index}>
                        <StepIndicator>
                          <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                          />
                        </StepIndicator>

                        <Box flexShrink="0">
                          <StepTitle>{step.title}</StepTitle>
                          <StepDescription>{step.description}</StepDescription>
                        </Box>

                        <StepSeparator />
                      </Step>
                    ))}
                  </Stepper>
                  <Stepper
                    display={{ sm: "none", md: "flex" }}
                    orientation="horizontal"
                    index={
                      steps.findIndex((step) => step.title === order.status) + 1
                    }
                  >
                    {steps.map((step, index) => (
                      <Step key={index}>
                        <StepIndicator>
                          <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                          />
                        </StepIndicator>

                        <Box flexShrink="0">
                          <StepTitle>{step.title}</StepTitle>
                          <StepDescription>{step.description}</StepDescription>
                        </Box>

                        <StepSeparator />
                      </Step>
                    ))}
                  </Stepper>
                </Box>

                {/* Payment Method */}
                <Box borderBottom="1px" py="6" borderColor="gray.300">
                  <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                    Payment Method
                  </Heading>
                  <Text>
                    Method: <strong>{order.paymentMethod.toUpperCase()}</strong>
                  </Text>
                  <Text mt="4">
                    {order.isPaid ? (
                      <>Paid on {order.paidAt.substring(0, 10)}</>
                    ) : (
                      <>Not Paid</>
                    )}
                  </Text>
                </Box>

                {/* Order Items */}
                <Box borderBottom="1px" py="6" borderColor="gray.300">
                  <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                    Order Items
                  </Heading>
                  <Box>
                    {order.orderItems.length === 0 ? (
                      <div>No Order Info</div>
                    ) : (
                      <div className="flow-root">
                        <ul
                          role="list"
                          className="-my-6 divide-y divide-gray-200"
                        >
                          {order.orderItems.map((item) => (
                            <li
                              key={item.product}
                              className="flex flex-col md:flex-row p-4 bg-white"
                            >
                              <div className="h-20 md:h-24 w-20 md:w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                <img
                                  src={item.image}
                                  alt={item.category}
                                  className="h-full w-full object-cover object-center"
                                />
                              </div>

                              <div className="ml-4 flex w-full flex-col overflow-clip">
                                <div className="flex w-full overflow-clip flex-col md:flex-row justify-start md:justify-between md:gap-2">
                                  <a
                                    className="font-semibold text-gray-800 w-full line-clamp-2"
                                    href={`/product/${item.name?.replaceAll(
                                      " ",
                                      "-"
                                    )}/${item.product}?variant=${item.variant}`}
                                  >
                                    {item.name}
                                  </a>
                                  <div className="self-start font-semibold">
                                    ₹{item.price}
                                  </div>
                                </div>
                                {item.giftWrap ? (
                                  <div>Message : {item.message}</div>
                                ) : (
                                  <></>
                                )}
                                {item.customized && (
                                  <div className="flex flex-col gap-2 w-full py-2">
                                    {item.userResponses &&
                                      item.userResponses?.map((response) => (
                                        <div className="flex gap-2 text-sm text-gray-500">
                                          <label>{response.question} - </label>
                                          <h3>{response.answer}</h3>
                                        </div>
                                      ))}
                                  </div>
                                )}
                                <div className="flex">
                                  {item.product?.gender === "female"
                                    ? `Engraving: ${item.engraveWomen}`
                                    : `Engraving: ${item.engraveMen}`}
                                </div>
                                <div className="flex flex-1 items-end justify-between text-sm">
                                  <div className="flex gap-2">
                                    <p className="text-gray-500">
                                      Qty {item.qty}
                                    </p>
                                    <p className="text-gray-500">
                                      {item.product?.gender === "female"
                                        ? `Size : ${item.sizeWomen}`
                                        : `Size : ${item.sizeMen}`}
                                    </p>
                                  </div>

                                  <div className="flex">
                                    {item.giftWrap ? "Gift Wrap Added" : ""}
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </Box>
                </Box>
              </Flex>

              {/* Column 2 */}
              <Flex
                direction="column"
                bgColor="white"
                justifyContent="space-between"
                p={{ sm: "2", md: "8" }}
                shadow="md"
                rounded="lg"
                borderColor="gray.300"
              >
                <Box>
                  <Heading as="h2" mb="3" fontSize="2xl" fontWeight="semibold">
                    Order Summary
                  </Heading>

                  {/* Items Price */}
                  <Flex
                    py="2"
                    alignitems="center"
                    justifyContent="space-between"
                  >
                    <Text fontSize="xl">Items</Text>
                    <Text fontWeight="bold" fontSize="xl">
                      ₹{order.itemsPrice}
                    </Text>
                  </Flex>

                  {/* Gift Price */}
                  <Flex
                    py="2"
                    alignitems="center"
                    justifyContent="space-between"
                  >
                    <Text fontSize="xl">Gift-wrap</Text>
                    <Text fontWeight="bold" fontSize="xl">
                      ₹{order.giftWrapTotalPrice}
                    </Text>
                  </Flex>

                  {/* Shipping Price */}
                  <Flex
                    py="2"
                    alignitems="center"
                    justifyContent="space-between"
                  >
                    <Text fontSize="xl">Shipping</Text>
                    <Text fontWeight="bold" fontSize="xl">
                      ₹{order.shippingPrice}
                    </Text>
                  </Flex>

                  {/* Tax Price */}
                  <Flex
                    borderBottom="1px"
                    py="2"
                    borderColor="gray.700"
                    alignitems="center"
                    justifyContent="space-between"
                  >
                    <Text fontSize="xl">Tax</Text>
                    <Text fontWeight="bold" fontSize="xl">
                      ₹{order.taxPrice}
                    </Text>
                  </Flex>

                  {order.discount !== 0 && (
                    <>
                      <Flex
                        borderBottom="1px"
                        py="2"
                        borderColor="gray.700"
                        alignitems="center"
                        justifyContent="space-between"
                      >
                        <Text fontSize="xl">Applied Coupon</Text>
                        <Text fontWeight="bold" fontSize="xl">
                          {order.couponCode}
                        </Text>
                      </Flex>

                      <Flex
                        borderBottom="1px"
                        py="2"
                        borderColor="gray.700"
                        alignitems="center"
                        justifyContent="space-between"
                      >
                        <Text fontSize="xl">Discount</Text>
                        <Text fontWeight="bold" fontSize="xl">
                          ₹{order.discount}
                        </Text>
                      </Flex>
                    </>
                  )}

                  {/* Total Price */}
                  <Flex
                    py="2"
                    alignitems="center"
                    justifyContent="space-between"
                  >
                    <Text fontSize="xl">Total</Text>
                    <Text fontWeight="bold" fontSize="xl">
                      ₹{order.totalPrice}
                    </Text>
                  </Flex>
                </Box>

                {/* {!order.isPaid && (
                  <Flex w="full" justifyContent="center">
                    {loadingPay ? (
                      <div>Loading</div>
                    ) : (
                      // <Loader />
                      // <button onClick={() => handlePayment(order)}>Razor Pay</button>
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() => handlePayment(order)}
                      >
                    //      <SiRazorpay
										// 	className="mr-2 h-5 w-5 inline"
										// /> 
                        RazorPay
                      </button>
                    )}
                  </Flex>
                )} */}

                {/* {order.isPaid && ( */}
                <ReactToPrint
                  trigger={() => (
                    <Button
                      type="button"
                      color={"white"}
                      // colorScheme="blue"
                      bgColor={"#000080"}
                      _hover={{ bgColor: "#000090" }}
                      onClick={printHandler}
                    >
                      Print receipt
                    </Button>
                  )}
                  content={() => componentRef.current}
                />
                {/* )}  */}
                <div className="hidden">
                  <OrderPrint order={order} ref={componentRef} />
                </div>
              </Flex>
            </Grid>
          </Flex>
        </Flex>
      )}
      {/* </main>
      </section> */}
    </div>
  );
};

export default OrderScreen;

{
  /* <html className="font-sans">
        <head>
          <title>Your Invoice</title>
        </head>

        <body className="p-12 bg-white">
          <div className="p-[8px]">
            <p>Dear {order.shippingAddress?.name}</p>
            <br />
            <p>
              Your order at SAIRAH with no. {order.order_id} has been
              successfully placed. You shall receive an update when the order is
              accepted. Thank You for shopping with SAIRAH, your favourite
              jewellery brand.
            </p>
          </div>

          <div className="max-w-800 mx-auto p-[20px] border-1 border-solid border-gray-300">
            <div className="text-center">
              <h1 className="text-4xl">TAX Invoice</h1>
            </div>
            <table className="w-full border-collapse mt-[20px]">
              <tr>
                <td className="p-[10px] text-left w-[70%]">
                  <img
                    src="https://d8v879q3glvao.cloudfront.net/products/1730092773960.png"
                    className="h-20"
                  />
                  <p>
                    A Soradis Group Venture under Soradis Jewellers Private
                    Limited.
                  </p>
                </td>
                <td className="p-[10px]"></td>
                <td className="p-[10px]"></td>
                <td className="p-[10px] text-right">
                  Invoice: {order.order_id}
                  <br />
                  Created: {order.createdAt?.substring(0, 10)}
                  <br />
                </td>
              </tr>

              <tr>
                <td className="p-[10px] text-left">
                  Soradis Jewellers Private Limited,
                  <br />
                  2029, 1Aerocity Business Park,
                  <br />
                  Andheri(E), Mumbai, Maharashtra, India.
                  <br />
                  PIN - 400072.
                  <br />
                  GSTIN 27ABJCS3957R1ZA
                  <br />
                  Importer-Exporter Code (IEC) ABJCS3957R
                </td>
                <td className="p-[10px]"></td>
                <td className="p-[10px]"></td>
                <td className="p-[10px] text-right">
                  {order.user.firstName}
                  <br />
                  {order.user.email}
                  <br />
                  {order.shippingAddress.address}, {order.shippingAddress.city},{" "}
                  {order.shippingAddress.postalCode},{" "}
                  {order.shippingAddress.country}
                </td>
              </tr>
            </table>

            <table className="w-full border-collapse mt-[20px]">
              <thead>
                <tr>
                  <th className="border p-[10px]">Image</th>
                  <th className="border p-[10px]">SKU</th>
                  <th className="border p-[10px]">Description</th>
                  <th className="border p-[10px]">Quantity</th>
                  <th className="border p-[10px]">Size</th>
                  <th className="border p-[10px]">Unit Price</th>
                  <th className="border p-[10px]">Total</th>
                </tr>
              </thead>
              <tbody>
                {order.orderItems?.map((orderObj) => (
                  <tr key={orderObj.name}>
                    <td className="border p-[10px]">
                      <img
                        src={orderObj.image}
                        alt="image"
                        height="50px"
                        width="50px"
                      />
                    </td>
                    <td className="border p-[10px]">{orderObj.sku}</td>
                    <td className="border p-[10px]">{orderObj.name}</td>
                    <td className="border p-[10px]">{orderObj.qty}</td>
                    <td className="border p-[10px]">{orderObj.size}</td>
                    <td className="border p-[10px]">{orderObj.price}</td>
                    <td className="border p-[10px]">
                      ₹ {orderObj.price * orderObj.qty}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="p-[10px] text-right">
              <p>Total: ₹ {order.totalPrice}</p>
            </div>
          </div>
        </body>
      </html> */
}
