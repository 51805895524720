import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { listCategory } from "../actions/categoryActions";
import Message from "../components/Message";
import Loader from "./Loader";
import { listCollections } from "../actions/collectionActions";
import { listSubCategory } from "../actions/subCategoryActions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <MdNavigateNext
      className={className}
      style={{
        color: "gray",
        fontSize: "45px",
        height: "55px",
        width: "55px",
        paddingLeft: "20px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <MdNavigateBefore
      className={className}
      style={{
        color: "gray",
        fontSize: "45px",
        height: "55px",
        width: "55px",
        paddingRight: "20px",
      }}
      onClick={onClick}
    />
  );
}

const NavLinks = () => {
  const dispatch = useDispatch();
  const [hoveredCategoryId, setHoveredCategoryId] = useState(null);
  const [hoveredCollectionId, setHoveredCollectionId] = useState(null);
  const [isDelayedVisible, setIsDelayedVisible] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(4);

  const { loading, error, categories } = useSelector(
    (state) => state.categoryList
  );

  const {
    loading: loadingSubCat,
    error: errorSubCat,
    subcategories,
  } = useSelector((state) => state.subCategoryList);

  const {
    loading: loadingColl,
    error: errorColl,
    collections,
  } = useSelector((state) => state.collectionList);

  useEffect(() => {
    dispatch(listCategory());
    dispatch(listCollections());
    dispatch(listSubCategory());
  }, [dispatch]);

  useEffect(() => {
    let delayTimer;
    if (hoveredCategoryId) {
      delayTimer = setTimeout(() => {
        setIsDelayedVisible(true);
      }, 300); // Adjust the delay time (300ms) as needed
    } else if (hoveredCollectionId) {
      delayTimer = setTimeout(() => {
        setIsDelayedVisible(true);
      }, 300); // Adjust the delay time (300ms) as needed
    } else {
      setIsDelayedVisible(false);
    }

    return () => clearTimeout(delayTimer);
  }, [hoveredCategoryId, hoveredCollectionId]);

  useEffect(() => {
    if (
      subcategories?.filter((cat) => cat.category._id === hoveredCategoryId)
        .length > 0 &&
      subcategories?.filter((cat) => cat.category._id === hoveredCategoryId)
        .length <= 4
    ) {
      setSlidesToShow(
        subcategories?.filter((cat) => cat.category._id === hoveredCategoryId)
          .length
      );
    }
  }, [subcategories, hoveredCategoryId]);

  const settings = {
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flex">
      {loading ? (
        <div className="w-full">Loading</div>
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        categories.map((link) => (
          <div
            key={link._id}
            className="py-2 px-3 text-center flex items-center justify-center md:cursor-pointer group"
            onMouseEnter={() => setHoveredCategoryId(link._id)}
            onMouseLeave={() => setHoveredCategoryId(null)}
          >
            <HashLink
              to={`/product?categories=${link._id}&menu=${link.name}`}
              className="relative my-5 flex justify-between text-sm hover:text-[#000080] font-semibold uppercase items-center group"
            >
              {link.name}
              <span className="absolute z-[100] -bottom-1 left-0 w-0 h-[2px] bg-[#000080] transition-all group-hover:w-[91%]"></span>
              <span className="text-xl md:hidden inline"></span>
              <span className="text-xl md:mt-1 md:ml-2 md:block hidden group-hover:rotate-180 group-hover:-mt-2">
                <ion-icon name="chevron-down"></ion-icon>
              </span>
            </HashLink>
            {hoveredCategoryId === link._id && isDelayedVisible && (
              <div className="mt-6 bg-white">
                <div className="bg-white absolute left-0 w-full top-46 z-20 hidden group-hover:md:block hover:md:block">
                  {/* <h2 className="bg-white flex w-[90%] mx-auto pb-2 border-b-2 text-center items-center justify-center bg-white text-sm font-semibold uppercase">
                    {link.name}
                  </h2> */}
                  <div className="bg-white p-5 max-w-6xl mx-auto px-16">
                    {loadingSubCat ? (
                      <Loader />
                    ) : errorSubCat ? (
                      <Message type="error">{errorSubCat}</Message>
                    ) : (
                      <Slider
                        prevArrow={<SamplePrevArrow />}
                        nextArrow={<SampleNextArrow />}
                        {...settings}
                      >
                        {subcategories
                          ?.filter((cat) => cat.category?._id === link._id)
                          ?.map((catObj) => (
                            <div
                              key={catObj._id}
                              className={`cursor-pointer p-3 md:p-6`}
                            >
                              <Link
                                className="block"
                                to={`/product?categories=${link._id}&subcategories=${catObj._id}`}
                                onClick={() => setHoveredCategoryId(null)}
                              >
                                <img
                                  src={catObj.image}
                                  alt={catObj.name}
                                  loading="lazy"
                                  className="ease-in transition hover:scale-105"
                                />
                              </Link>
                              <h1 className="text-[14px] font-[600] text-black text-center uppercase p-3">
                                {catObj.name}
                              </h1>
                            </div>
                          ))}
                      </Slider>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))
      )}
      {/* Collections Part */}
      <div
        onMouseEnter={() => setHoveredCollectionId(true)}
        onMouseLeave={() => setHoveredCollectionId(null)}
        className="py-2 px-3 text-left flex items-center justify-center md:cursor-pointer group"
      >
        <div className="relative my-5 flex justify-between text-sm hover:text-[#000080] font-semibold uppercase items-center group">
          Collections
          <span className="absolute z-[100] -bottom-1 left-0 w-0 h-[2px] bg-[#000080] transition-all group-hover:w-[91%]"></span>
          <span className="text-xl md:hidden inline"></span>
          <span className="text-xl md:mt-1 md:ml-2 md:block hidden group-hover:rotate-180 group-hover:-mt-2">
            <ion-icon name="chevron-down"></ion-icon>
          </span>
        </div>
        {hoveredCollectionId && isDelayedVisible && (
          <div className="mt-6 bg-white">
            <div className="bg-white absolute left-0 w-full top-46 z-20 hidden group-hover:md:block hover:md:block">
              {/* <h2 className="bg-white flex w-[90%] mx-auto pb-2 border-b-2 text-center items-center justify-center bg-white text-sm font-semibold uppercase">
              Collections
            </h2> */}
              <div className="bg-white p-5 max-w-6xl mx-auto px-16">
                {loadingColl ? (
                  <Loader />
                ) : errorColl ? (
                  <Message type="error">{errorColl}</Message>
                ) : (
                  <Slider
                    prevArrow={<SamplePrevArrow />}
                    nextArrow={<SampleNextArrow />}
                    {...settings}
                  >
                    {collections.map((catObj) => (
                      <div
                        key={catObj._id}
                        className={`cursor-pointer p-3 md:p-6`}
                      >
                        <Link
                          className="block"
                          to={`/product?collections=${catObj._id}`}
                          onClick={() => setHoveredCollectionId(false)}
                        >
                          <img
                            src={catObj.image}
                            alt={catObj.name}
                            loading="lazy"
                            className="ease-in transition hover:scale-105"
                          />
                        </Link>
                        <h1 className="text-[14px] font-[600] text-black text-center uppercase p-3">
                          {catObj.name}
                        </h1>
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="py-2 px-3 text-left flex items-center justify-center md:cursor-pointer group">
        <Link
          to={`/product?isLatestArrival=true&menu=WHATS NEW`}
          className="relative my-5 flex justify-between text-sm hover:text-[#000080] font-semibold uppercase items-center group"
        >
          What's New
          <span className="absolute z-[100] -bottom-1 left-0 w-0 h-[2px] bg-[#000080] transition-all group-hover:w-[91%]"></span>
          <span className="text-xl md:hidden inline"></span>
          <span className="text-xl md:mt-1 md:ml-2 md:block hidden group-hover:rotate-180 group-hover:-mt-2">
            <ion-icon name="chevron-down"></ion-icon>
          </span>
        </Link>
      </div>
      <div className="py-2 px-3 text-left flex items-center justify-center md:cursor-pointer group">
        <Link
          to={`/product?gender=male&menu=FOR HIM`}
          className="relative my-5 flex justify-between text-sm hover:text-[#000080] font-semibold uppercase items-center group"
        >
          For Him
          <span className="absolute z-[100] -bottom-1 left-0 w-0 h-[2px] bg-[#000080] transition-all group-hover:w-[91%]"></span>
          <span className="text-xl md:hidden inline"></span>
          <span className="text-xl md:mt-1 md:ml-2 md:block hidden group-hover:rotate-180 group-hover:-mt-2">
            <ion-icon name="chevron-down"></ion-icon>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default NavLinks;
