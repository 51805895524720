import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fragment, useEffect, useState } from "react";
import { listFeaturedProducts } from "../actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Loader from "./Loader";
import Message from "./Message";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import {
  addItemToWishlist,
  getWishlistItems,
  removeItemFromWishlist,
} from "../actions/wishListActions";
import { toast } from "react-toastify";
import { addItemToCart } from "../actions/cartActions";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { Dialog, Transition } from "@headlessui/react";
import { FaRegEdit } from "react-icons/fa";
import { serverIp } from "../config/conf";
import axios from "axios";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <MdNavigateNext
      className={className}
      style={{
        color: "gray",
        fontSize: "45px",
        height: "55px",
        width: "55px",
        paddingLeft: "20px",
      }}
      onClick={onClick}
    ></MdNavigateNext>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <MdNavigateBefore
      className={className}
      style={{
        color: "gray",
        fontSize: "45px",
        height: "55px",
        width: "55px",
        paddingRight: "20px",
      }}
      onClick={onClick}
    ></MdNavigateBefore>
  );
}

export default function PromoProductCard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [stockCount, setStockCount] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sentToCart, setSentToCart] = useState(false);
  // const [sizes, setSizes] = useState([]);
  const [id, setId] = useState("");
  const [qty, setQty] = useState(0);
  const [size, setSize] = useState("0");

  const [isOpen2, setIsOpen] = useState(false);
  const [isCustomized, setIsCustomized] = useState(false);
  const [numberOfCustomFields, setNumberOfCustomFields] = useState(0);

  const [customQuestions, setCustomQuestions] = useState([]);
  const [userResponses, setUserResponses] = useState([]);
  const [selectedSize, setSelectedSize] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [engravingText, setEngravingText] = useState("");
  const [isAccordionOpen, setIsAccordionOpen] = useState(false); // State to track Accordion state
  const [selectedPlating, setSelectedPlating] = useState([]);
  const [engravingTextMen, setEngravingTextMen] = useState(""); // State for engraving text
  const [engravingTextWomen, setEngravingTextWomen] = useState("");
  const [selectedSizeMen, setSelectedSizeMen] = useState(0);
  const [selectedSizeWomen, setSelectedSizeWomen] = useState(0);
  const [backEng, setbackEng] = useState(""); // State for engraving text
  const [engImage, setengImage] = useState(""); // State for engraving text

  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");

  const wishList = useSelector((state) => state.wishlist);
  const { wishlistItems, successRemove, successAdd } = wishList;

  const sizeList = useSelector((state) => state.sizeList);
  const { loading: loadingSize, error: errorSize, sizes } = sizeList;

  const settings = {
    infinite: true,
    slidesToShow: props.sts > 4 ? 4 : props.sts,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const notify = (link, content, type) =>
    type(<RouterLink to={link}>{content}</RouterLink>);

  const handleAddToCart = (e, obj) => {
    e.preventDefault();
    if (qty !== 0) {
      dispatch(addItemToCart(obj));
      onClose();
      if (sentToCart) {
        navigate("/cart");
      } else {
        notify("/cart", "Item Added To Cart", toast.success);
      }
    } else {
      notify("#", "Please select size & quantity", toast.error);
    }
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (wishlistItems && wishlistItems?.length > 0) {
      if (wishlistItems?.some((item) => !item._id)) {
        dispatch(getWishlistItems());
      }
    }
  }, [wishlistItems]);

  const uploadImageHandler = async (e, setImageCallback) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);
    // setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${serverIp}/api/uploads/single`,
        formData,
        config
      );

      // Use the passed callback to set the image URL
      setImageCallback(data);

      // setUploading(false);
    } catch (err) {
      console.error(err);
      // setUploading(false);
    }
  };

  const handleAccordionToggle = (isOpen) => {
    setIsAccordionOpen(isOpen); // Toggle Accordion state
    if (!isOpen) {
      setEngravingText(""); // Clear engraving text when Accordion is closed
    }
  };

  const handleAddToCart2 = (pId) => {
    dispatch(addItemToCart(1, "1", pId, "en"));
    onClose();
    notify("/cart", "Item Added To Cart", toast.success);
  };

  const handleEngravingInput = (value) => {
    const lettersAndSpaces = /^[A-Za-z ]*$/; // Regex to allow only letters and spaces
    if (lettersAndSpaces.test(value)) {
      setEngravingText(value); // Update engraving text if input is valid
    }
  };

  const addItemToWishlistHandler = (productId) => {
    dispatch(addItemToWishlist(productId));
    notify("/wishlist", "Item Added To Wishlist", toast.success);
  };

  const removeItemFromWishlistHandler = (id) => {
    dispatch(removeItemFromWishlist(id));
    notify("/wishlist", "Item Removed from Wishlist", toast.error);
  };

  const handleModalOpen = (
    sizes,
    id,
    sent,
    customized,
    customFields,
    customQuestions
  ) => {
    onOpen();
    setSentToCart(sent);
    setIsCustomized(customized);
    setNumberOfCustomFields(customFields);
    setCustomQuestions(customQuestions);
    const initialResponses = customQuestions?.map((question) => ({
      question,
      answer: "",
    }));
    setUserResponses(initialResponses);
    // setSizes(sizes);
    setId(id);
  };

  const handleModalOpen2 = (id, customized, customFields, customQuestions) => {
    setIsOpen(true);
    setSentToCart(false);
    setIsCustomized(customized);
    setNumberOfCustomFields(customFields);
    setCustomQuestions(customQuestions);
    const initialResponses = customQuestions?.map((question) => ({
      question,
      answer: "",
    }));
    setUserResponses(initialResponses);
    setId(id);
  };

  const handleResponseChange = (index, event) => {
    const newResponses = [...userResponses];
    newResponses[index].answer = event.target.value;
    setUserResponses(newResponses);
  };

  const handleSizeChange = (e, qty) => {
    const selectedSize = e.target.value;
    setSize(selectedSize);
    const selectedQuantity = qty > 0 ? 1 : 0;
    setQty(selectedQuantity);
    const selectedSizeObj = sizes.find(
      (sizeObj) => sizeObj.indianSize === selectedSize
    );
    setStockCount(selectedSizeObj ? selectedSizeObj.countInStock : 0);
  };

  const handleQuantityChange = (value) => {
    if (value >= 1 && value <= 5) {
      setQty(value);
    }
  };
  function closeModal() {
    setIsOpen(false);
  }

  const handleAddToCartCustom = (e) => {
    e.preventDefault();
    dispatch(
      addItemToCart(
        1,
        "1",
        id,
        "en",
        isCustomized,
        numberOfCustomFields,
        userResponses
      )
    );
    setIsOpen(false);
    if (sentToCart) {
      navigate("/cart");
    } else {
      notify("/cart", "Item Added To Cart", toast.success);
    }
  };

  return (
    <div className={`mx-auto max-w-7xl px-4 lg:px-8 py-3`}>
      <Transition appear show={isOpen2} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Enter Custom Name
                  </Dialog.Title>
                  <form id="form1" onSubmit={handleAddToCartCustom}>
                    {isCustomized && (
                      <div className="flex gap-4 py-2">
                        {customQuestions.map((question, index) => (
                          <div key={index} className="py-2">
                            <label className="text-base font-semibold">
                              {question}
                            </label>
                            <input
                              type="text"
                              value={userResponses[index].answer}
                              onChange={(event) =>
                                handleResponseChange(index, event)
                              }
                              placeholder={`Enter ${question}`}
                              className="rounded-md w-full"
                              required
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="mt-4">
                      <button
                        type="submit"
                        value="form1"
                        form="form1"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <p className="text-center text-xl font-semibold">{props.name}</p>
      {props.loading ? (
        <Loader />
      ) : props.error ? (
        <div></div>
      ) : (
        <div className="py-4">
          <Slider
            prevArrow={<SamplePrevArrow />}
            nextArrow={<SampleNextArrow />}
            {...settings}
          >
            {props.products?.length > 0 &&
              props.products?.map((product, index) => (
                <div
                  key={index}
                  className="flex flex-col justify-between relative p-2 group/prod hover:border"
                >
                  <RouterLink
                    to={`/product/${product.name?.replaceAll(" ", "-")}/${
                      product._id
                    }?variant=${
                      product?.variants?.length > 0 &&
                      product?.variants?.filter(
                        (variant) => variant.isPrimary
                      )[0]?._id
                    }`}
                  >
                    <div className="aspect-h-1 aspect-w-1 overflow-hidden bg-white">
                      {product?.variants?.length > 0 ? (
                        <img
                          src={
                            product?.variants?.length > 0 &&
                            product?.variants?.filter(
                              (variant) => variant.isPrimary
                            )[0]?.images[0]
                          }
                          // src="../prodimg1.png"
                          alt={product?.name}
                          loading="lazy"
                          className={
                            "self-center hover:scale-125 transition-transform ease-in object-cover object-center m-auto bg-[#EBEAE5] text-center"
                          }
                        />
                      ) : (
                        <div className="flex h-full w-full bg-gray-100 items-center justify-center">
                          <span>Image</span>
                        </div>
                      )}

                      {/* <img
                                  // src={product?.variants[0]?.images[1]}
                                  src="../prodimg1.png"
                                  alt={product?.category?.name}
                                  loading="lazy"
                                  className={
                                    "invisible group-hover/prod:visible hover:scale-110 hover:ease-in hover:duration-700 object-cover object-center m-auto"
                                  }
                                /> */}
                    </div>
                    <h3 className="py-1 mt-3 text-center px-2 text-base font-semibold text-gray-800 h-6 overflow-clip truncate">
                      {product.name}
                    </h3>
                    <h3 className="py-1 mt-3 text-center px-2 text-[14px] text-gray-500 h-6 overflow-clip truncate">
                      {product.description}
                    </h3>
                    <div className="flex flex-col mt-3 px-2 gap-2 md:gap-4 justify-between items-center">
                      {/* <p
                                  className={`${
                                    product.discountedPercent === 0
                                      ? "hidden"
                                      : "flex"
                                  } text-base text-red-600 font-semibold`}
                                >
                                  -
                                  {(
                                    ((product.price -
                                      product.basePrice) /
                                      product.price) *
                                    100
                                  )?.toFixed(0)}
                                  %
                                </p> */}
                      <div className="hidden group-hover/prod:flex w-full items-center justify-center gap-1 md:gap-3 ">
                        <p className="text-[14px] text-center text-gray-400 line-through">
                          ₹ {product.basePrice?.toLocaleString("en-IN")}
                        </p>
                        <p className="text-[14px] text-center md:text-base font-semibold text-[#000080]">
                          {(
                            ((product.basePrice - product.price) /
                              product.basePrice) *
                            100
                          ).toFixed(0)}
                          %
                        </p>
                      </div>
                      <div className="flex w-full items-center justify-center gap-1 md:gap-3">
                        <p className="text-sm text-center md:text-base font-bold text-gray-900">
                          ₹ {product.price?.toLocaleString("en-IN")}
                        </p>
                      </div>
                    </div>
                  </RouterLink>
                  {/* <div className="flex w-full  absolute z-1 left-0">
                              <p className="text-sm bg-red-200 opacity-90 p-0.5">
                                Pure Silver
                              </p>
                            </div> */}
                  <div className="w-full pt-2 bg-white hidden group-hover/prod:block">
                    <div className="flex justify-between items-center gap-1 h-[40px]">
                      {product?.categories?.some(
                        (cat) => cat.name === "Rings"
                      ) || product?.canEngrave ? (
                        <button
                          className="w-3/4 h-full bg-[#000080] rounded text-center self-center text-white text-sm font-medium"
                          onClick={() => {
                            if (userInfo && userInfo.firstName) {
                              onOpen();
                              setSelectedProduct(product);
                            } else {
                              navigate(
                                `/signup?redirect=/product/${product.name?.replaceAll(
                                  " ",
                                  "-"
                                )}/${product._id}?variant=${
                                  product?.variants?.length > 0 &&
                                  product?.variants?.filter(
                                    (variant) => variant.isPrimary
                                  )[0]?._id
                                }`
                              );
                            }
                          }}
                        >
                          Add to Cart
                        </button>
                      ) : (
                        <button
                          className="w-3/4 h-full bg-[#000080] rounded text-center self-center text-white text-sm font-medium"
                          onClick={(e) => {
                            if (userInfo && userInfo.firstName) {
                              handleAddToCart(e, {
                                name: product?.name,
                                image:
                                  product?.variants?.length > 0 &&
                                  product?.variants?.filter((variant) =>
                                    selectedPlating.length > 0
                                      ? selectedPlating.includes(variant.color)
                                      : variant.isPrimary
                                  )[0]?.images[0],
                                qty,
                                sizeMen: "1",
                                sizeWomen: "1",
                                price: product?.price,
                                basePrice: product?.basePrice,
                                product: product?._id,
                                variant:
                                  product?.variants?.length > 0 &&
                                  product?.variants?.filter(
                                    (variant) => variant?.isPrimary
                                  )[0]?._id,
                                sku: product?.sku,
                                giftWrap: false,
                                message: "",
                                engraveMen: engravingTextMen,
                                engraveWomen: engravingTextWomen,
                                backEng,
                                engImage,
                                isPair: false,
                              });
                            } else {
                              navigate(
                                `/signup?redirect=/product/${product.name?.replaceAll(
                                  " ",
                                  "-"
                                )}/${product._id}?variant=${
                                  product?.variants?.length > 0 &&
                                  product?.variants?.filter(
                                    (variant) => variant.isPrimary
                                  )[0]?._id
                                }`
                              );
                            }
                          }}
                        >
                          Add to Cart
                        </button>
                      )}

                      <button
                        className="flex items-center justify-center w-1/4 h-full border border-[#000080] rounded"
                        onClick={() => {
                          wishlistItems?.some(
                            (item) => item._id === product._id
                          )
                            ? removeItemFromWishlistHandler(product._id)
                            : addItemToWishlistHandler(product._id);
                        }}
                      >
                        <Icon
                          as={
                            wishlistItems?.some(
                              (item) => item._id === product._id
                            )
                              ? AiFillHeart
                              : AiOutlineHeart
                          }
                          w="6"
                          h="6"
                          textColor="#000080"
                          cursor="pointer"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>

          <Drawer onClose={onClose} isOpen={isOpen} size="lg">
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>{`Select size and quantity`}</DrawerHeader>
              <DrawerBody>
                <form
                  onSubmit={(e) =>
                    handleAddToCart(e, {
                      name: selectedProduct?.name,
                      image:
                        selectedProduct?.variants?.length > 0 &&
                        selectedProduct?.variants?.filter((variant) =>
                          selectedPlating.length > 0
                            ? selectedPlating.includes(variant.color)
                            : variant.isPrimary
                        )[0]?.images[0],
                      qty,
                      sizeMen: selectedSizeMen,
                      sizeWomen: selectedSizeWomen,
                      price: selectedProduct?.price,
                      basePrice: selectedProduct?.basePrice,
                      product: selectedProduct?._id,
                      variant:
                        selectedProduct?.variants?.length > 0 &&
                        selectedProduct?.variants?.filter(
                          (variant) => variant?.isPrimary
                        )[0]?._id,
                      sku: selectedProduct?.sku,
                      giftWrap: false,
                      message: "",
                      engraveMen: engravingTextMen,
                      engraveWomen: engravingTextWomen,
                      isPair: false,
                      backEng,
                      engImage,
                    })
                  }
                >
                  <Tabs isManual variant="enclosed">
                    <TabList>
                      <Tab>Select Size/Add Engraving</Tab>
                      <Tab>Size Chart(Indian Size)</Tab>
                      <Tab>How to measure</Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <Accordion
                          allowMultiple
                          onChange={() =>
                            handleAccordionToggle(!isAccordionOpen)
                          }
                          my="6"
                        >
                          <AccordionItem>
                            <h2>
                              <AccordionButton>
                                <FaRegEdit className="w-5 h-5" />
                                <Box as="span" flex="1" textAlign="left">
                                  Add Engraving
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            {selectedProduct?.categories?.some(
                              (cat) =>
                                cat.name == "Rings" ||
                                cat.name == "JEWELLERY SETS"
                            ) && (
                              <>
                                {selectedProduct?.subcategories?.some(
                                  (subcat) => subcat.name === "Couple Rings"
                                ) ? (
                                  <>
                                    <AccordionPanel pb={4}>
                                      <input
                                        type="text"
                                        maxLength="10" // Limits input to 10 characters
                                        pattern="[A-Za-z]*" // Allows only letters and spaces
                                        value={engravingTextMen} // Controlled input
                                        className="border px-2 py-1 rounded"
                                        placeholder="Engraving for men"
                                        onChange={(e) =>
                                          handleEngravingInput(
                                            "male",
                                            e.target.value
                                          )
                                        } // Handle engraving input
                                      />
                                      <p className="text-sm text-gray-500">
                                        Max 10 characters, letters only.
                                      </p>
                                    </AccordionPanel>
                                    <AccordionPanel pb={4}>
                                      <input
                                        type="text"
                                        maxLength="10" // Limits input to 10 characters
                                        pattern="[A-Za-z]*" // Allows only letters and spaces
                                        value={engravingTextWomen} // Controlled input
                                        className="border px-2 py-1 rounded"
                                        placeholder="Engraving for women"
                                        onChange={(e) =>
                                          handleEngravingInput(
                                            "female",
                                            e.target.value
                                          )
                                        } // Handle engraving input
                                      />
                                      <p className="text-sm text-gray-500">
                                        Max 10 characters, letters only.
                                      </p>
                                    </AccordionPanel>
                                  </>
                                ) : (
                                  <AccordionPanel pb={4}>
                                    <input
                                      type="text"
                                      maxLength="10" // Limits input to 10 characters
                                      pattern="[A-Za-z]*" // Allows only letters and spaces
                                      value={
                                        selectedProduct?.gender === "female"
                                          ? engravingTextWomen
                                          : engravingTextMen
                                      } // Controlled input
                                      className="border px-2 py-1 rounded"
                                      placeholder={`Engraving Text`}
                                      onChange={(e) => {
                                        if (
                                          selectedProduct?.gender === "female"
                                        ) {
                                          handleEngravingInput(
                                            "female",
                                            e.target.value
                                          );
                                        } else {
                                          handleEngravingInput(
                                            "male",
                                            e.target.value
                                          );
                                        }
                                      }} // Handle engraving input
                                    />
                                    <p className="text-sm text-gray-500">
                                      Max 10 characters, letters only.
                                    </p>
                                  </AccordionPanel>
                                )}
                              </>
                            )}
                            {selectedProduct?.productCollections?.some(
                              (cat) => cat.name == "Pet Collection"
                            ) &&
                              selectedProduct?.canEngrave && (
                                <>
                                  <AccordionPanel pb={4}>
                                    <div className="w-full md:w-[60%] relative">
                                      <img
                                        className="w-full aspect-[3/2] object-cover object-center"
                                        src={selectedProduct.frontPreviewImg}
                                      />
                                      <div className="flex text-4xl font-ScriptMTBold absolute inset-0 items-center justify-center z-10 text-center">
                                        {engravingTextWomen}
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      maxLength="10" // Limits input to 10 characters
                                      pattern="[A-Za-z]*" // Allows only letters and spaces
                                      value={engravingTextWomen} // Controlled input
                                      className="border px-2 py-1 rounded"
                                      placeholder="Front Engraving Text"
                                      onChange={(e) =>
                                        handleEngravingInput(
                                          "female",
                                          e.target.value
                                        )
                                      } // Handle engraving input
                                    />
                                    <p className="text-sm text-gray-500">
                                      Max 10 characters, letters only.
                                    </p>
                                  </AccordionPanel>
                                  {selectedProduct?.canBackEng && (
                                    <AccordionPanel pb={4}>
                                      <div className="w-full md:w-[60%] relative">
                                        <img
                                          className="w-full aspect-[3/2] object-cover object-center"
                                          src={selectedProduct.backPreviewImg}
                                        />
                                        <div className="flex text-4xl font-MvBoli absolute inset-0 items-center justify-center z-10 text-center">
                                          {backEng}
                                        </div>
                                      </div>
                                      <input
                                        type="text"
                                        maxLength="10" // Limits input to 10 characters
                                        pattern="[A-Za-z]*" // Allows only letters and spaces
                                        value={backEng} // Controlled input
                                        className="border px-2 py-1 rounded"
                                        placeholder="Back Engraving Text"
                                        onChange={(e) =>
                                          setbackEng(e.target.value)
                                        } // Handle engraving input
                                      />
                                      <p className="text-sm text-gray-500">
                                        Max 10 characters, letters only.
                                      </p>
                                    </AccordionPanel>
                                  )}
                                </>
                              )}
                          </AccordionItem>
                        </Accordion>

                        <div className="flex flex-col gap-6 my-6">
                          <div>
                            <label htmlFor="quantitySelect">Quantity:</label>
                            <div className="flex items-center">
                              <button
                                type="button"
                                onClick={() =>
                                  handleQuantityChange(qty > 1 ? qty - 1 : 1)
                                }
                                className="px-4 py-2 bg-[#F3F3F3] text-[#000080] font-bold text-xl focus:outline-none"
                              >
                                -
                              </button>
                              <input
                                type="text"
                                id="quantitySelect"
                                value={qty}
                                onChange={(e) =>
                                  handleQuantityChange(Number(e.target.value))
                                }
                                className="px-4 py-2 w-[45px] text-center text-xl bg-[#F3F3F3] focus:outline-none border-none"
                                readOnly
                              />
                              <button
                                type="button"
                                onClick={() =>
                                  handleQuantityChange(qty < 5 ? qty + 1 : 5)
                                }
                                className="px-4 py-2 bg-[#F3F3F3] text-[#000080] font-bold text-xl focus:outline-none"
                              >
                                +
                              </button>
                            </div>
                          </div>

                          {selectedProduct?.canImgEng && (
                            <div className="mb-4">
                              <label
                                htmlFor="image"
                                className="block text-gray-700"
                              >
                                Upload Image For Engraving
                              </label>
                              <input
                                type="file"
                                id="image"
                                onChange={(e) =>
                                  uploadImageHandler(e, setengImage)
                                }
                                className="w-full p-2 border border-gray-300 rounded"
                              />
                            </div>
                          )}

                          {selectedProduct?.categories?.find(
                            (cat) =>
                              cat.name === "Rings" ||
                              cat.name === "JEWELLERY SETS"
                          ) && (
                            <div>
                              <label htmlFor="sizeSelect">Size:</label>
                              {selectedProduct?.subcategories?.some(
                                (subcat) => subcat.name === "Couple Rings"
                              ) ? (
                                <div className="flex gap-6">
                                  <Select
                                    id="sizeSelect"
                                    border="1px solid"
                                    _hover="none"
                                    className="pt-0"
                                    pt="0px"
                                    borderColor="[#000080]"
                                    value={selectedSizeMen}
                                    onChange={(e) =>
                                      setSelectedSizeMen(e.target.value)
                                    }
                                    required
                                  >
                                    <option value={0}>Select Size Men</option>
                                    {loadingSize ? (
                                      <Loader />
                                    ) : errorSize ? (
                                      <Message type="error">
                                        {errorSize}
                                      </Message>
                                    ) : (
                                      sizes.map((num) => (
                                        <option
                                          key={num?.size}
                                          value={num?.size}
                                        >
                                          {num?.size}
                                        </option>
                                      ))
                                    )}
                                  </Select>
                                  <Select
                                    id="sizeSelect"
                                    border="1px solid"
                                    _hover="none"
                                    className="pt-0"
                                    pt="0px"
                                    borderColor="[#000080]"
                                    value={selectedSizeWomen}
                                    onChange={(e) =>
                                      setSelectedSizeWomen(e.target.value)
                                    }
                                    required
                                  >
                                    <option value={0}>Select Size Women</option>
                                    {loadingSize ? (
                                      <Loader />
                                    ) : errorSize ? (
                                      <Message type="error">
                                        {errorSize}
                                      </Message>
                                    ) : (
                                      sizes.map((num) => (
                                        <option
                                          key={num?.size}
                                          value={num?.size}
                                        >
                                          {num?.size}
                                        </option>
                                      ))
                                    )}
                                  </Select>
                                </div>
                              ) : (
                                <Select
                                  id="sizeSelect"
                                  border="1px solid"
                                  _hover="none"
                                  className="pt-0"
                                  pt="0px"
                                  borderColor="[#000080]"
                                  value={
                                    selectedProduct?.gender === "female"
                                      ? selectedSizeWomen
                                      : selectedSizeMen
                                  }
                                  onChange={(e) => {
                                    if (selectedProduct?.gender === "female") {
                                      setSelectedSizeWomen(e.target.value);
                                    } else {
                                      setSelectedSizeMen(e.target.value);
                                    }
                                  }}
                                  required
                                >
                                  <option value={0}>Select Size</option>
                                  {loadingSize ? (
                                    <Loader />
                                  ) : errorSize ? (
                                    <Message type="error">{errorSize}</Message>
                                  ) : (
                                    sizes.map((num) => (
                                      <option key={num?.size} value={num?.size}>
                                        {num?.size}
                                      </option>
                                    ))
                                  )}
                                </Select>
                              )}
                            </div>
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="mt-2 max-h-[60vh] overflow-y-auto">
                          <table className="w-full border-collapse border border-slate-500">
                            <tr className="text-center">
                              <th className="w-1/3 border border-slate-300 p-1">
                                Size
                              </th>
                              <th className="w-2/3 border border-slate-300 p-1">
                                Internal Diameter(mm.)
                              </th>
                            </tr>
                            {loadingSize ? (
                              <Loader />
                            ) : errorSize ? (
                              <Message type="error">{errorSize}</Message>
                            ) : (
                              sizes?.map((size) => (
                                <tr className="text-center">
                                  <td className="border border-slate-300 p-1">
                                    {size?.size}
                                  </td>
                                  <td className="border border-slate-300 p-1">
                                    {size?.internalDiameterMM} mm.
                                  </td>
                                </tr>
                              ))
                            )}
                          </table>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <p className="flex w-full justify-center items-center text-lg font-bold py-8">
                          If you dont have ring
                        </p>
                        <div className="grid grid-cols-2 gap-8">
                          <div className="flex justify-center items-center">
                            <img
                              src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-2.png"
                              alt="instructions"
                              className="h-[120px]"
                            />
                          </div>
                          <div>
                            Take a small piece of string or thread or ribbon.
                            Wrap it around your finger.
                          </div>

                          <div className="flex justify-center items-center">
                            <img
                              src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-3.webp"
                              alt="instructions"
                              className="h-[120px]"
                            />
                          </div>
                          <div>Mark the spot where the thread meets.</div>

                          <div className="flex justify-center items-center">
                            <img
                              src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-4.webp"
                              alt="instructions"
                              className="h-[120px]"
                            />
                          </div>
                          <div>
                            Measure the length of the string with your ruler.
                          </div>
                        </div>

                        <p className="flex w-full justify-center items-center pt-8">
                          --------------------------------------------------------------------------------------------
                        </p>

                        <p className="flex w-full justify-center items-center text-lg font-bold pb-8">
                          If you have a ring
                        </p>
                        <div className="grid grid-cols-2 gap-8">
                          <div className="flex justify-center items-center">
                            <img
                              src="https://d8v879q3glvao.cloudfront.net/Static_images/Ring-size-guide-ruler.jpg"
                              alt="instructions"
                              className="h-[120px]"
                            />
                          </div>
                          <div>
                            1 : Place the ring
                            <br />2 : Measure the inner diameter within inner
                            edges of your ring with a ruler.
                          </div>
                        </div>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                  <Button
                    type="submit"
                    isDisabled={
                      selectedProduct?.categories.some(
                        (prod) =>
                          prod.name === "Rings" ||
                          prod.name == "JEWELLERY SETS"
                      ) && selectedProduct?.gender === "female"
                        ? selectedSizeWomen === 0
                        : selectedProduct?.categories.some(
                            (prod) =>
                              prod.name === "Rings" ||
                              prod.name == "JEWELLERY SETS"
                          ) && selectedProduct?.gender === "male"
                        ? selectedSizeMen === 0
                        : selectedProduct?.productCollections.some(
                            (prod) =>
                              prod.name === "Pet Collection"
                          ) && selectedProduct?.canImgEng
                        ? engImage === ""
                        : false
                    }
                    bgColor="#000080"
                    textColor="white"
                    _hover={{ bgColor: "#000090" }}
                  >
                    Next
                  </Button>
                </form>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </div>
      )}
    </div>
  );
}
