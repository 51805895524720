// constants/productSetConstants.js
export const PRODUCT_SET_CREATE_REQUEST = "PRODUCT_SET_CREATE_REQUEST";
export const PRODUCT_SET_CREATE_SUCCESS = "PRODUCT_SET_CREATE_SUCCESS";
export const PRODUCT_SET_CREATE_FAIL = "PRODUCT_SET_CREATE_FAIL";
export const PRODUCT_SET_CREATE_RESET = "PRODUCT_SET_CREATE_RESET";

export const PRODUCT_SET_LIST_REQUEST = "PRODUCT_SET_LIST_REQUEST";
export const PRODUCT_SET_LIST_SUCCESS = "PRODUCT_SET_LIST_SUCCESS";
export const PRODUCT_SET_LIST_FAIL = "PRODUCT_SET_LIST_FAIL";

export const PRODUCT_SET_DETAILS_REQUEST = "PRODUCT_SET_DETAILS_REQUEST";
export const PRODUCT_SET_DETAILS_SUCCESS = "PRODUCT_SET_DETAILS_SUCCESS";
export const PRODUCT_SET_DETAILS_FAIL = "PRODUCT_SET_DETAILS_FAIL";

export const PRODUCT_SET_UPDATE_REQUEST = "PRODUCT_SET_UPDATE_REQUEST";
export const PRODUCT_SET_UPDATE_SUCCESS = "PRODUCT_SET_UPDATE_SUCCESS";
export const PRODUCT_SET_UPDATE_FAIL = "PRODUCT_SET_UPDATE_FAIL";
export const PRODUCT_SET_UPDATE_RESET = "PRODUCT_SET_UPDATE_RESET";

export const PRODUCT_SET_DELETE_REQUEST = "PRODUCT_SET_DELETE_REQUEST";
export const PRODUCT_SET_DELETE_SUCCESS = "PRODUCT_SET_DELETE_SUCCESS";
export const PRODUCT_SET_DELETE_FAIL = "PRODUCT_SET_DELETE_FAIL";
