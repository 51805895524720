import {
  COLLECTION_CREATE_REQUEST,
  COLLECTION_CREATE_SUCCESS,
  COLLECTION_CREATE_FAIL,
  COLLECTION_CREATE_RESET,
  COLLECTION_LIST_REQUEST,
  COLLECTION_LIST_SUCCESS,
  COLLECTION_LIST_FAIL,
  COLLECTION_DETAILS_REQUEST,
  COLLECTION_DETAILS_SUCCESS,
  COLLECTION_DETAILS_FAIL,
  COLLECTION_UPDATE_REQUEST,
  COLLECTION_UPDATE_SUCCESS,
  COLLECTION_UPDATE_FAIL,
  COLLECTION_UPDATE_RESET,
  COLLECTION_DELETE_REQUEST,
  COLLECTION_DELETE_SUCCESS,
  COLLECTION_DELETE_FAIL,
} from "../constants/collectionConstants";

const initialState = {
  collections: [],
};

export const collectionCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case COLLECTION_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case COLLECTION_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        success: true,
      };
    case COLLECTION_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case COLLECTION_CREATE_RESET:
      return {
        ...state,
        collections: [],
        loading: false,
        error: null,
        success: false,
      };
    default:
      return state;
  }
};

export const collectionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case COLLECTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COLLECTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case COLLECTION_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const collectionDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case COLLECTION_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COLLECTION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case COLLECTION_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const collectionUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case COLLECTION_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COLLECTION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        success: true,
      };
    case COLLECTION_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case COLLECTION_UPDATE_RESET:
      return {
        ...state,
        collections: [],
        loading: false,
        error: null,
        success: false,
      };
    default:
      return state;
  }
};

export const collectionDeleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case COLLECTION_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case COLLECTION_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case COLLECTION_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
};
