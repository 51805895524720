import React from 'react'
import Loader from './Loader'
import Message from './Message'

const SizeChart = ({loadingSize,errorSize,sizes}) => {
  return (
    <div>
        <table className="w-full border-collapse border border-slate-500">
                                          <tr className="text-center">
                                            <th className="w-1/3 border border-slate-300 p-1">
                                              Size
                                            </th>
                                            <th className="w-2/3 border border-slate-300 p-1">
                                              Internal Diameter(mm.)
                                            </th>
                                          </tr>
                                          {loadingSize ? (
                                            <Loader />
                                          ) : errorSize ? (
                                            <Message type="error">
                                              {errorSize}
                                            </Message>
                                          ) : (
                                            sizes?.map((size) => (
                                              <tr className="text-center">
                                                <td className="border border-slate-300 p-1">
                                                  {size?.size}
                                                </td>
                                                <td className="border border-slate-300 p-1">
                                                  {size?.internalDiameterMM} mm.
                                                </td>
                                              </tr>
                                            ))
                                          )}
                                        </table>
    </div>
  )
}

export default SizeChart