import { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Select,
  Button,
  Icon,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
  AccordionIcon,
  Accordion,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import { listSearchProducts } from "../actions/productActions";
import { useTranslation } from "react-i18next";
import { addItemToCart } from "../actions/cartActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Pagination from "../components/Pagination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import {
  addItemToWishlist,
  removeItemFromWishlist,
} from "../actions/wishListActions";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { listSizes } from "../actions/sizeActions";
import { FaRegEdit } from "react-icons/fa";
import axios from "axios";
import { serverIp } from "../config/conf";

const sortOptions = [
  { name: "Newest", href: "#", current: false },
  { name: "Price: Low to High", href: "#", current: false },
  { name: "Price: High to Low", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Search() {
  const filters = [
    {
      id: "category",
      name: "Category",
      options: [
        { value: "true", label: "Rings", checked: false },
        { value: "true", label: "Earrings", checked: false },
        { value: "true", label: "Pendants", checked: false },
      ],
    },
    {
      id: "color",
      name: "Plating",
      options: [
        { value: "true", label: "Yellow Gold", checked: false },
        { value: "true", label: "White Gold", checked: false },
        { value: "true", label: "Rose Gold", checked: false },
      ],
    },
    {
      id: "priceRange",
      name: "Price",
      options: [
        { value: "true", label: "Upto 2000", checked: false },
        { value: "true", label: "2000-3000", checked: false },
        { value: "true", label: "3000-5000", checked: false },
        { value: "true", label: "5000-8000", checked: false },
        { value: "true", label: "8000 and above", checked: false },
      ],
    },
    {
      id: "gender",
      name: "Gender",
      options: [
        { value: "true", label: "Women", checked: false },
        { value: "true", label: "Men", checked: false },
        { value: "true", label: "Unisex", checked: false },
      ],
    },
    {
      id: "bestOfSapphire",
      name: "Only For You",
      options: [{ value: "true", label: "Only For You", checked: false }],
    },
    {
      id: "latestArrivals",
      name: "Latest Arrivals",
      options: [{ value: "true", label: "Latest Arrivals", checked: false }],
    },
    {
      id: "giftingspecial",
      name: "Gifting Special",
      options: [{ value: "true", label: "Gifting Special", checked: false }],
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const queryParams = new URLSearchParams(routePath.search);
  let query = queryParams.get("q");

  const [stockCount, setStockCount] = useState(0);
  const [qty, setQty] = useState(0);
  const [id, setId] = useState("");
  const [size, setSize] = useState("0");
  // const [sizes, setSizes] = useState([]);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [category, setCategory] = useState([]); // Use an array for multi-select
  const [subCategory, setSubCategory] = useState([]);
  const [bestOfSapphire, setBestOfSapphire] = useState([]);
  const [latest, setLatest] = useState([]);
  const [gift, setGift] = useState([]);
  const [gender, setGender] = useState([]);
  const [color, setColor] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [sort, setSort] = useState("");
  const [sentToCart, setSentToCart] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSizeMen, setSelectedSizeMen] = useState(0);
  const [selectedSizeWomen, setSelectedSizeWomen] = useState(0);
  const [engravingTextMen, setEngravingTextMen] = useState(""); // State for engraving text
  const [engravingTextWomen, setEngravingTextWomen] = useState(""); // State for engraving text
  const [isAccordionOpen, setIsAccordionOpen] = useState(false); // State to track Accordion state
  const [backEng, setbackEng] = useState(""); // State for engraving text
  const [engImage, setengImage] = useState(""); // State for engraving text

  const [selectedPlating, setSelectedPlating] = useState([]);

  const [itemsPerPage, setItemsPerPage] = useState(40);

  const [scrolling, setScrolling] = useState(false);

  const [page, setPage] = useState(1);

  useEffect(() => {
    onTop();
    dispatch(listSizes());
  }, [query]);

  const handlePage = (page) => {
    onTop();
    setPage(page);
  };

  const {
    loading: loadingFilter,
    error: errorFilter,
    menuLinks: menuFilter,
  } = useSelector((state) => state.menuLinkList);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productList = useSelector((state) => state.productList);
  const { loading, products, error } = productList;

  const productSearchList = useSelector((state) => state.productSearchList);
  const {
    loading: loadingSearch,
    error: errorSearch,
    products: productSearch,
  } = productSearchList;

  const wishList = useSelector((state) => state.wishlist);
  const { wishlistItems } = wishList;

  const sizeList = useSelector((state) => state.sizeList);
  const { loading: loadingSize, error: errorSize, sizes } = sizeList;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { i18n } = useTranslation();

  let lang = i18n.language;

  const handleScroll = () => {
    if (window.innerWidth >= 1080 && window.scrollY > 100) {
      setScrolling(true);
    } else if (window.innerWidth <= 400 && window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const clearFilter = () => {
    setCategory([]);
    setSubCategory([]);
    setBestOfSapphire([]);
    setLatest([]);
    setColor([]);
    setPriceRange([]);
    setSort("");
  };

  const handleAccordionToggle = (isOpen) => {
    setIsAccordionOpen(isOpen); // Toggle Accordion state
    if (!isOpen) {
      setEngravingTextMen(""); // Clear engraving text when Accordion is closed
      setEngravingTextWomen(""); // Clear engraving text when Accordion is closed
    }
  };

  const handleEngravingInput = (gender, value) => {
    const lettersAndSpaces = /^[A-Za-z ]*$/; // Regex to allow only letters and spaces
    if (lettersAndSpaces.test(value)) {
      if (gender === "female") {
        setEngravingTextWomen(value); // Update engraving text if input is valid
      } else {
        setEngravingTextMen(value); // Update engraving text if input is valid
      }
    }
  };

  const handleAddToCart = (e, obj) => {
    e.preventDefault();
    if (qty !== 0) {
      dispatch(addItemToCart(obj));
      onClose();
      if (sentToCart) {
        navigate("/cart");
      } else {
        notify("/cart", "Item Added To Cart", toast.success);
      }
    } else {
      notify("#", "Please select size & quantity", toast.error);
    }
  };

  const uploadImageHandler = async (e, setImageCallback) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);
    // setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${serverIp}/api/uploads/single`,
        formData,
        config
      );

      // Use the passed callback to set the image URL
      setImageCallback(data);

      // setUploading(false);
    } catch (err) {
      console.error(err);
      // setUploading(false);
    }
  };

  const handleAddToCart2 = (pId) => {
    dispatch(addItemToCart(1, "1", pId, "en"));
    onClose();
    notify("/cart", "Item Added To Cart", toast.success);
  };

  const addItemToWishlistHandler = (productId) => {
    dispatch(addItemToWishlist(productId));
    notify("/wishlist", "Item Added To Wishlist", toast.success);
  };

  const removeItemFromWishlistHandler = (id) => {
    dispatch(removeItemFromWishlist(id));
    notify("/wishlist", "Item Removed from Wishlist", toast.error);
  };

  const handleSizeChange = (e) => {
    setQty(0);
    const selectedSize = e.target.value;
    setSize(selectedSize);
    const selectedSizeObj = sizes.find(
      (sizeObj) =>
        (i18n.language === "de" ? sizeObj.germanSize : sizeObj.indianSize) ===
        selectedSize
    );
    setStockCount(selectedSizeObj ? selectedSizeObj.countInStock : 0);
  };

  const handleQuantityChange = (e) => {
    const selectedQuantity = Number(e.target.value);
    if (selectedQuantity > 0) {
      setQty(selectedQuantity);
    }
  };

  const handleModalOpen = (sizes, id, sent) => {
    onOpen();
    setSentToCart(sent);
    // setSizes(sizes);
    setId(id);
  };

  const handleCheckboxChange = (event, setStateCallback) => {
    const { value, checked, name } = event.target;

    setPage(1);

    setStateCallback((prevValues) => {
      if (
        name === "Only For You" ||
        name === "Latest Arrivals" ||
        name === "Gifting Special"
      ) {
        if (checked) {
          return [...prevValues, value];
        } else {
          return prevValues.filter((item) => item !== value);
        }
      } else {
        if (checked) {
          return [...prevValues, name];
        } else {
          return prevValues.filter((item) => item !== name);
        }
      }
    });
  };

  const notify = (link, content, type) =>
    type(<RouterLink to={link}>{content}</RouterLink>);

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  function filterProducts() {
    const filteredProducts = productSearch?.filter((product) => {
      if (category.length > 0 && !category.includes(product.category)) {
        return false;
      }
      if (color.length > 0 && !color.includes(product.plating)) {
        return false;
      }
      if (priceRange.length > 0) {
        const priceRangeValue = parseInt(product.discountedPrice);
        return priceRange.some((selectedRange) => {
          if (selectedRange === "Upto 2000") {
            return priceRangeValue <= 2000;
          } else if (selectedRange === "2000-3000") {
            return priceRangeValue >= 2000 && priceRangeValue <= 3000;
          } else if (selectedRange === "3000-5000") {
            return priceRangeValue >= 3000 && priceRangeValue <= 5000;
          } else if (selectedRange === "5000-8000") {
            return priceRangeValue >= 5000 && priceRangeValue <= 8000;
          } else if (selectedRange === "8000 and above") {
            return priceRangeValue >= 8000;
          }
          return false;
        });
      }

      if (gender.length > 0 && !gender.includes(product.gender)) {
        return false;
      }
      if (
        bestOfSapphire.length > 0 &&
        !bestOfSapphire.includes(product.bestOfSapphire?.toString())
      ) {
        return false;
      }
      if (
        latest.length > 0 &&
        !latest.includes(product.latestArrivals?.toString())
      ) {
        return false;
      }
      if (gift.length > 0 && !gift.includes(product.gift?.toString())) {
        return false;
      }
      return true;
    });

    if (sort === "Newest") {
      filteredProducts.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    } else if (sort === "Price: Low to High") {
      filteredProducts.sort((a, b) => a.discountedPrice - b.discountedPrice);
    } else if (sort === "Price: High to Low") {
      filteredProducts.sort((a, b) => b.discountedPrice - a.discountedPrice);
    }

    return filteredProducts?.slice(indexOfFirstItem, indexOfLastItem);
  }
  const currentItems = filterProducts();

  useEffect(() => {
    dispatch(listSearchProducts(query));
  }, [query]);

  return (
    <div className="bg-white">
      <div>
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <form className="mt-4 border-t border-gray-200">
                    {menuFilter
                      .filter((menu) => menu.name === "Generic")
                      .map((menu, index) => (
                        <div key={index}>
                          {menu.sublinks?.map((menuObj, index) => (
                            <Disclosure
                              key={index}
                              defaultOpen
                              as="div"
                              className="border-b border-gray-200"
                            >
                              {({ open }) => (
                                <>
                                  <h3 className="flow-root">
                                    <Disclosure.Button className="flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                                      <span className="text-md font-semibold text-gray-900">
                                        {menuObj.Head}
                                      </span>
                                      <span className="ml-6 flex items-center">
                                        {open ? (
                                          <MinusIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <PlusIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </span>
                                    </Disclosure.Button>
                                  </h3>
                                  <Disclosure.Panel className="py-4 px-2 bg-white">
                                    <div className="space-y-4">
                                      {menuObj.sublinkObj?.map(
                                        (option, idx) => (
                                          <div
                                            key={idx}
                                            className="flex items-center"
                                          >
                                            <input
                                              id={option.name}
                                              name={option.name}
                                              defaultValue={option.name}
                                              type="checkbox"
                                              checked={
                                                menuObj.Head === "Purpose"
                                                  ? subCategory.includes(
                                                      option.name
                                                    )
                                                  : menuObj.Head === "Plating"
                                                  ? color.includes(option.name)
                                                  : menuObj.Head === "Category"
                                                  ? category.includes(
                                                      option.name
                                                    )
                                                  : menuObj.Head === "Gender"
                                                  ? gender.includes(option.name)
                                                  : priceRange.includes(
                                                      option.name
                                                    )
                                              }
                                              onChange={(e) => {
                                                if (
                                                  menuObj.Head === "Purpose"
                                                ) {
                                                  handleCheckboxChange(
                                                    e,
                                                    setSubCategory
                                                  );
                                                } else if (
                                                  menuObj.Head === "Plating"
                                                ) {
                                                  handleCheckboxChange(
                                                    e,
                                                    setColor
                                                  );
                                                } else if (
                                                  menuObj.Head === "Price"
                                                ) {
                                                  handleCheckboxChange(
                                                    e,
                                                    setPriceRange
                                                  );
                                                } else if (
                                                  menuObj.Head === "Category"
                                                ) {
                                                  handleCheckboxChange(
                                                    e,
                                                    setCategory
                                                  );
                                                } else if (
                                                  menuObj.Head === "Gender"
                                                ) {
                                                  handleCheckboxChange(
                                                    e,
                                                    setGender
                                                  );
                                                }
                                              }}
                                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            />
                                            <label
                                              htmlFor={option.name}
                                              className="ml-3 text-sm text-gray-600"
                                            >
                                              {option.name}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          ))}
                        </div>
                      ))}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div
          className={`flex w-full bg-white items-baseline justify-between border-b border-gray-200 px-2 md:px-14 py-4 ${
            scrolling ? "fixed z-10 top-[48px] md:top-[60px]" : ""
          }`}
        >
          <form className="hidden lg:flex w-full">
            <div className="flex w-full gap-4">
              {menuFilter
                .filter((menu) => menu.name === "Generic")
                .map((menu, index) => (
                  <div className="flex gap-4" key={index}>
                    {menu.sublinks?.map((menuObj, index) => (
                      <Popover key={index} as="div" className="">
                        {({ open }) => (
                          <>
                            <h3 className="flow-root">
                              <Popover.Button className="relative flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                                <span className="text-md font-semibold text-gray-900">
                                  {menuObj.Head}
                                </span>
                                <span className="ml-2 mr-4 flex items-center">
                                  {open ? (
                                    <IoIosArrowUp
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <IoIosArrowDown
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Popover.Button>
                            </h3>
                            <Popover.Panel className="absolute z-20 py-4 px-2 bg-white border shadow-md">
                              <div className="space-y-4">
                                {menuObj.sublinkObj?.map((option, idx) => (
                                  <div key={idx} className="flex items-center ">
                                    <input
                                      id={option.name}
                                      name={option.name}
                                      defaultValue={option.name}
                                      type="checkbox"
                                      checked={
                                        menuObj.Head === "Purpose"
                                          ? subCategory.includes(option.name)
                                          : menuObj.Head === "Plating"
                                          ? color.includes(option.name)
                                          : menuObj.Head === "Category"
                                          ? category.includes(option.name)
                                          : menuObj.Head === "Gender"
                                          ? gender.includes(option.name)
                                          : priceRange.includes(option.name)
                                      }
                                      onChange={(e) => {
                                        if (menuObj.Head === "Purpose") {
                                          handleCheckboxChange(
                                            e,
                                            setSubCategory
                                          );
                                        } else if (menuObj.Head === "Plating") {
                                          handleCheckboxChange(e, setColor);
                                        } else if (menuObj.Head === "Price") {
                                          handleCheckboxChange(
                                            e,
                                            setPriceRange
                                          );
                                        } else if (
                                          menuObj.Head === "Category"
                                        ) {
                                          handleCheckboxChange(e, setCategory);
                                        } else if (menuObj.Head === "Gender") {
                                          handleCheckboxChange(e, setGender);
                                        }
                                      }}
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={option.name}
                                      className="ml-3 text-sm text-gray-600"
                                    >
                                      {option.name}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Popover.Panel>
                          </>
                        )}
                      </Popover>
                    ))}
                  </div>
                ))}
            </div>
          </form>

          <div className="flex w-full md:w-auto justify-end items-center">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <ChevronDownIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option, idx) => (
                      <Menu.Item key={idx}>
                        {({ active }) => (
                          <button
                            onClick={() => setSort(option.name)}
                            className={classNames(
                              option.current
                                ? "font-medium text-gray-900"
                                : "text-gray-500",
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            {option.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <button
              type="button"
              className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
              onClick={() => {
                setToggle(!toggle);
                setItemsPerPage(toggle ? 40 : 40);
              }}
            >
              <span className="sr-only">View grid</span>
              <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
              onClick={() => {
                setMobileFiltersOpen(true);
              }}
            >
              <span className="sr-only">Filters</span>
              <FunnelIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>

        <main id="prodPage" className="mx-auto max-w-7xl">
          <section aria-labelledby="products-heading" className="pb-12 pt-6">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="md:min-h-[112vh]">
              <div className="">
                <div className="bg-white">
                  <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                    <h2 className="sr-only">Products</h2>

                    {loadingSearch ? (
                      <div>
                        <Loader />
                      </div>
                    ) : errorSearch ? (
                      <div>
                        <Message type="error">{errorSearch}</Message>
                      </div>
                    ) : (
                      <div
                        className={`relative grid mt-4 gap-x-8 gap-y-10 xl:gap-y-10 xl:gap-x-8 ${
                          toggle
                            ? "grid-cols-1 md:grid-cols-4"
                            : "grid-cols-2 md:grid-cols-5"
                        }`}
                      >
                        {currentItems?.map((product, index) => (
                          <div
                            key={index}
                            className="flex flex-col justify-between relative p-2 group/prod"
                          >
                            <RouterLink
                              to={`/product/${product.name?.replaceAll(
                                " ",
                                "-"
                              )}/${product._id}?variant=${
                                product?.variants?.length > 0 &&
                                product?.variants?.filter((variant) =>
                                  selectedPlating.length > 0
                                    ? selectedPlating.includes(variant.color)
                                    : variant.isPrimary
                                )[0]?._id
                              }`}
                            >
                              <div className="aspect-h-1 aspect-w-1 overflow-hidden bg-white">
                                {product?.variants?.length > 0 ? (
                                  <img
                                    src={
                                      product?.variants?.length > 0 &&
                                      product?.variants?.filter((variant) =>
                                        selectedPlating.length > 0
                                          ? selectedPlating.includes(
                                              variant.color
                                            )
                                          : variant.isPrimary
                                      )[0]?.images[0]
                                    }
                                    alt={product?.name}
                                    loading="lazy"
                                    className={
                                      "self-center hover:scale-125 transition-transform ease-in object-cover object-center m-auto bg-[#EBEAE5] text-center"
                                    }
                                  />
                                ) : (
                                  <div className="flex h-full w-full bg-gray-100 items-center justify-center">
                                    <span>Image</span>
                                  </div>
                                )}
                              </div>
                              <h3 className="py-1 mt-3 text-center px-2 text-base font-semibold text-gray-800 h-6 overflow-clip line-clamp-2">
                                {product.name}
                              </h3>
                              {/* <h3 className="py-1 mt-3 text-center px-2 text-[14px] text-gray-500 h-6 overflow-clip truncate">
                              {product.metaDescription}
                            </h3> */}
                              <div className="flex flex-col mt-3 px-2 gap-2 md:gap-4 justify-between items-center">
                                <div className="flex w-full items-center justify-center gap-1 md:gap-3 ">
                                  <p className="text-[14px] text-center text-gray-400 line-through">
                                    ₹{" "}
                                    {product.basePrice?.toLocaleString("en-IN")}
                                  </p>
                                  <p className="text-[14px] text-center md:text-base font-semibold text-[#000080]">
                                    {(
                                      ((product.basePrice - product.price) /
                                        product.basePrice) *
                                      100
                                    ).toFixed(0)}
                                    %
                                  </p>
                                </div>
                                <div className="flex w-full items-center justify-center gap-1 md:gap-3">
                                  <p className="text-sm text-center md:text-base font-bold text-gray-900">
                                    ₹ {product.price?.toLocaleString("en-IN")}
                                  </p>
                                </div>
                              </div>
                            </RouterLink>
                            {/* <div className="w-full pt-2 bg-white hidden group-hover/prod:block"> */}
                            <div className="absolute left-0 -bottom-10 w-full flex items-center bg-white opacity-100 md:opacity-0 md:group-hover/prod:opacity-100 transition-opacity duration-300">
                              {/* //hidden group-hover/prod: */}
                              <div className="flex w-full justify-between items-center gap-1 h-[40px]">
                                {product?.categories?.some(
                                  (cat) => cat.name === "Rings"
                                ) || product?.canEngrave ? (
                                  <button
                                    className="w-3/4 h-full bg-[#000080] rounded text-center self-center text-white text-sm font-medium"
                                    onClick={() => {
                                      if (userInfo && userInfo.firstName) {
                                        onOpen();
                                        setSelectedProduct(product);
                                      } else {
                                        navigate(
                                          `/signup?redirect=/product/${product.name?.replaceAll(
                                            " ",
                                            "-"
                                          )}/${product._id}?variant=${
                                            product?.variants?.length > 0 &&
                                            product?.variants?.filter(
                                              (variant) =>
                                                selectedPlating.length > 0
                                                  ? selectedPlating.includes(
                                                      variant.color
                                                    )
                                                  : variant.isPrimary
                                            )[0]?._id
                                          }`
                                        );
                                      }
                                    }}
                                  >
                                    Add to Cart
                                  </button>
                                ) : (
                                  <button
                                    className="w-3/4 h-full bg-[#000080] rounded text-center self-center text-white text-sm font-medium"
                                    onClick={(e) => {
                                      if (userInfo && userInfo.firstName) {
                                        handleAddToCart(e, {
                                          name: product?.name,
                                          image:
                                            product?.variants?.length > 0 &&
                                            product?.variants?.filter(
                                              (variant) =>
                                                selectedPlating.length > 0
                                                  ? selectedPlating.includes(
                                                      variant.color
                                                    )
                                                  : variant.isPrimary
                                            )[0]?.images[0],
                                          qty,
                                          sizeMen: "1",
                                          sizeWomen: "1",
                                          price: product?.price,
                                          basePrice: product?.basePrice,
                                          product: product?._id,
                                          variant:
                                            product?.variants?.length > 0 &&
                                            product?.variants?.filter(
                                              (variant) => variant?.isPrimary
                                            )[0]?._id,
                                          sku: product?.sku,
                                          giftWrap: false,
                                          message: "",
                                          engraveMen: engravingTextMen,
                                          engraveWomen: engravingTextWomen,
                                          backEng,
                                          engImage,
                                          isPair: false,
                                        });
                                      } else {
                                        navigate(
                                          `/signup?redirect=/product/${product.name?.replaceAll(
                                            " ",
                                            "-"
                                          )}/${product._id}?variant=${
                                            product?.variants?.length > 0 &&
                                            product?.variants?.filter(
                                              (variant) =>
                                                selectedPlating.length > 0
                                                  ? selectedPlating.includes(
                                                      variant.color
                                                    )
                                                  : variant.isPrimary
                                            )[0]?._id
                                          }`
                                        );
                                      }
                                    }}
                                  >
                                    Add to Cart
                                  </button>
                                )}

                                <button
                                  className="flex items-center justify-center w-1/4 h-full border border-[#000080] rounded"
                                  onClick={() => {
                                    wishlistItems?.some(
                                      (item) => item._id === product._id
                                    )
                                      ? removeItemFromWishlistHandler(
                                          product._id
                                        )
                                      : addItemToWishlistHandler(product._id);
                                  }}
                                >
                                  <Icon
                                    as={
                                      wishlistItems?.some(
                                        (item) => item._id === product._id
                                      )
                                        ? AiFillHeart
                                        : AiOutlineHeart
                                    }
                                    w="6"
                                    h="6"
                                    textColor="#000080"
                                    cursor="pointer"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}

                        <Drawer onClose={onClose} isOpen={isOpen} size="lg">
                          <DrawerOverlay />
                          <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerHeader>{`Select size and quantity`}</DrawerHeader>
                            <DrawerBody>
                              <form
                                onSubmit={(e) =>
                                  handleAddToCart(e, {
                                    name: selectedProduct?.name,
                                    image:
                                      selectedProduct?.variants?.length > 0 &&
                                      selectedProduct?.variants?.filter(
                                        (variant) =>
                                          selectedPlating.length > 0
                                            ? selectedPlating.includes(
                                                variant.color
                                              )
                                            : variant.isPrimary
                                      )[0]?.images[0],
                                    qty,
                                    sizeMen: selectedSizeMen,
                                    sizeWomen: selectedSizeWomen,
                                    price: selectedProduct?.price,
                                    basePrice: selectedProduct?.basePrice,
                                    product: selectedProduct?._id,
                                    variant:
                                      selectedProduct?.variants?.length > 0 &&
                                      selectedProduct?.variants?.filter(
                                        (variant) => variant?.isPrimary
                                      )[0]?._id,
                                    sku: selectedProduct?.sku,
                                    giftWrap: false,
                                    message: "",
                                    engraveMen: engravingTextMen,
                                    engraveWomen: engravingTextWomen,
                                    isPair: false,
                                    backEng,
                                    engImage,
                                  })
                                }
                              >
                                <Tabs isManual variant="enclosed">
                                  <TabList>
                                    <Tab>Select Size/Add Engraving</Tab>
                                    <Tab>Size Chart(Indian Size)</Tab>
                                    <Tab>How to measure</Tab>
                                  </TabList>
                                  <TabPanels>
                                    <TabPanel>
                                      <Accordion
                                        allowMultiple
                                        onChange={() =>
                                          handleAccordionToggle(
                                            !isAccordionOpen
                                          )
                                        }
                                        my="6"
                                      >
                                        <AccordionItem>
                                          <h2>
                                            <AccordionButton>
                                              <FaRegEdit className="w-5 h-5" />
                                              <Box
                                                as="span"
                                                flex="1"
                                                textAlign="left"
                                              >
                                                Add Engraving
                                              </Box>
                                              <AccordionIcon />
                                            </AccordionButton>
                                          </h2>
                                          {selectedProduct?.categories?.some(
                                            (cat) =>
                                              cat.name == "Rings" ||
                                              cat.name == "JEWELLERY SETS"
                                          ) && (
                                            <>
                                              {selectedProduct?.subcategories?.some(
                                                (subcat) =>
                                                  subcat.name === "Couple Rings"
                                              ) ? (
                                                <>
                                                  <AccordionPanel pb={4}>
                                                    <input
                                                      type="text"
                                                      maxLength="10" // Limits input to 10 characters
                                                      pattern="[A-Za-z]*" // Allows only letters and spaces
                                                      value={engravingTextMen} // Controlled input
                                                      className="border px-2 py-1 rounded"
                                                      placeholder="Engraving for men"
                                                      onChange={(e) =>
                                                        handleEngravingInput(
                                                          "male",
                                                          e.target.value
                                                        )
                                                      } // Handle engraving input
                                                    />
                                                    <p className="text-sm text-gray-500">
                                                      Max 10 characters, letters
                                                      only.
                                                    </p>
                                                  </AccordionPanel>
                                                  <AccordionPanel pb={4}>
                                                    <input
                                                      type="text"
                                                      maxLength="10" // Limits input to 10 characters
                                                      pattern="[A-Za-z]*" // Allows only letters and spaces
                                                      value={engravingTextWomen} // Controlled input
                                                      className="border px-2 py-1 rounded"
                                                      placeholder="Engraving for women"
                                                      onChange={(e) =>
                                                        handleEngravingInput(
                                                          "female",
                                                          e.target.value
                                                        )
                                                      } // Handle engraving input
                                                    />
                                                    <p className="text-sm text-gray-500">
                                                      Max 10 characters, letters
                                                      only.
                                                    </p>
                                                  </AccordionPanel>
                                                </>
                                              ) : (
                                                <AccordionPanel pb={4}>
                                                  <input
                                                    type="text"
                                                    maxLength="10" // Limits input to 10 characters
                                                    pattern="[A-Za-z]*" // Allows only letters and spaces
                                                    value={
                                                      selectedProduct?.gender ===
                                                      "female"
                                                        ? engravingTextWomen
                                                        : engravingTextMen
                                                    } // Controlled input
                                                    className="border px-2 py-1 rounded"
                                                    placeholder={`Engraving Text`}
                                                    onChange={(e) => {
                                                      if (
                                                        selectedProduct?.gender ===
                                                        "female"
                                                      ) {
                                                        handleEngravingInput(
                                                          "female",
                                                          e.target.value
                                                        );
                                                      } else {
                                                        handleEngravingInput(
                                                          "male",
                                                          e.target.value
                                                        );
                                                      }
                                                    }} // Handle engraving input
                                                  />
                                                  <p className="text-sm text-gray-500">
                                                    Max 10 characters, letters
                                                    only.
                                                  </p>
                                                </AccordionPanel>
                                              )}
                                            </>
                                          )}
                                          {selectedProduct?.productCollections?.some(
                                            (cat) =>
                                              cat.name == "Pet Collection"
                                          ) &&
                                            selectedProduct?.canEngrave && (
                                              <>
                                                <AccordionPanel pb={4}>
                                                  <div className="w-full md:w-[60%] relative">
                                                    <img
                                                      className="w-full aspect-[3/2] object-cover object-center"
                                                      src={
                                                        selectedProduct.frontPreviewImg
                                                      }
                                                    />
                                                    <div className="flex text-4xl font-ScriptMTBold absolute inset-0 items-center justify-center z-10 text-center">
                                                      {engravingTextWomen}
                                                    </div>
                                                  </div>
                                                  <input
                                                    type="text"
                                                    maxLength="10" // Limits input to 10 characters
                                                    pattern="[A-Za-z]*" // Allows only letters and spaces
                                                    value={engravingTextWomen} // Controlled input
                                                    className="border px-2 py-1 rounded"
                                                    placeholder="Engraving Text"
                                                    onChange={(e) =>
                                                      handleEngravingInput(
                                                        "female",
                                                        e.target.value
                                                      )
                                                    } // Handle engraving input
                                                  />
                                                  <p className="text-sm text-gray-500">
                                                    Max 10 characters, letters
                                                    only.
                                                  </p>
                                                </AccordionPanel>
                                                {selectedProduct?.canBackEng && (
                                                  <AccordionPanel pb={4}>
                                                    <div className="w-full md:w-[60%] relative">
                                                      <img
                                                        className="w-full aspect-[3/2] object-cover object-center"
                                                        src={
                                                          selectedProduct.backPreviewImg
                                                        }
                                                      />
                                                      <div className="flex text-4xl font-MvBoli absolute inset-0 items-center justify-center z-10 text-center">
                                                        {backEng}
                                                      </div>
                                                    </div>
                                                    <input
                                                      type="text"
                                                      maxLength="10" // Limits input to 10 characters
                                                      pattern="[A-Za-z]*" // Allows only letters and spaces
                                                      value={backEng} // Controlled input
                                                      className="border px-2 py-1 rounded"
                                                      placeholder="Engraving Text"
                                                      onChange={(e) =>
                                                        setbackEng(
                                                          e.target.value
                                                        )
                                                      } // Handle engraving input
                                                    />
                                                    <p className="text-sm text-gray-500">
                                                      Max 10 characters, letters
                                                      only.
                                                    </p>
                                                  </AccordionPanel>
                                                )}
                                              </>
                                            )}
                                        </AccordionItem>
                                      </Accordion>

                                      <div className="flex flex-col gap-6 my-6">
                                        <div>
                                          <label htmlFor="quantitySelect">
                                            Quantity:
                                          </label>
                                          <div className="flex items-center">
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleQuantityChange(
                                                  qty > 1 ? qty - 1 : 1
                                                )
                                              }
                                              className="px-4 py-2 bg-[#F3F3F3] text-[#000080] font-bold text-xl focus:outline-none"
                                            >
                                              -
                                            </button>
                                            <input
                                              type="text"
                                              id="quantitySelect"
                                              value={qty}
                                              onChange={(e) =>
                                                handleQuantityChange(
                                                  Number(e.target.value)
                                                )
                                              }
                                              className="px-4 py-2 w-[45px] text-center text-xl bg-[#F3F3F3] focus:outline-none border-none"
                                              readOnly
                                            />
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleQuantityChange(
                                                  qty < 5 ? qty + 1 : 5
                                                )
                                              }
                                              className="px-4 py-2 bg-[#F3F3F3] text-[#000080] font-bold text-xl focus:outline-none"
                                            >
                                              +
                                            </button>
                                          </div>
                                        </div>

                                        {selectedProduct?.canImgEng && (
                                          <div className="mb-4">
                                            <label
                                              htmlFor="image"
                                              className="block text-gray-700"
                                            >
                                              Upload Image For Engraving
                                            </label>
                                            <input
                                              type="file"
                                              id="image"
                                              onChange={(e) =>
                                                uploadImageHandler(
                                                  e,
                                                  setengImage
                                                )
                                              }
                                              className="w-full p-2 border border-gray-300 rounded"
                                            />
                                          </div>
                                        )}

                                        {selectedProduct?.categories?.find(
                                          (cat) =>
                                            cat.name === "Rings" ||
                                            cat.name === "JEWELLERY SETS"
                                        ) && (
                                          <div>
                                            <label htmlFor="sizeSelect">
                                              Size:
                                            </label>
                                            {selectedProduct?.subcategories?.some(
                                              (subcat) =>
                                                subcat.name === "Couple Rings"
                                            ) ? (
                                              <div className="flex gap-6">
                                                <Select
                                                  id="sizeSelect"
                                                  border="1px solid"
                                                  _hover="none"
                                                  className="pt-0"
                                                  pt="0px"
                                                  borderColor="[#000080]"
                                                  value={selectedSizeMen}
                                                  onChange={(e) =>
                                                    setSelectedSizeMen(
                                                      e.target.value
                                                    )
                                                  }
                                                  required
                                                >
                                                  <option value={0}>
                                                    Select Size Men
                                                  </option>
                                                  {loadingSize ? (
                                                    <Loader />
                                                  ) : errorSize ? (
                                                    <Message type="error">
                                                      {errorSize}
                                                    </Message>
                                                  ) : (
                                                    sizes.map((num) => (
                                                      <option
                                                        key={num?.size}
                                                        value={num?.size}
                                                      >
                                                        {num?.size}
                                                      </option>
                                                    ))
                                                  )}
                                                </Select>
                                                <Select
                                                  id="sizeSelect"
                                                  border="1px solid"
                                                  _hover="none"
                                                  className="pt-0"
                                                  pt="0px"
                                                  borderColor="[#000080]"
                                                  value={selectedSizeWomen}
                                                  onChange={(e) =>
                                                    setSelectedSizeWomen(
                                                      e.target.value
                                                    )
                                                  }
                                                  required
                                                >
                                                  <option value={0}>
                                                    Select Size Women
                                                  </option>
                                                  {loadingSize ? (
                                                    <Loader />
                                                  ) : errorSize ? (
                                                    <Message type="error">
                                                      {errorSize}
                                                    </Message>
                                                  ) : (
                                                    sizes.map((num) => (
                                                      <option
                                                        key={num?.size}
                                                        value={num?.size}
                                                      >
                                                        {num?.size}
                                                      </option>
                                                    ))
                                                  )}
                                                </Select>
                                              </div>
                                            ) : (
                                              <Select
                                                id="sizeSelect"
                                                border="1px solid"
                                                _hover="none"
                                                className="pt-0"
                                                pt="0px"
                                                borderColor="[#000080]"
                                                value={
                                                  selectedProduct?.gender ===
                                                  "female"
                                                    ? selectedSizeWomen
                                                    : selectedSizeMen
                                                }
                                                onChange={(e) => {
                                                  if (
                                                    selectedProduct?.gender ===
                                                    "female"
                                                  ) {
                                                    setSelectedSizeWomen(
                                                      e.target.value
                                                    );
                                                  } else {
                                                    setSelectedSizeMen(
                                                      e.target.value
                                                    );
                                                  }
                                                }}
                                                required
                                              >
                                                <option value={0}>
                                                  Select Size
                                                </option>
                                                {loadingSize ? (
                                                  <Loader />
                                                ) : errorSize ? (
                                                  <Message type="error">
                                                    {errorSize}
                                                  </Message>
                                                ) : (
                                                  sizes.map((num) => (
                                                    <option
                                                      key={num?.size}
                                                      value={num?.size}
                                                    >
                                                      {num?.size}
                                                    </option>
                                                  ))
                                                )}
                                              </Select>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </TabPanel>
                                    <TabPanel>
                                      <div className="mt-2 max-h-[60vh] overflow-y-auto">
                                        <table className="w-full border-collapse border border-slate-500">
                                          <tr className="text-center">
                                            <th className="w-1/3 border border-slate-300 p-1">
                                              Size
                                            </th>
                                            <th className="w-2/3 border border-slate-300 p-1">
                                              Internal Diameter(mm.)
                                            </th>
                                          </tr>
                                          {loadingSize ? (
                                            <Loader />
                                          ) : errorSize ? (
                                            <Message type="error">
                                              {errorSize}
                                            </Message>
                                          ) : (
                                            sizes?.map((size) => (
                                              <tr className="text-center">
                                                <td className="border border-slate-300 p-1">
                                                  {size?.size}
                                                </td>
                                                <td className="border border-slate-300 p-1">
                                                  {size?.internalDiameterMM} mm.
                                                </td>
                                              </tr>
                                            ))
                                          )}
                                        </table>
                                      </div>
                                    </TabPanel>
                                    <TabPanel>
                                      <p className="flex w-full justify-center items-center text-lg font-bold py-8">
                                        If you dont have ring
                                      </p>
                                      <div className="grid grid-cols-2 gap-8">
                                        <div className="flex justify-center items-center">
                                          <img
                                            src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-2.png"
                                            alt="instructions"
                                            className="h-[120px]"
                                          />
                                        </div>
                                        <div>
                                          Take a small piece of string or thread
                                          or ribbon. Wrap it around your finger.
                                        </div>

                                        <div className="flex justify-center items-center">
                                          <img
                                            src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-3.webp"
                                            alt="instructions"
                                            className="h-[120px]"
                                          />
                                        </div>
                                        <div>
                                          Mark the spot where the thread meets.
                                        </div>

                                        <div className="flex justify-center items-center">
                                          <img
                                            src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-4.webp"
                                            alt="instructions"
                                            className="h-[120px]"
                                          />
                                        </div>
                                        <div>
                                          Measure the length of the string with
                                          your ruler.
                                        </div>
                                      </div>

                                      <p className="flex w-full justify-center items-center pt-8">
                                        --------------------------------------------------------------------------------------------
                                      </p>

                                      <p className="flex w-full justify-center items-center text-lg font-bold pb-8">
                                        If you have a ring
                                      </p>
                                      <div className="grid grid-cols-2 gap-8">
                                        <div className="flex justify-center items-center">
                                          <img
                                            src="https://d8v879q3glvao.cloudfront.net/Static_images/Ring-size-guide-ruler.jpg"
                                            alt="instructions"
                                            className="h-[120px]"
                                          />
                                        </div>
                                        <div>
                                          1 : Place the ring
                                          <br />2 : Measure the inner diameter
                                          within inner edges of your ring with a
                                          ruler.
                                        </div>
                                      </div>
                                    </TabPanel>
                                  </TabPanels>
                                </Tabs>
                                <Button
                                  type="submit"
                                  isDisabled={
                                    selectedProduct?.categories.some(
                                      (prod) =>
                                        prod.name === "Rings" ||
                                        prod.name == "JEWELLERY SETS"
                                    ) && selectedProduct?.gender === "female"
                                      ? selectedSizeWomen === 0
                                      : selectedProduct?.categories.some(
                                          (prod) =>
                                            prod.name === "Rings" ||
                                            prod.name == "JEWELLERY SETS"
                                        ) && selectedProduct?.gender === "male"
                                      ? selectedSizeMen === 0
                                      : selectedProduct?.productCollections.some(
                                          (prod) =>
                                            prod.name === "Pet Collection"
                                        ) && selectedProduct?.canImgEng
                                      ? engImage === ""
                                      : false
                                  }
                                  bgColor="#000080"
                                  textColor="white"
                                  _hover={{ bgColor: "#000090" }}
                                >
                                  Next
                                </Button>
                              </form>
                            </DrawerBody>
                          </DrawerContent>
                        </Drawer>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {loading ? (
            <></>
          ) : error ? (
            <></>
          ) : (
            <Pagination
              page={page}
              setPage={setPage}
              handlePage={handlePage}
              totalItems={productSearch?.length}
              itemsPerPage={itemsPerPage}
            ></Pagination>
          )}
        </main>
      </div>
    </div>
  );
}
