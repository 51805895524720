import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  createCategory,
  updateCategory,
  getCategoryDetails,
} from "../actions/categoryActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  CATEGORY_CREATE_RESET,
  CATEGORY_UPDATE_RESET,
} from "../constants/categoryConstants";
import {
  COLLECTION_CREATE_RESET,
  COLLECTION_UPDATE_RESET,
} from "../constants/collectionConstants";
import {
  createCollection,
  getCollectionDetails,
  updateCollection,
} from "../actions/collectionActions";
import axios from "axios";
import { serverIp } from "../config/conf";

function CollectionsForm() {
  const { id: collectionId } = useParams(); // Get category ID if editing
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [thumbImage, setThumbImage] = useState("");
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Access category creation and update states
  const collectionCreate = useSelector((state) => state.collectionCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = collectionCreate;

  const collectionUpdate = useSelector((state) => state.collectionUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = collectionUpdate;

  const collectionDetails = useSelector((state) => state.collectionDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    collection,
  } = collectionDetails;

  // When editing, load the category details
  useEffect(() => {
    if (collectionId) {
      if (!collection || collection._id !== collectionId || successUpdate) {
        dispatch({ type: COLLECTION_UPDATE_RESET });
        dispatch(getCollectionDetails(collectionId));
      } else {
        setName(collection.name);
        setDescription(collection.description);
        setImage(collection.image);
        setThumbImage(collection.thumbImage);
      }
    }
    if (successCreate) {
      dispatch({ type: COLLECTION_CREATE_RESET });
      navigate("/dashboard/collections");
    } else if (successUpdate) {
      dispatch({ type: COLLECTION_UPDATE_RESET });
      navigate("/dashboard/collections");
    }
  }, [
    dispatch,
    collectionId,
    collection,
    successCreate,
    successUpdate,
    navigate,
  ]);

  // Handle form submission for both create and update
  const submitHandler = (e) => {
    e.preventDefault();

    const collectionData = {
      name,
      description,
      image,
      thumbImage,
    };

    if (collectionId) {
      dispatch(updateCollection({ _id: collectionId, ...collectionData })); // Update category
    } else {
      dispatch(createCollection(collectionData)); // Create category
    }
  };

  const uploadImageHandler = async (e, setImageCallback) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${serverIp}/api/uploads/single`,
        formData,
        config
      );

      // Use the passed callback to set the image URL
      setImageCallback(data);

      setUploading(false);
    } catch (err) {
      console.error(err);
      setUploading(false);
    }
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-2xl font-semibold mb-4">
        {collectionId ? "Edit Collection" : "Add New Collection"}
      </h2>

      {/* Show loader or error messages */}
      {(loadingCreate || loadingUpdate || loadingDetails) && <Loader />}
      {(errorCreate || errorUpdate || errorDetails) && (
        <Message type="error">
          {errorCreate || errorUpdate || errorDetails}
        </Message>
      )}
      {successUpdate && (
        <Message type="success">Collection Updated Successfully!</Message>
      )}
      {successCreate && (
        <Message type="success">Collection Created Successfully!</Message>
      )}

      <form onSubmit={submitHandler}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700">
            Collection Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="description" className="block text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            rows="4"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="image" className="block text-gray-700">
            Image URL
          </label>
          <input
            type="file"
            id="image"
            onChange={(e) => uploadImageHandler(e, setImage)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="thumbImage" className="block text-gray-700">
            Thumbnail Image URL
          </label>
          <input
            type="file"
            id="thumbImage"
            onChange={(e) => uploadImageHandler(e, setThumbImage)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700 transition"
        >
          {collectionId ? "Update Collection" : "Add Collection"}
        </button>
      </form>
    </div>
  );
}

export default CollectionsForm;
