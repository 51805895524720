import {
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_RESET,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_REVIEW_CREATE_FAIL,
  PRODUCT_REVIEW_CREATE_REQUEST,
  PRODUCT_REVIEW_CREATE_RESET,
  PRODUCT_REVIEW_CREATE_SUCCESS,
  PRODUCT_SEARCH_LIST_FAIL,
  PRODUCT_SEARCH_LIST_REQUEST,
  PRODUCT_SEARCH_LIST_SUCCESS,
  PRODUCT_SETS_LIST_FAIL,
  PRODUCT_SETS_LIST_REQUEST,
  PRODUCT_SETS_LIST_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_RESET,
  PRODUCT_UPDATE_SUCCESS,
  RELATED_PRODUCT_LIST_FAIL,
  RELATED_PRODUCT_LIST_REQUEST,
  RELATED_PRODUCT_LIST_SUCCESS,
  YMAL_PRODUCT_LIST_FAIL,
  YMAL_PRODUCT_LIST_REQUEST,
  YMAL_PRODUCT_LIST_SUCCESS,
} from "../constants/productConstants";

export const productListReducer = (
  state = { products: [{ variants: [] }] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [{ variants: [] }] };
    case PRODUCT_LIST_SUCCESS:
      return { loading: false, ...action.payload };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productSetsListReducer = (
  state = { products: [{ variants: [] }] },
  action
) => {
  switch (action.type) {
    case PRODUCT_SETS_LIST_REQUEST:
      return { loading: true, products: [{ variants: [] }] };
    case PRODUCT_SETS_LIST_SUCCESS:
      return { loading: false, ...action.payload };
    case PRODUCT_SETS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const relatedProductListReducer = (
  state = { products: [{ variants: [] }] },
  action
) => {
  switch (action.type) {
    case RELATED_PRODUCT_LIST_REQUEST:
      return { loading: true, products: [{ variants: [] }] };
    case RELATED_PRODUCT_LIST_SUCCESS:
      return { loading: false, ...action.payload };
    case RELATED_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ymalProductListReducer = (
  state = { products: [{ variants: [] }] },
  action
) => {
  switch (action.type) {
    case YMAL_PRODUCT_LIST_REQUEST:
      return { loading: true, products: [{ variants: [] }] };
    case YMAL_PRODUCT_LIST_SUCCESS:
      return { loading: false, ...action.payload };
    case YMAL_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productSearchListReducer = (
  state = { products: [{ variants: [] }] },
  action
) => {
  switch (action.type) {
    case PRODUCT_SEARCH_LIST_REQUEST:
      return { loading: true, products: [{ variants: [] }] };
    case PRODUCT_SEARCH_LIST_SUCCESS:
      return { loading: false, ...action.payload };
    case PRODUCT_SEARCH_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productDetailsReducer = (
  state = { product: { image: [], reviews: [], variants: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, ...action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, ...action.payload };
    case PRODUCT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PRODUCT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productUpdateReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { ...state, loading: true };
    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_UPDATE_RESET:
      return { product: {} };
    default:
      return state;
  }
};

export const productReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_REVIEW_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_REVIEW_CREATE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_REVIEW_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_REVIEW_CREATE_RESET:
      return {};
    default:
      return state;
  }
};
