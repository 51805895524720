import axios from 'axios';
import {
    STYLE_CREATE_REQUEST,
    STYLE_CREATE_SUCCESS,
    STYLE_CREATE_FAIL,
    STYLE_LIST_REQUEST,
    STYLE_LIST_SUCCESS,
    STYLE_LIST_FAIL,
    STYLE_DETAILS_REQUEST,
    STYLE_DETAILS_SUCCESS,
    STYLE_DETAILS_FAIL,
    STYLE_UPDATE_REQUEST,
    STYLE_UPDATE_SUCCESS,
    STYLE_UPDATE_FAIL,
    STYLE_DELETE_REQUEST,
    STYLE_DELETE_SUCCESS,
    STYLE_DELETE_FAIL,
} from '../constants/stylesConstants';
import { serverIp } from '../config/conf';

export const createStyle = (styleData) => async (dispatch, getState) => {
    try {
        dispatch({ type: STYLE_CREATE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.post(`${serverIp}/api/style`, styleData, config);

        dispatch({
            type: STYLE_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: STYLE_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const listStyles = () => async (dispatch) => {
    try {
        dispatch({ type: STYLE_LIST_REQUEST });

        const { data } = await axios.get(`${serverIp}/api/style`);

        dispatch({
            type: STYLE_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: STYLE_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getStyleDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: STYLE_DETAILS_REQUEST });

        const { data } = await axios.get(`${serverIp}/api/style/${id}`);

        dispatch({
            type: STYLE_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: STYLE_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const updateStyle = (styleData) => async (dispatch, getState) => {
    try {
        dispatch({ type: STYLE_UPDATE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.put(`${serverIp}/api/style/${styleData._id}`, styleData, config);

        dispatch({
            type: STYLE_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: STYLE_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteStyle = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: STYLE_DELETE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`${serverIp}/api/style/${id}`, config);

        dispatch({
            type: STYLE_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: STYLE_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
