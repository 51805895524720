// src/constants/addressConstants.js

export const ADDRESS_CREATE_REQUEST = "ADDRESS_CREATE_REQUEST";
export const ADDRESS_CREATE_SUCCESS = "ADDRESS_CREATE_SUCCESS";
export const ADDRESS_CREATE_FAIL = "ADDRESS_CREATE_FAIL";
export const ADDRESS_CREATE_RESET = "ADDRESS_CREATE_RESET";

export const ADDRESS_LIST_REQUEST = "ADDRESS_LIST_REQUEST";
export const ADDRESS_LIST_SUCCESS = "ADDRESS_LIST_SUCCESS";
export const ADDRESS_LIST_FAIL = "ADDRESS_LIST_FAIL";

export const ADDRESS_DETAILS_REQUEST = "ADDRESS_DETAILS_REQUEST";
export const ADDRESS_DETAILS_SUCCESS = "ADDRESS_DETAILS_SUCCESS";
export const ADDRESS_DETAILS_FAIL = "ADDRESS_DETAILS_FAIL";

export const ADDRESS_UPDATE_REQUEST = "ADDRESS_UPDATE_REQUEST";
export const ADDRESS_UPDATE_SUCCESS = "ADDRESS_UPDATE_SUCCESS";
export const ADDRESS_UPDATE_FAIL = "ADDRESS_UPDATE_FAIL";
export const ADDRESS_UPDATE_RESET = "ADDRESS_UPDATE_RESET";

export const ADDRESS_DELETE_REQUEST = "ADDRESS_DELETE_REQUEST";
export const ADDRESS_DELETE_SUCCESS = "ADDRESS_DELETE_SUCCESS";
export const ADDRESS_DELETE_FAIL = "ADDRESS_DELETE_FAIL";
export const ADDRESS_DELETE_RESET = "ADDRESS_DELETE_RESET";
