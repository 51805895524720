import { Select } from "@chakra-ui/react";
import React from "react";
import Loader from "./Loader";
import Message from "./Message";

const RingSize = ({selectedProduct,loadingSize,errorSize,selectedSizeMen,setSelectedSizeMen,sizes,selectedSizeWomen,setSelectedSizeWomen}) => {
  return (
    <div>
      <label htmlFor="sizeSelect">Size:</label>
      {selectedProduct?.subcategories?.some(
        (subcat) => subcat.name === "Couple Rings"
      ) ? (
        <div className="flex gap-6">
          <Select
            id="sizeSelect"
            border="1px solid"
            _hover="none"
            className="pt-0"
            pt="0px"
            borderColor="[#000080]"
            value={selectedSizeMen}
            onChange={(e) => setSelectedSizeMen(e.target.value)}
            required
          >
            <option value={0}>Select Size Men</option>
            {loadingSize ? (
              <Loader />
            ) : errorSize ? (
              <Message type="error">{errorSize}</Message>
            ) : (
              sizes.map((num) => (
                <option key={num?.size} value={num?.size}>
                  {num?.size}
                </option>
              ))
            )}
          </Select>
          <Select
            id="sizeSelect"
            border="1px solid"
            _hover="none"
            className="pt-0"
            pt="0px"
            borderColor="[#000080]"
            value={selectedSizeWomen}
            onChange={(e) => setSelectedSizeWomen(e.target.value)}
            required
          >
            <option value={0}>Select Size Women</option>
            {loadingSize ? (
              <Loader />
            ) : errorSize ? (
              <Message type="error">{errorSize}</Message>
            ) : (
              sizes.map((num) => (
                <option key={num?.size} value={num?.size}>
                  {num?.size}
                </option>
              ))
            )}
          </Select>
        </div>
      ) : (
        <Select
          id="sizeSelect"
          border="1px solid"
          _hover="none"
          className="pt-0"
          pt="0px"
          borderColor="[#000080]"
          value={
            selectedProduct?.gender === "female"
              ? selectedSizeWomen
              : selectedSizeMen
          }
          onChange={(e) => {
            if (selectedProduct?.gender === "female") {
              setSelectedSizeWomen(e.target.value);
            } else {
              setSelectedSizeMen(e.target.value);
            }
          }}
          required
        >
          <option value={0}>Select Size</option>
          {loadingSize ? (
            <Loader />
          ) : errorSize ? (
            <Message type="error">{errorSize}</Message>
          ) : (
            sizes.map((num) => (
              <option key={num?.size} value={num?.size}>
                {num?.size}
              </option>
            ))
          )}
        </Select>
      )}
    </div>
  );
};

export default RingSize;
