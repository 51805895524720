import React, {useState, useEffect } from 'react'
import Slider from "react-slick";
import Message from "./Message";
import { Link as RouterLink } from "react-router-dom";
import axios from 'axios'



function PetCollection() {

    // const dispatch = useDispatch();

    // const productList = useSelector((state) => state.productList);
    // const { loading, error, products } = productList;

    const settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        // prevArrow: <SamplePrevArrow />,
        // nextArrow: <SampleNextArrow />,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        ;(async () => {
            try {
                setLoading(true)
                setError(null)
                const response = await axios.get("https://sairahjewels.com/api/products?productCollections=66f7d8233bd3aed2eb69988c")
                console.log(response.data)
                setData(response.data.products)
                setLoading(false)
            } catch (error) {
                console.log(error.response.data.message)
                setError(error.response.data.message)
                setLoading(false)
            }
        })()
    }, [])

    return (
        <div className={`mx-auto max-w-7xl px-4 sm:px-6 lg:px-8`}>
            <p className="text-center text-xl font-semibold py-10">PET COLLECTIONS</p>
            {loading ? (
                <div></div>
            ) : error ? (
                <Message type="error">{error}</Message>
            ) : (
                <Slider
                    {...settings}
                >
                    {data?.map((prod) => (
                        <div
                            className={`relative cursor-pointer px-3 md:px-6 ease-in transition hover:scale-105`}
                        >
                            <RouterLink
                                className="block"
                                to={`/product/${prod.name?.replaceAll(" ", "-")}/${prod._id
                                    }?variant=${prod?.variants?.length > 0 &&
                                    prod?.variants?.filter((variant) => variant.isPrimary)[0]?._id
                                    }`}
                            >
                                <img
                                    src={
                                        prod?.variants?.filter((vari) => vari.isPrimary)[0]
                                            ?.images[0]
                                    }
                                    loading="lazy"
                                    alt="prod1"
                                    className="aspect-1 object-center object-cover"
                                />
                            </RouterLink>

                        </div>
                    ))}
                </Slider>
            )}
        </div>
    )
}

export default PetCollection
