import { AccordionIcon, AccordionPanel, Box ,Accordion, AccordionButton, AccordionItem } from "@chakra-ui/react";
import React from "react";
// import { Accordion, AccordionButton, AccordionItem } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";

const AddEngravingSection = ({
  selectedProduct,
  handleAccordionToggle,
  isAccordionOpen,
  engravingTextMen,
  engravingTextWomen,
  handleEngravingInput,
  backEng,
  setbackEng,
}) => {
  return (
    <div>
      <Accordion
        allowMultiple
        onChange={() => handleAccordionToggle(!isAccordionOpen)}
        my="6"
      >
        <AccordionItem>
          <h2>
            <AccordionButton>
              <FaRegEdit className="w-5 h-5" />
              <Box as="span" flex="1" textAlign="left">
                Add Engraving
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          {selectedProduct?.categories?.some(
            (cat) => cat.name == "Rings" || cat.name == "JEWELLERY SETS"
          ) && (
            <>
              {selectedProduct?.subcategories?.some(
                (subcat) => subcat.name === "Couple Rings"
              ) ? (
                <>
                  <AccordionPanel pb={4}>
                    <input
                      type="text"
                      maxLength="10" // Limits input to 10 characters
                      pattern="[A-Za-z]*" // Allows only letters and spaces
                      value={engravingTextMen} // Controlled input
                      className="border px-2 py-1 rounded"
                      placeholder="Engraving for men"
                      onChange={(e) =>
                        handleEngravingInput("male", e.target.value)
                      } // Handle engraving input
                    />
                    <p className="text-sm text-gray-500">
                      Max 10 characters, letters only.
                    </p>
                  </AccordionPanel>
                  <AccordionPanel pb={4}>
                    <input
                      type="text"
                      maxLength="10" // Limits input to 10 characters
                      pattern="[A-Za-z]*" // Allows only letters and spaces
                      value={engravingTextWomen} // Controlled input
                      className="border px-2 py-1 rounded"
                      placeholder="Engraving for women"
                      onChange={(e) =>
                        handleEngravingInput("female", e.target.value)
                      } // Handle engraving input
                    />
                    <p className="text-sm text-gray-500">
                      Max 10 characters, letters only.
                    </p>
                  </AccordionPanel>
                </>
              ) : (
                <AccordionPanel pb={4}>
                  <input
                    type="text"
                    maxLength="10" // Limits input to 10 characters
                    pattern="[A-Za-z]*" // Allows only letters and spaces
                    value={
                      selectedProduct?.gender === "female"
                        ? engravingTextWomen
                        : engravingTextMen
                    } // Controlled input
                    className="border px-2 py-1 rounded"
                    placeholder={`Engraving Text`}
                    onChange={(e) => {
                      if (selectedProduct?.gender === "female") {
                        handleEngravingInput("female", e.target.value);
                      } else {
                        handleEngravingInput("male", e.target.value);
                      }
                    }} // Handle engraving input
                  />
                  <p className="text-sm text-gray-500">
                    Max 10 characters, letters only.
                  </p>
                </AccordionPanel>
              )}
            </>
          )}
          {selectedProduct?.productCollections?.some(
            (cat) => cat.name == "Pet Collection"
          ) &&
            selectedProduct?.canEngrave && (
              <>
                <AccordionPanel pb={4}>
                  <div className="w-full md:w-[60%] relative">
                    <img
                      className="w-full aspect-[3/2] object-cover object-center"
                      src={selectedProduct.frontPreviewImg}
                    />
                    <div className="flex text-4xl font-ScriptMTBold absolute inset-0 items-center justify-center z-10 text-center">
                      {engravingTextWomen}
                    </div>
                  </div>
                  <input
                    type="text"
                    maxLength="10" // Limits input to 10 characters
                    pattern="[A-Za-z]*" // Allows only letters and spaces
                    value={engravingTextWomen} // Controlled input
                    className="border px-2 py-1 rounded"
                    placeholder="Front Engraving Text"
                    onChange={(e) =>
                      handleEngravingInput("female", e.target.value)
                    } // Handle engraving input
                  />
                  <p className="text-sm text-gray-500">
                    Max 10 characters, letters only.
                  </p>
                </AccordionPanel>
                {selectedProduct?.canBackEng && (
                  <AccordionPanel pb={4}>
                    <div className="w-full md:w-[60%] relative">
                      <img
                        className="w-full aspect-[3/2] object-cover object-center"
                        src={selectedProduct.backPreviewImg}
                      />
                      <div className="flex text-4xl font-MvBoli absolute inset-0 items-center justify-center z-10 text-center">
                        {backEng}
                      </div>
                    </div>
                    <input
                      type="text"
                      maxLength="10" // Limits input to 10 characters
                      pattern="[A-Za-z]*" // Allows only letters and spaces
                      value={backEng} // Controlled input
                      className="border px-2 py-1 rounded"
                      placeholder="Back Engraving Text"
                      onChange={(e) => setbackEng(e.target.value)} // Handle engraving input
                    />
                    <p className="text-sm text-gray-500">
                      Max 10 characters, letters only.
                    </p>
                  </AccordionPanel>
                )}
              </>
            )}
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AddEngravingSection;
