import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { listCategory, listCatSubcategory } from "../actions/categoryActions";
import { listCollections } from "../actions/collectionActions";
import Loader from "./Loader";
import Message from "./Message";
import Slider from "react-slick";
import { listSubCategory } from "../actions/subCategoryActions";
import { Link } from "react-router-dom";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <MdNavigateNext
      className={className}
      style={{
        color: "gray",
        fontSize: "45px",
        height: "55px",
        width: "55px",
        paddingLeft: "20px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <MdNavigateBefore
      className={className}
      style={{
        color: "gray",
        fontSize: "45px",
        height: "55px",
        width: "55px",
        paddingRight: "20px",
      }}
      onClick={onClick}
    />
  );
}

export default function MenuFilterComp(props) {
  const dispatch = useDispatch();

  const { loading, error, categories } = useSelector(
    (state) => state.categoryList
  );

  const [slidesToShow, setSlidesToShow] = useState(4);

  const {
    loading: loadingSubCat,
    error: errorSubCat,
    subcategories,
  } = useSelector((state) => state.subCategoryList);

  const {
    loading: loadingColl,
    error: errorColl,
    collections,
  } = useSelector((state) => state.collectionList);

  useEffect(() => {
    dispatch(listCategory());
    dispatch(listSubCategory());
    dispatch(listCollections());
  }, [dispatch]);

  useEffect(() => {
    if (
      subcategories?.filter(
        (cat) => cat.category._id === props.categoriesQuery
      ).length > 0 &&
      subcategories?.filter(
        (cat) => cat.category._id === props.categoriesQuery
      ).length <= 4
    ) {
      setSlidesToShow(
        subcategories?.filter(
          (cat) => cat.category._id === props.categoriesQuery
        ).length
      );
    }
  }, [subcategories, props.categoriesQuery]);

  const settings = {
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //   categoriesQuery
  // collectionsQuery
  // subCategoriesQuery
  // isLatestArrivalQuery
  // genderQuery

  return (
    <div className={`mx-auto w-full md:max-w-7xl px-4 sm:px-6 lg:px-8`}>
      {props.categoriesQuery !== null ? (
        <div className="bg-white p-5 w-full md:max-w-6xl mx-auto px-6 md:px-16">
          {loadingSubCat ? (
            <Loader />
          ) : errorSubCat ? (
            <Message type="error">{errorSubCat}</Message>
          ) : (
            <Slider
              prevArrow={<SamplePrevArrow />}
              nextArrow={<SampleNextArrow />}
              {...settings}
            >
              {subcategories
                ?.filter((cat) => cat.category?._id === props.categoriesQuery)
                ?.map((catObj) => (
                  <div key={catObj._id} className={`cursor-pointer p-3 md:p-6`}>
                    <Link
                      className="block"
                      to={`/product?categories=${props.categoriesQuery}&subcategories=${catObj._id}`}
                    >
                      <img
                        src={catObj.image}
                        alt={catObj.name}
                        className="ease-in transition hover:scale-105"
                      />
                    </Link>
                    <h1 className="text-[14px] font-[600] text-black text-center uppercase p-3">
                      {catObj.name}
                    </h1>
                  </div>
                ))}
            </Slider>
          )}
        </div>
      ) : (
        <div className="bg-white p-5 w-full md:max-w-6xl mx-auto px-6 md:px-16">
          {loadingSubCat ? (
            <Loader />
          ) : errorSubCat ? (
            <Message type="error">{errorSubCat}</Message>
          ) : (
            <Slider
              prevArrow={<SamplePrevArrow />}
              nextArrow={<SampleNextArrow />}
              {...settings}
            >
              {categories?.map((catObj) => (
                <div key={catObj._id} className={`cursor-pointer p-3 md:p-6`}>
                  <Link
                    className="block"
                    to={`/product?categories=${catObj._id}&collections=${props.collectionsQuery}&isLatestArrival=${props.isLatestArrivalQuery}&gender=${props.genderQuery}`}
                  >
                    <img
                      src={catObj.image}
                      alt={catObj.name}
                      className="ease-in transition hover:scale-105"
                    />
                  </Link>
                  <h1 className="text-[14px] font-[600] text-black text-center uppercase p-3">
                    {catObj.name}
                  </h1>
                </div>
              ))}
            </Slider>
          )}
        </div>
      )}
      {/* <div className="bg-white p-5 max-w-6xl mx-auto px-16">
        {loadingSubCat ? (
          <Loader />
        ) : errorSubCat ? (
          <Message type="error">{errorSubCat}</Message>
        ) : (
          <Slider
            prevArrow={<SamplePrevArrow />}
            nextArrow={<SampleNextArrow />}
            {...settings}
          >
            {subcategories
              ?.filter((cat) => cat.category?._id === props.categoriesQuery)
              ?.map((catObj) => (
                <div key={catObj._id} className={`cursor-pointer p-3 md:p-6`}>
                  <Link
                    className="block"
                    to={`/product?categories=${props.categoriesQuery}&subcategories=${catObj._id}`}
                  >
                    <img
                      src={catObj.image}
                      alt={catObj.name}
                      className="ease-in transition hover:scale-105"
                    />
                  </Link>
                  <h1 className="text-[14px] font-[600] text-black text-center uppercase p-3">
                    {catObj.name}
                  </h1>
                </div>
              ))}
          </Slider>
        )}
      </div> */}
    </div>
  );
}
