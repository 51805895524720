import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  createCategory,
  updateCategory,
  getCategoryDetails,
} from "../actions/categoryActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  CATEGORY_CREATE_RESET,
  CATEGORY_UPDATE_RESET,
} from "../constants/categoryConstants";
import { serverIp } from "../config/conf";
import axios from "axios";

function CategoryForm() {
  const { id: categoryId } = useParams(); // Get category ID if editing
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [thumbImage, setThumbImage] = useState("");
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Access category creation and update states
  const categoryCreate = useSelector((state) => state.categoryCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = categoryCreate;

  const categoryUpdate = useSelector((state) => state.categoryUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = categoryUpdate;

  const categoryDetails = useSelector((state) => state.categoryDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    category,
  } = categoryDetails;

  // When editing, load the category details
  useEffect(() => {
    if (categoryId) {
      if (!category || category._id !== categoryId || successUpdate) {
        dispatch({ type: CATEGORY_UPDATE_RESET });
        dispatch(getCategoryDetails(categoryId));
      } else {
        setName(category.name);
        setDescription(category.description);
        setImage(category.image);
        setThumbImage(category.thumbImage);
      }
    }
    if (successCreate) {
      dispatch({ type: CATEGORY_CREATE_RESET });
      navigate("/dashboard/categories");
    } else if (successUpdate) {
      dispatch({ type: CATEGORY_UPDATE_RESET });
      navigate("/dashboard/categories");
    }
  }, [dispatch, categoryId, category, successCreate, successUpdate, navigate]);

  // Handle form submission for both create and update
  const submitHandler = (e) => {
    e.preventDefault();

    const categoryData = {
      name,
      description,
      image,
      thumbImage,
    };

    if (categoryId) {
      dispatch(updateCategory({ _id: categoryId, ...categoryData })); // Update category
    } else {
      dispatch(createCategory(categoryData)); // Create category
    }
  };

  const uploadImageHandler = async (e, setImageCallback) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${serverIp}/api/uploads/single`,
        formData,
        config
      );

      // Use the passed callback to set the image URL
      setImageCallback(data);

      setUploading(false);
    } catch (err) {
      console.error(err);
      setUploading(false);
    }
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-2xl font-semibold mb-4">
        {categoryId ? "Edit Category" : "Add New Category"}
      </h2>

      {/* Show loader or error messages */}
      {(loadingCreate || loadingUpdate || loadingDetails) && <Loader />}
      {(errorCreate || errorUpdate || errorDetails) && (
        <Message type="error">
          {errorCreate || errorUpdate || errorDetails}
        </Message>
      )}
      {successUpdate && (
        <Message type="success">Category Updated Successfully!</Message>
      )}
      {successCreate && (
        <Message type="success">Category Created Successfully!</Message>
      )}

      <form onSubmit={submitHandler}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700">
            Category Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="description" className="block text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            rows="4"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="image" className="block text-gray-700">
            Image URL
          </label>
          <input
            type="file"
            id="image"
            onChange={(e) => uploadImageHandler(e, setImage)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="thumbImage" className="block text-gray-700">
            Thumbnail Image URL
          </label>
          <input
            type="file"
            id="thumbImage"
            onChange={(e) => uploadImageHandler(e, setThumbImage)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700 transition"
        >
          {categoryId ? "Update Category" : "Add Category"}
        </button>
      </form>
    </div>
  );
}

export default CategoryForm;
