import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  getUserDetails,
  updateUserProfile,
} from "../actions/userActions";
import {
  createAddress,
  getUserAddresses,
  removeAddress as removeAddressAction,
  updateAddress,
} from "../actions/addressActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useNavigate } from "react-router-dom";
import {
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_RESET,
} from "../constants/userConstants";
import { Dialog, Transition } from "@headlessui/react";
import { scrollToTop } from "../components/ScrollToTop";
import { RiDeleteBin5Line } from "react-icons/ri";
import Sidebar from "../components/Sidebar";
import "./sidenav.css";
import { MdOutlineEdit } from "react-icons/md";
import { FormLabel, Switch } from "@chakra-ui/react";
import AddressForm from "../components/AddressForm";
import {
  ADDRESS_CREATE_RESET,
  ADDRESS_DELETE_RESET,
  ADDRESS_UPDATE_RESET,
} from "../constants/addressConstants";

export default function Addresses() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [addresses, setAddresses] = useState([
    {
      name: "",
      number: "",
      label: "",
      address: "",
      town: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      isDefault: false,
      landmark: "",
      area: "",
    },
  ]);

  const [selectedLabel, setSelectedLabel] = useState("");
  const [message, setMessage] = useState("");

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  // const { success } = userUpdateProfile;

  // const userDelete = useSelector((state) => state.userDelete);
  // const { success: successDelete } = userDelete;

  const addressList = useSelector((state) => state.addressList);
  const {
    addresses: userAddresses,
    loading: loadingAddressList,
    error: errorAddressList,
  } = addressList;

  const addressCreate = useSelector((state) => state.addressCreate);
  const { success: successCreate } = addressCreate;

  const addressDelete = useSelector((state) => state.addressDelete);
  const { success: successDelete } = addressDelete;

  const addressUpdate = useSelector((state) => state.addressUpdate);
  const { success: successUpdate } = addressUpdate;

  useEffect(() => {
    scrollToTop();
    if (!userInfo) {
      navigate("/signup");
    } else {
      dispatch(getUserAddresses());
      dispatch({ type: ADDRESS_CREATE_RESET });
      dispatch({ type: ADDRESS_UPDATE_RESET });
      dispatch({ type: ADDRESS_DELETE_RESET });
    }
  }, [
    userInfo,
    navigate,
    user,
    dispatch,
    successDelete,
    successCreate,
    successUpdate,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();

    // dispatch(createAddress(newAddress));
  };

  const removeAddress = (uid) => {
    // const updatedAddresses = addresses.filter((add) => add.uid !== uid);
    // setAddresses(updatedAddresses);
    // dispatch(
    //   updateUserProfile({
    //     id: user._id,
    //     name,
    //     email,
    //     password,
    //     addresses: updatedAddresses,
    //   })
    // );
    // dispatch({ type: USER_UPDATE_PROFILE_RESET });
    // dispatch({ type: USER_DETAILS_RESET });
    dispatch(removeAddressAction(uid));
  };

  const handleSwitchToggle = (id, updatedData) => {
    dispatch(updateAddress(id, updatedData));
  };

  const openAddressForm = (type, addressData) => {
    setSelectedAddress(addressData);
    setType(type);
    setIsOpen(true);
  };

  const closeAddressForm = () => {
    setSelectedAddress(null);
    setType(null);
    setIsOpen(false);
  };

  const saveAddress = (newAddressData) => {
    const updatedAddresses = !selectedAddress
      ? [...addresses, { ...newAddressData, uid: generateUniqueId() }]
      : addresses.map((address) =>
          address.uid === selectedAddress.uid
            ? { ...address, ...newAddressData }
            : address
        );

    if (type === "add") {
      dispatch(createAddress(newAddressData));
    } else {
      dispatch(updateAddress(selectedAddress._id, newAddressData));
    }

    setAddresses(updatedAddresses);

    // dispatch(
    //   updateUserProfile({
    //     id: user._id,
    //     name,
    //     email,
    //     password,
    //     addresses: updatedAddresses,
    //   })
    // );
    // dispatch({ type: USER_UPDATE_PROFILE_RESET });
    // dispatch({ type: USER_DETAILS_RESET });

    // Close the address form
    closeAddressForm();
  };

  const generateUniqueId = () => {
    return Math.floor(Date.now() * Math.random());
  };

  return (
    // <div className="">
    //   <Sidebar menu="Addresses" />
    //   <section id="content">
    //     <main className="">
    <>
      <AddressForm
        isOpen={isOpen}
        onClose={closeAddressForm}
        onSave={saveAddress}
        addressData={selectedAddress}
      />

      <div className="p-2 md:p-8 flex flex-col border-b border-gray-900/10 pb-8">
        <p className="text-2xl font-bold">Addresses</p>
        <p className="">Manage your saved addresses or add new ones.</p>
      </div>

      <div className="p-2 md:p-8 flex items-center gap-x-6 my-8">
        {/* Add New Address Button */}
        <button
          type="button"
          onClick={() => openAddressForm("add", null)}
          className="rounded-md bg-[#000080] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#000060] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#000080]"
        >
          Add Address
        </button>
      </div>

      <div className="p-2 md:p-8 border-2 border-top bg-white">
        {loadingAddressList ? (
          <Loader />
        ) : errorAddressList ? (
          <Message type="error">{errorAddressList}</Message>
        ) : (
          <form onSubmit={submitHandler}>
            <div className="space-y-4">
              <div className="">
                {/* Primary Address */}
                {userAddresses?.filter((add) => add.isDefault).length !== 0 && (
                  <div className="flex flex-col pb-8">
                    <h2 className="text-base mb-4 font-semibold leading-7 text-gray-900">
                      Primary Address
                    </h2>
                    {userAddresses
                      ?.filter((add) => add.isDefault)
                      .map((add) => (
                        <div className="flex w-full flex-col gap-4 bg-white border border-gray-200 p-4 rounded-lg shadow-sm">
                          <div className="flex flex-col md:flex-row justify-between md:items-center gap-4">
                            <div className="flex gap-3 items-center">
                              <h3 className="text-xl font-bold">{add.name}</h3>
                              <div className="px-2 rounded-md text-yellow-500 border border-yellow-500">
                                {add.label}
                              </div>
                            </div>
                            <div className="flex gap-2">
                              <button
                                type="button"
                                disabled={true}
                                onClick={() => removeAddress(add._id)}
                                className="rounded-md cursor-not-allowed bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
                              >
                                <RiDeleteBin5Line />
                              </button>
                              <button
                                type="button"
                                onClick={() => openAddressForm("edit", add)}
                                className="rounded-md bg-[#000080] px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-[#000060]"
                              >
                                <MdOutlineEdit />
                              </button>
                              <div className="flex items-center gap-2">
                                <label
                                  htmlFor="isDisabled"
                                  className="text-xs truncate ... md:text-base"
                                >
                                  Primary Address
                                </label>
                                <Switch
                                  colorScheme="green"
                                  id="isDisabled"
                                  onChange={() =>
                                    handleSwitchToggle(add._id, {
                                      ...add,
                                      isDefault: true,
                                    })
                                  }
                                  isDisabled={add.isDefault}
                                  isChecked={add.isDefault}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-2">
                            <span className="text-gray-400">Address:</span>
                            <p>{add.address}</p>
                          </div>
                          <div className="flex gap-2">
                            <span className="text-gray-400">Area:</span>
                            <p>{add.area}</p>
                          </div>
                          <div className="flex gap-2">
                            <span className="text-gray-400">Landmark:</span>
                            <p>{add.landmark}</p>
                          </div>
                          <div className="flex gap-2">
                            <span className="text-gray-400">Pin Code:</span>
                            <p>{add.postalCode}</p>
                          </div>
                          <div className="flex gap-2">
                            <span className="text-gray-400">City:</span>
                            <p>{add.city}</p>
                          </div>
                          <div className="flex gap-2">
                            <span className="text-gray-400">State:</span>
                            <p>{add.state}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                )}

                {/* Other Addresses */}
                {userAddresses?.filter((add) => !add.isDefault).length !==
                  0 && (
                  <div className="flex flex-col">
                    <h2 className="text-base mb-4 font-semibold leading-7 text-gray-900">
                      Other Addresses
                    </h2>
                    {userAddresses
                      ?.filter((add) => !add.isDefault)
                      .map((add) => (
                        <div className="flex w-full flex-col gap-4 bg-white border border-gray-200 p-4 rounded-lg shadow-sm">
                          <div className="flex flex-col md:flex-row justify-between md:items-center gap-4">
                            <div className="flex gap-3 items-center">
                              <h3 className="text-xl font-bold">{add.name}</h3>
                              <div className="px-2 rounded-md text-yellow-500 border border-yellow-500">
                                {add.label}
                              </div>
                            </div>
                            <div className="flex gap-2">
                              <button
                                type="button"
                                onClick={() => removeAddress(add._id)}
                                className="rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
                              >
                                <RiDeleteBin5Line />
                              </button>
                              <button
                                type="button"
                                onClick={() => openAddressForm("edit", add)}
                                className="rounded-md bg-[#000080] px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-[#000060]"
                              >
                                <MdOutlineEdit />
                              </button>
                              <div className="flex items-center gap-2">
                                <label
                                  htmlFor="isDisabled"
                                  className="text-xs truncate ... md:text-base"
                                >
                                  Primary Address
                                </label>
                                <Switch
                                  id="isDisabled"
                                  onChange={() =>
                                    handleSwitchToggle(add._id, {
                                      ...add,
                                      isDefault: true,
                                    })
                                  }
                                  isDisabled={add.isDefault}
                                  isChecked={add.isDefault}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-2">
                            <span className="text-gray-400">Address:</span>
                            <p>{add.address}</p>
                          </div>
                          <div className="flex gap-2">
                            <span className="text-gray-400">Area:</span>
                            <p>{add.area}</p>
                          </div>
                          <div className="flex gap-2">
                            <span className="text-gray-400">Landmark:</span>
                            <p>{add.landmark}</p>
                          </div>
                          <div className="flex gap-2">
                            <span className="text-gray-400">Pin Code:</span>
                            <p>{add.postalCode}</p>
                          </div>
                          <div className="flex gap-2">
                            <span className="text-gray-400">City:</span>
                            <p>{add.city}</p>
                          </div>
                          <div className="flex gap-2">
                            <span className="text-gray-400">State:</span>
                            <p>{add.state}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </form>
        )}
      </div>
    </>
    //     </main>
    //   </section>
    // </div>
  );
}
