import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  clearCart,
  removeFromCart,
  saveShippingAddress,
} from "../actions/cartActions";
import CheckoutSteps from "../components/CheckoutSteps";
import { getUserDetails, updateUserProfile } from "../actions/userActions";
import { scrollToTop } from "../components/ScrollToTop";
import { serverIp } from "../config/conf";
import Loader from "../components/Loader";
import useRazorpay from "react-razorpay";
import Message from "../components/Message";
import { checkCoupon } from "../actions/couponActions";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { createOrder } from "../actions/orderActions";
import {
  ORDER_CREATE_RESET,
  ORDER_DETAILS_RESET,
} from "../constants/orderConstants";
import { CART_CLEAR_ITEMS } from "../constants/cartConstants";
import AddressSelection from "../components/AddressSelection";
import { Box, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";

const ShippingScreen = () => {
  const [Razorpay] = useRazorpay();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const routePath = useLocation();
  const queryParams = new URLSearchParams(routePath.search);
  const orderStatus = queryParams.get("orders");
  const orderId = queryParams.get("moid");
  const transactionId = queryParams.get("tid");
  const failmessage = queryParams.get("failmessage");
  const statusmessage = queryParams.get("statusmessage");

  let [isOpen, setIsOpen] = useState(false);

  const [isAddressSelected, setIsAdressSelected] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [isPair, setIsPair] = useState(true);

  const cart = useSelector((state) => state.cart);
  const { shippingAddress, cartItems } = cart;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading: loadingUser, error: errorUSer, user } = userDetails;

  const couponList = useSelector((state) => state.couponList);
  const {
    loading: loadingCoupon,
    error: errorCouponLoad,
    coupons,
  } = couponList;

  const couponValidation = useSelector((state) => state.couponValidation);
  const {
    loading,
    error: errorCoupon,
    isValid,
    discount,
    type,
  } = couponValidation;

  const orderCreate = useSelector((state) => state.orderCreate);
  const { data: order, success, loading: loadingCreate } = orderCreate;

  cart.itemsPrice = cart.cartItems?.reduce(
    (acc, item) =>
      acc + (item.isPair ? item.price * 2 * item.qty : item.price * item.qty),
    0
  );
  cart.giftWrapTotalCost = cart.cartItems?.reduce(
    (acc, currVal) => acc + (currVal.giftWrap ? currVal.qty * 50 : 0),
    0
  );
  cart.shippingPrice = 0; //cart.itemsPrice < 1000 ? 1000 : 0;
  cart.taxPrice = 0; //(18 * cart.itemsPrice) / 100;
  // cart.discount =
  //   type !== null && type === "percentage"
  //     ? cart.itemsPrice * (discount / 100)
  //     : discount;
  cart.discount =
    type !== null && type === "percentage"
      ? cart.itemsPrice * (discount / 100)
      : type !== null && type === "fixed"
      ? discount
      : 0;
  cart.totalPrice =
    cartItems?.reduce(
      (acc, item) =>
        acc + (item.isPair ? item.price * 2 * item.qty : item.price * item.qty),
      0
    ) +
    (cartItems?.reduce(
      (acc, item) => acc + 10 * item?.engraveMen.length * item.qty,
      0
    ) +
      cartItems?.reduce(
        (acc, item) => acc + 10 * item?.engraveWomen.length * item.qty,
        0
      )) +
    cartItems?.reduce(
      (acc, item) => acc + 10 * item?.backEng.length * item.qty,
      0
    ) +
    cartItems
      ?.filter((item) => item.giftWrap)
      ?.reduce((acc, item) => acc + 50 * item.qty, 0) -
    cart.discount;

  const [code, setCode] = useState("");
  const [selectedOption, setSelectedOption] = useState("creditDebitCard");
  const [name, setName] = useState(shippingAddress.name || "");
  const [phone, setPhone] = useState(shippingAddress.phone || "");
  const [email, setEmail] = useState(shippingAddress.email || "");
  const [postalCode, setPostalCode] = useState(
    shippingAddress.postalCode || ""
  );
  const [address, setAddress] = useState(shippingAddress.address || "");
  const [town, setTown] = useState(shippingAddress.town || "");
  const [state, setState] = useState(shippingAddress.state || "");
  const [city, setCity] = useState(shippingAddress.city || "");
  const [country, setCountry] = useState(shippingAddress.country || "India");

  const [selectedAddress, setSelectedAddress] = useState(0); // Initialize with 'home'

  const [formHtml, setFormHtml] = useState("");

  const ccavpaymentcall = async (order) => {
    const formData = new URLSearchParams();
    formData.append("merchant_id", "2770933");
    formData.append("order_id", order.order_id);
    formData.append("currency", "INR");
    formData.append("amount", order.totalPrice);
    formData.append("redirect_url", `${serverIp}/ccavResponseHandler`);
    formData.append("cancel_url", `${serverIp}/ccavResponseHandler`);
    formData.append("language", "EN");
    formData.append("billing_name", order.shippingAddress?.name);
    formData.append("billing_address", order.shippingAddress?.address);
    formData.append("billing_city", order.shippingAddress?.city);
    formData.append("billing_state", order.shippingAddress?.state);
    formData.append("billing_zip", order.shippingAddress?.postalCode);
    formData.append("billing_country", order.shippingAddress?.country);
    formData.append("billing_tel", order.shippingAddress?.phone);
    formData.append("billing_email", order.shippingAddress?.email);
    formData.append("delivery_name", order.shippingAddress?.name);
    formData.append("delivery_address", order.shippingAddress?.address);
    formData.append("delivery_city", order.shippingAddress?.city);
    formData.append("delivery_state", order.shippingAddress?.state);
    formData.append("delivery_zip", order.shippingAddress?.postalCode);
    formData.append("delivery_country", order.shippingAddress?.country);
    formData.append("delivery_tel", order.shippingAddress?.phone);

    try {
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      const { data } = await axios.post(
        `${serverIp}/ccavRequestHandler`,
        formData,
        config
      );
      setFormHtml(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (success) {
      navigate(
        `/successPay?orders=Success&oid=${order.order_id}&moid=${order._id}&paymentmode=${order.paymentMethod}`
      );
      dispatch(clearCart());
      dispatch({ type: ORDER_CREATE_RESET });
      dispatch({ type: ORDER_DETAILS_RESET });
      dispatch(getUserDetails());
    }
  }, [success, navigate, order]);

  useEffect(() => {
    if (formHtml) {
      const formContainer = document.createElement("div");
      formContainer.innerHTML = formHtml;
      const form = formContainer.querySelector("form");
      document.body.appendChild(form);
      form.submit();
    }
  }, [formHtml]);

  useEffect(() => {
    if (
      orderStatus !== "" &&
      orderStatus !== null &&
      orderStatus !== undefined &&
      orderStatus !== "Success"
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [orderStatus]);

  const handleAddressChange = (addressIndex) => {
    setSelectedAddress(addressIndex);

    if (addressIndex !== null && user.addresses[addressIndex]) {
      const selectedAddress = user.addresses[addressIndex];
      setName(selectedAddress.name);
      setPhone(selectedAddress.number);
      setAddress(selectedAddress.address);
      setPostalCode(selectedAddress.postalCode);
      setTown(selectedAddress.town);
      setState(selectedAddress.state);
      setCity(selectedAddress.city);
      setCountry(selectedAddress.country);
    } else {
      setName("");
      setPhone("");
      setPostalCode("");
      setAddress("");
      setTown("");
      setState("");
      setCity("");
      setCountry("");
    }
  };

  useEffect(() => {
    // scrollToTop();
    if (!userInfo) {
      navigate("/signup");
    } else {
      if (!user?.firstName) {
        dispatch(getUserDetails());
      } else {
        setName(user.firstName);
        setEmail(user.email);
        setPhone(user.phone);
      }
    }
  }, [userInfo, user]);

  // const submitHandler = async () => {

  //   const selectedAddressData = {
  //     address,
  //     town,
  //     city,
  //     state,
  //     postalCode,
  //     country,
  //   };

  //   if (selectedAddress !== "new" && user.addresses[selectedAddress]) {
  //     dispatch(
  //       updateUserProfile({
  //         addresses: user.addresses.map((address, index) =>
  //           index === selectedAddress
  //             ? { ...selectedAddressData, name, number: phone, email }
  //             : { ...address, name, number: phone, email }
  //         ),
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       updateUserProfile({
  //         addresses: [
  //           ...(user.addresses || []),
  //           { ...selectedAddressData, name, number: phone, email },
  //         ],
  //       })
  //     );
  //   }

  //   const cc_order_id = Math.floor(Date.now() * Math.random());

  //   dispatch(
  //     createOrder({
  //       order_id: cc_order_id,
  //       orderItems: cart.cartItems,
  //       shippingAddress: { ...selectedAddressData, name, phone, email },
  //       paymentMethod: selectedOption,
  //       itemsPrice: cart.itemsPrice,
  //       shippingPrice: cart.shippingPrice,
  //       giftWrapTotalPrice: cart.giftWrapTotalCost,
  //       taxPrice: cart.taxPrice,
  //       totalPrice: cart.totalPrice,
  //       couponCode: cart.couponCode,
  //       discount: discount,
  //     })
  //   );
  // };

  const handlePayment = useCallback(
    async (param) => {
      // const order = await createOrder(params);

      const minimumAmount = 1.0;
      const validatedTotalPrice = Math.max(cart.totalPrice, minimumAmount);
      const options = {
        // key: "rzp_test_I4PMvx8lJvNsZf",
        // key: "rzp_test_Ain4xsWuc4rqYm",
        key: "rzp_live_34yru4KVh9F2KW",
        amount: validatedTotalPrice * 100,
        currency: "INR",
        name: "Sairah",
        description: "Transaction",
        image:
          "https://d8v879q3glvao.cloudfront.net/products/1730092773960.png",
        order_id: null,
        handler: (res) => {
          // console.log({
          //   order_id: res.razorpay_order_id,
          //   payment_id: res.razorpay_payment_id,
          //   update_time: Date.now(),
          //   email_address: userInfo.email,
          //   razorpay_signature: res.razorpay_signature,
          // });
          dispatch(
            createOrder({
              orderItems: param.orderItems,
              shippingAddress: param.shippingAddress,
              paymentMethod: param.paymentMethod,
              itemsPrice: param.itemsPrice,
              shippingPrice: param.shippingPrice,
              giftWrapTotalPrice: param.giftWrapTotalPrice,
              taxPrice: param.taxPrice,
              totalPrice: param.totalPrice,
              couponCode: param.couponCode,
              discount: cart.discount,
              isPaid: true,
              paidAt: Date.now(),
              paymentResult: { razorpay_payment_id: res.razorpay_payment_id },

              // orderItems
              // shippingAddress
              // paymentMethod
              // taxPrice
              // shippingPrice
              // totalPrice
              // couponCode
              // discount
              // giftWrapTotalPrice
              // paymentResult
              // isPaid
              // paidAt
            })
          );
        },
        // prefill: {
        //   name: userInfo.firstName,
        //   email: userInfo.email,
        //   contact: userInfo.phone,
        // },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#000080",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    },
    [Razorpay]
  );

  const handlePaymentCOD = (param) => {
    dispatch(
      createOrder({
        orderItems: param.orderItems,
        shippingAddress: param.shippingAddress,
        paymentMethod: param.paymentMethod,
        itemsPrice: param.itemsPrice,
        shippingPrice: param.shippingPrice,
        giftWrapTotalPrice: param.giftWrapTotalPrice,
        taxPrice: param.taxPrice,
        totalPrice: param.totalPrice,
        couponCode: param.couponCode,
        discount: discount,
        isPaid: true,
        paidAt: Date.now(),
        paymentResult: {},
      })
    );
  };

  const checkCouponHandler = (e) => {
    e.preventDefault();
    dispatch(checkCoupon(code));
    cart.couponCode = code;
  };

  const [selectedOffer, setSelectedOffer] = useState(null);

  const handleOfferSelection = (code) => {
    const isOfferValid = true;

    if (isOfferValid) {
      setSelectedOffer(code);
      setCode(code);
    }
  };

  return (
    <div className="flex flex-col p-4 md:p-6">
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Payment Failed
                  </Dialog.Title>
                  <div className="text-center">
                    <img
                      src="https://d8v879q3glvao.cloudfront.net/Static_images/credit-card+(1).gif"
                      alt="Failure"
                      className="w-1/2 mx-auto"
                    />
                  </div>

                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Failed Reason - {failmessage}
                    </p>
                    <p className="text-sm text-gray-500">
                      Cancel Reason - {statusmessage}
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Retry Payment
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <nav className="flex justify-center pb-4" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <a
              href="#"
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            >
              Cart
            </a>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <a
                href="#"
                className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
              >
                Information
              </a>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                Payment
              </span>
            </div>
          </li>
        </ol>
      </nav>

      <div className="flex flex-col md:flex-row gap-4 justify-between">
        <div className="flex h-full w-full md:w-1/2 flex-col shadow-md">
          <AddressSelection
            setSelectedAddressId={setSelectedAddressId}
            setIsAdressSelected={setIsAdressSelected}
          />
          {cart.shippingAddress.addressId === null && (
            <Message type="error">Please Select Shipping Address</Message>
          )}
        </div>

        <div className="flex h-full w-full md:w-1/2 flex-col shadow-md">
          <div className="border-t border-white px-4 py-6 md:px-6">
            {cartItems.length !== 0 ? (
              <div className="flex flex-col gap-6">
                <h2 className="font-bold">{`PRICE DETAILS (${
                  cartItems?.length + 1
                } ITEMS)`}</h2>
                <div className="flex justify-between text-sm text-gray-500">
                  <div>Total MRP</div>
                  <div>
                    ₹{" "}
                    {cartItems
                      ?.reduce(
                        (acc, item) =>
                          acc +
                          (item.isPair
                            ? item?.basePrice * 2 * item.qty
                            : item?.basePrice * item.qty),
                        0
                      )
                      ?.toLocaleString("en-IN")}
                  </div>
                </div>
                <div className="flex justify-between text-sm text-gray-500">
                  <div>Discount on MRP</div>
                  <div className="text-green-500">
                    -₹{" "}
                    {(
                      cartItems?.reduce(
                        (acc, item) =>
                          acc +
                          (item.isPair
                            ? item?.basePrice * 2 * item.qty
                            : item?.basePrice * item.qty),
                        0
                      ) -
                      cartItems?.reduce(
                        (acc, item) =>
                          acc +
                          (item.isPair
                            ? item?.price * 2 * item.qty
                            : item?.price * item.qty),
                        0
                      )
                    )?.toLocaleString("en-IN")}
                  </div>
                </div>
                {isValid && (
                  <div className="flex justify-between text-sm text-gray-500">
                    <p className="">
                      Coupon Discount({discount}
                      {type !== null && type === "percentage" ? " %" : " Off"})
                    </p>
                    <p className="text-green-500">
                      -₹ {cart.discount?.toFixed(0)}
                    </p>
                  </div>
                )}
                {isValid && (
                  <div className="flex justify-between text-sm text-gray-500">
                    <p className="">Coupon Code</p>
                    <p className="">{cart.couponCode}</p>
                  </div>
                )}
                {cartItems?.some(
                  (item) =>
                    item?.engraveMen !== "" ||
                    item.engraveWomen !== "" ||
                    item.backEng !== ""
                ) && (
                  <div className="flex justify-between text-sm text-gray-500">
                    <div>Engraving Charge (₹ 10 Per Character)</div>
                    <div>
                      {(
                        cartItems?.reduce(
                          (acc, item) =>
                            acc + 10 * item?.engraveMen.length * item.qty,
                          0
                        ) +
                        cartItems?.reduce(
                          (acc, item) =>
                            acc + 10 * item?.engraveWomen.length * item.qty,
                          0
                        ) +
                        cartItems?.reduce(
                          (acc, item) =>
                            acc + 10 * item?.backEng.length * item.qty,
                          0
                        )
                      )?.toLocaleString("en-IN")}
                    </div>
                  </div>
                )}
                {cartItems?.some((item) => item?.giftWrap) && (
                  <div className="flex justify-between text-sm text-gray-500">
                    <div>Gift Charge</div>
                    <div>
                      ₹{" "}
                      {cartItems
                        ?.filter((item) => item.giftWrap)
                        ?.reduce((acc, item) => acc + 50 * item.qty, 0)
                        ?.toLocaleString("en-IN")}
                    </div>
                  </div>
                )}

                <div className="flex justify-between text-sm text-gray-500">
                  <div>Delivery Charge</div>
                  <div className="text-green-500">Free</div>
                </div>

                <div className="flex justify-between border-t-2 border-gray-200 font-bold pt-4">
                  <div>Total Amount</div>
                  <div>
                    ₹{" "}
                    {(
                      cartItems?.reduce(
                        (acc, item) =>
                          acc +
                          (item.isPair
                            ? item.price * 2 * item.qty
                            : item.price * item.qty),
                        0
                      ) +
                      (cartItems?.reduce(
                        (acc, item) =>
                          acc + 10 * item?.engraveMen.length * item.qty,
                        0
                      ) +
                        cartItems?.reduce(
                          (acc, item) =>
                            acc + 10 * item?.engraveWomen.length * item.qty,
                          0
                        )) +
                      cartItems
                        ?.filter((item) => item.giftWrap)
                        ?.reduce((acc, item) => acc + 50 * item.qty, 0) -
                      cart.discount
                    )?.toLocaleString("en-IN")}
                  </div>
                </div>

                <Box bg="#fff" py={4} borderRadius="md" width="300px">
                  <Text fontSize="lg" fontWeight="bold" color="#000080" mb={2}>
                    Choose Payment Option
                  </Text>
                  <RadioGroup
                    onChange={(value) => setIsPair(value === "Razorpay")}
                    value={isPair ? "Razorpay" : "COD"}
                  >
                    <Stack direction="row" spacing={6}>
                      <Radio
                        value="Razorpay"
                        colorScheme="blue"
                        borderColor="#000080"
                        size="lg"
                        _checked={{
                          bg: "#000080",
                          color: "white",
                          borderColor: "#000080",
                        }}
                      >
                        <Text color="#000080" fontWeight="medium">
                          Pay Now
                        </Text>
                      </Radio>
                      <Radio
                        value="COD"
                        colorScheme="blue"
                        borderColor="#000080"
                        size="lg"
                        _checked={{
                          bg: "#000080",
                          color: "white",
                          borderColor: "#000080",
                        }}
                      >
                        <Text color="#000080" fontWeight="medium">
                          COD
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Box>
                <div className="flex h-full w-full flex-col items-center">
                  {isPair ? (
                    <button
                      type="button"
                      onClick={() =>
                        handlePayment({
                          orderItems: cart.cartItems,
                          shippingAddress: cart.shippingAddress.addressId,
                          paymentMethod: "Razorpay",
                          itemsPrice: cart.itemsPrice,
                          shippingPrice: cart.shippingPrice,
                          giftWrapTotalPrice: cart.giftWrapTotalCost,
                          taxPrice: cart.taxPrice,
                          totalPrice: cart.totalPrice,
                          couponCode: cart.couponCode,
                        })
                      }
                      disabled={isAddressSelected === false || loadingCreate}
                      className={`flex items-center gap-2 justify-center rounded-md w-1/2 md:w-1/3 bg-[#000080] px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                        isAddressSelected === false || loadingCreate
                          ? "bg-gray-500 cursor-not-allowed"
                          : "hover:bg-[#000080]"
                      }`}
                    >
                      <svg
                        className={`${
                          loadingCreate ? "block animate-spin" : "hidden"
                        } h-5 w-5 text-gray-500`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 4.411 2.837 8.166 6.787 9.576l1.213-2.285z"
                        ></path>
                      </svg>
                      Proceed to Pay
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() =>
                        handlePaymentCOD({
                          orderItems: cart.cartItems,
                          shippingAddress: cart.shippingAddress.addressId,
                          paymentMethod: "COD",
                          itemsPrice: cart.itemsPrice,
                          shippingPrice: cart.shippingPrice,
                          giftWrapTotalPrice: cart.giftWrapTotalCost,
                          taxPrice: cart.taxPrice,
                          totalPrice: cart.totalPrice,
                          couponCode: cart.couponCode,
                        })
                      }
                      disabled={isAddressSelected === false || loadingCreate}
                      className={`flex items-center gap-2 justify-center rounded-md w-1/2 md:w-1/3 bg-[#000080] px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                        isAddressSelected === false || loadingCreate
                          ? "bg-gray-500 cursor-not-allowed"
                          : "hover:bg-[#000080]"
                      }`}
                    >
                      <svg
                        className={`${
                          loadingCreate ? "block animate-spin" : "hidden"
                        } h-5 w-5 text-gray-500`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 4.411 2.837 8.166 6.787 9.576l1.213-2.285z"
                        ></path>
                      </svg>
                      Proceed to Pay
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingScreen;
