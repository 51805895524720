// Coupon action types
export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAILURE = 'CREATE_COUPON_FAILURE';
export const CREATE_COUPON_RESET = 'CREATE_COUPON_RESET';

export const COUPON_UPDATE_REQUEST = 'COUPON_UPDATE_REQUEST';
export const COUPON_UPDATE_SUCCESS = 'COUPON_UPDATE_SUCCESS';
export const COUPON_UPDATE_FAIL = 'COUPON_UPDATE_FAIL';
export const COUPON_UPDATE_RESET = 'COUPON_UPDATE_RESET';

export const GET_ALL_COUPONS_REQUEST = 'GET_ALL_COUPONS_REQUEST';
export const GET_ALL_COUPONS_SUCCESS = 'GET_ALL_COUPONS_SUCCESS';
export const GET_ALL_COUPONS_FAILURE = 'GET_ALL_COUPONS_FAILURE';

export const GET_COUPON_REQUEST = 'GET_COUPON_REQUEST';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_FAILURE = 'GET_COUPON_FAILURE';
export const GET_COUPON_RESET = 'GET_COUPON_RESET';

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAILURE = 'DELETE_COUPON_FAILURE';

// Coupon action types
export const CHECK_COUPON_REQUEST = 'CHECK_COUPON_REQUEST';
export const CHECK_COUPON_SUCCESS = 'CHECK_COUPON_SUCCESS';
export const CHECK_COUPON_FAILURE = 'CHECK_COUPON_FAILURE';
export const CHECK_COUPON_RESET = 'CHECK_COUPON_RESET   '
