import { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { listHero } from "../actions/heroActions";
import "./Slider.css";
import Message from "./Message";
import Loader from "./Loader";
import { useState } from "react";

const getConfigurableProps = () => ({
  showArrows: true,
  showStatus: false,
  showIndicators: true,
  infiniteLoop: true,
  showThumbs: false,
  useKeyboardArrows: true,
  autoPlay: true,
  stopOnHover: true,
  swipeable: true,
  dynamicHeight: true,
  emulateTouch: true,
  autoFocus: false,
  thumbWidth: 100,
  selectedItem: 0,
  interval: 2000,
  transitionTime: 800,
  swipeScrollTolerance: 5,
});

export default function Hero({ cat }) {
  const dispatch = useDispatch();

  const heroList = useSelector((state) => state.heroList);
  const { loading, heros, error } = heroList;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    dispatch(listHero("india", cat));
  }, [dispatch, cat]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {loading ? (
        <div className="w-full min-h-[20vh] md:min-h-[70vh] bg-white animate-pulse">
          <div className="flex items-center justify-center min-h-[20vh] md:min-h-[70vh] bg-slate-100 bg-no-repeat bg-animate animate-pulse">
            <Loader />
          </div>
        </div>
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <div className="w-full bg-white">
          <Carousel  {...getConfigurableProps()} >
            {heros.map((hero, index) => (
              <div key={index}>
                <RouterLink className="block h-full" to={`${hero.link}`}>
                  <img
                    src={windowWidth <= 768 ? hero.urlMobImg : hero.url}
                    alt="banner"
                    className="w-full h-full object-cover object-center"
                  />
                </RouterLink>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
}
