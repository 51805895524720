import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import React, { lazy, Suspense, useEffect, useState, Fragment } from "react";
import "./App.css";

import Home from "./screens/Home";
import ProductPage from "./screens/ProductPage";
import Wishlist from "./screens/Wishlist";
import Cart from "./screens/Cart";
import Signup from "./screens/Signup";
import Registration from "./screens/Registration";
import Rings from "./screens/Rings";
import ShippingScreen from "./screens/ShippingScreen";
import Header from "./components/Header";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import Profile from "./screens/Profile";
import Footer from "./components/Footer";
import Support from "./screens/SupportScreen";
import OrderList from "./screens/OrderListScreen";
import OtpScreen from "./screens/OtpScreen";
import ForgotPass from "./screens/ForgotPass";
import ResetPass from "./screens/ResetPass";
import Search from "./screens/Search";
import PaymentResponseHandler from "./screens/PaymentResponse";
import SuccessPaymentScreen from "./screens/SuccessPaymentScreen";
import { ToastContainer } from "react-toastify";
import Addresses from "./screens/Addresses";
import ClaimsList from "./screens/ClaimsListScreen";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { authToken, logout } from "./actions/userActions";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from "./components/Loader";
import Message from "./components/Message";
import { Dialog, Transition } from "@headlessui/react";
import Aos from "aos";
import RegistrationForm from "./screens/RegistartionNew";
import Categories from "./screens/Categories";
import CategoryForm from "./screens/CategoryForm";
import CollectionsForm from "./screens/CollectionsForm";
import Collections from "./screens/Collections";
import Styles from "./screens/Styles";
import StylesForm from "./screens/StylesForm";
import SubCategories from "./screens/SubCategories";
import SubCategoryForm from "./screens/SubCategoryForm";
import { Helmet } from "react-helmet";
import ProductSetList from "./screens/ProductSetList";
import ProductSetEdit from "./screens/ProductSetEdit";

const NotFound = lazy(() => import("./components/404"));
const Dashboard = lazy(() => import("./screens/Dashboard"));
const MyStore = lazy(() => import("./screens/MyStore"));
const ProductListScreen = lazy(() => import("./screens/ProductListScreen"));
const ProductForm = lazy(() => import("./screens/ProductForm"));
const OrderListAdmin = lazy(() => import("./screens/OrderListAdmin"));
const UserListScreen = lazy(() => import("./screens/UserListScreen"));
const SupportForm = lazy(() => import("./screens/SupportAdmin"));
const TeamScreen = lazy(() => import("./screens/TeamScreen"));
const BannerAdmin = lazy(() => import("./screens/BannerAdmin"));
const BannerForm = lazy(() => import("./screens/BannerForm"));
const TeamUserForm = lazy(() => import("./screens/TeamUserForm"));
const CouponListAdmin = lazy(() => import("./screens/CouponListAdmin"));
const CouponForm = lazy(() => import("./screens/CouponForm"));
const MenuLinksPage = lazy(() => import("./screens/MenuLinkPage"));
const EditMenuLinkPage = lazy(() => import("./screens/EditMenuLinkPage"));
const OrderScreenAdmin = lazy(() => import("./screens/OrderScreenAdmin"));
const FaqForm = lazy(() => import("./screens/FaqForm"));

function App() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const tokenAuth = useSelector((state) => state.tokenAuth);
  const { success, loading, error } = tokenAuth;

  useEffect(() => {
    if (userInfo && userInfo.token) {
      dispatch(authToken());
    }
  }, [userInfo]);

  let [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    dispatch(logout());
    notify("#", "Thank you for visiting Sairah", toast.success);
    setIsOpen(false);
  }

  const notify = (link, content, type) =>
    type(<Link to={link}>{content}</Link>);

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    Aos.init();
    // Aos.init({disable: 'mobile'});
  });

  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <title>Sairah Jewelry</title>
        <meta
          name="description"
          content="Exclusive collection of silver jewellery at jaw dropping prices."
        />
      </Helmet>
      <Header />
      <div id="recaptcha-container"></div>
      <div id="recaptcha-container2"></div>
      {error && (
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Please Re-login
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Dear Customer, your session has been expired.
                      </p>
                    </div>

                    <div className="mt-4">
                      <Link
                        to={"/signup"}
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Sign Up
                      </Link>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
      <Suspense
        fallback={
          <div className="flex justify-center items-center w-full h-[100vh]">
            <img
              width="150px"
              src="https://d8v879q3glvao.cloudfront.net/products/1730092605925.gif"
            />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/addresses" element={<Addresses />} />
          <Route path="/product" element={<Rings />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/shipping" element={<ShippingScreen />} />
          <Route
            path="/paymentResponseHandler"
            element={<PaymentResponseHandler />}
          />
          <Route path="/search" element={<Search />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotpass" element={<ForgotPass />} />
          <Route path="/resetPass" element={<ResetPass />} />
          <Route path="/otp" element={<OtpScreen />} />
          {/* <Route path="/registration" element={<Registration />} /> */}
          <Route path="/registration" element={<RegistrationForm />} />
          <Route path="/successPay" element={<SuccessPaymentScreen />} />
          <Route path="/placeorder" element={<PlaceOrderScreen />} />
          <Route path="/orders" element={<OrderList />} />
          <Route path="/claims" element={<ClaimsList />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/supportFAQ" element={<Support />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/mystore" element={<MyStore />} />
          <Route path="/dashboard/set" element={<ProductSetList />} />
          <Route path="/dashboard/collections" element={<Collections />} />
          <Route path="/dashboard/styles" element={<Styles />} />
          <Route path="/dashboard/categories" element={<Categories />} />
          <Route path="/dashboard/subcategories" element={<SubCategories />} />
          <Route path="/dashboard/menu" element={<MenuLinksPage />} />
          <Route path="/dashboard/order" element={<OrderListAdmin />} />
          <Route path="/dashboard/couponCode" element={<CouponListAdmin />} />
          <Route path="/dashboard/customers" element={<UserListScreen />} />
          <Route path="/dashboard/support" element={<SupportForm />} />
          <Route path="/dashboard/team" element={<TeamScreen />} />
          <Route path="/dashboard/banner" element={<BannerAdmin />} />
          <Route
            path="/dashboard/mystore/products"
            element={<ProductListScreen />}
          />
          <Route
            path="/dashboard/categories/create"
            element={<CategoryForm />}
          />
          <Route
            path="/dashboard/subcategories/create"
            element={<SubCategoryForm />}
          />
          <Route
            path="/dashboard/collections/create"
            element={<CollectionsForm />}
          />
          <Route path="/dashboard/styles/create" element={<StylesForm />} />
          <Route path="/dashboard/team/add" element={<TeamUserForm />} />
          <Route path="/dashboard/product/add" element={<ProductForm />} />
          <Route path="/dashboard/faq/add" element={<FaqForm />} />
          <Route path="/dashboard/coupons/add" element={<CouponForm />} />
          <Route path="/cart/:id" element={<Cart />} />
          <Route path="/wishlist/:id" element={<Wishlist />} />
          <Route path="/product/:name/:id" element={<ProductPage />} />
          <Route path="/order/:id" element={<OrderScreen />} />
          <Route path="/dashboard/order/:id" element={<OrderScreenAdmin />} />
          <Route path="/dashboard/menu/:id" element={<EditMenuLinkPage />} />
          <Route path="/dashboard/coupons/:id/edit" element={<CouponForm />} />
          <Route
            path="/dashboard/collections/:id/edit"
            element={<CollectionsForm />}
          />
          <Route path="/dashboard/styles/:id/edit" element={<StylesForm />} />
          <Route
            path="/dashboard/product-set/:id/edit"
            element={<ProductSetEdit />}
          />
          <Route
            path="/dashboard/categories/:id/edit"
            element={<CategoryForm />}
          />
          <Route
            path="/dashboard/subcategories/:id/edit"
            element={<SubCategoryForm />}
          />
          <Route path="/dashboard/product/:id/edit" element={<ProductForm />} />
          <Route path="/dashboard/faq/:id/edit" element={<FaqForm />} />
          <Route path="/dashboard/banner/:id/edit" element={<BannerForm />} />
        </Routes>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
