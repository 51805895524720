import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  getUserDetails,
  logout,
  updateUserProfile,
} from "../actions/userActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useNavigate } from "react-router-dom";
import {
  USER_DETAILS_RESET,
  USER_LIST_RESET,
  USER_LOGOUT,
  USER_REGISTER_RESET,
  USER_UPDATE_PROFILE_RESET,
} from "../constants/userConstants";
import { Dialog, Transition } from "@headlessui/react";
import { scrollToTop } from "../components/ScrollToTop";
import { RiDeleteBin5Line } from "react-icons/ri";
import Sidebar from "../components/Sidebar";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { WISHLIST_CLEAR_ITEMS } from "../constants/wishListConstants";
import { CART_CLEAR_ITEMS } from "../constants/cartConstants";
import {
  ORDER_DETAILS_RESET,
  ORDER_MY_LIST_RESET,
} from "../constants/orderConstants";

export default function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [isOpen, setIsOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState(null);
  const [doa, setDoa] = useState(null);
  const [gender, setGender] = useState("");
  const [mariedStatus, setMariedStatus] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [err, setErr] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [addresses, setAddresses] = useState([
    {
      address: "",
      town: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
  ]);
  const [message, setMessage] = useState("");

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  useEffect(() => {
    scrollToTop();
    if (!userInfo) {
      navigate("/signup");
    } else {
      if (!user?.firstName) {
        dispatch(getUserDetails());
      } else {
        setName(user.firstName);
        setEmail(user.email);
        setPhone(user.phone);
        setGender(user.gender);
        setMariedStatus(user.mariedStatus);
        setIsEmailVerified(user.isEmailVerified);
        setIsPhoneVerified(user.isPhoneVerified);
        setDob(
          user?.dob !== null && user?.dob !== "" && user?.dob !== undefined
            ? new Date(user?.dob)?.toISOString()?.substring(0, 10)
            : null
        );
        setDoa(
          user?.doa !== null && user?.doa !== "" && user?.doa !== undefined
            ? new Date(user?.doa)?.toISOString()?.substring(0, 10)
            : null
        );
      }
    }

    if (successDelete) {
      navigate("/signup");
    }

    if (success) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
      dispatch(getUserDetails());
      // setPassword("");
      // setConfirmPassword("");
    }
  }, [userInfo, navigate, user, dispatch, success, successDelete]);

  const submitHandler = (e) => {
    e.preventDefault();

    // if (password !== confirmPassword) {
    //   setMessage("Passwords do not match");
    // } else {
    dispatch(
      updateUserProfile({
        id: user._id,
        name,
        email,
        mariedStatus,
        dob,
        doa,
        gender,
      })
    );
    // }
  };

  // const validatePassword = (value) => {
  //   const minLength = 7;
  //   const hasNumber = /\d/.test(value);
  //   const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(value);

  //   if (value.length < minLength) {
  //     return "Password must be at least 7 characters long, must contain at least 1 number & 1 special character.";
  //   } else if (!hasNumber) {
  //     return "Password must be at least 7 characters long, must contain at least 1 number & 1 special character.";
  //   } else if (!hasSpecialChar) {
  //     return "Password must be at least 7 characters long, must contain at least 1 number & 1 special character.";
  //   } else {
  //     return "";
  //   }
  // };

  // const handlePasswordChange = (e) => {
  //   const newPassword = e.target.value;
  //   setPassword(newPassword);
  //   const error = validatePassword(newPassword);
  //   setPasswordError(error);
  // };

  const handleDelete = (id) => {
    setIsOpen(false);
    dispatch(deleteUser(id));
    dispatch(logout());
    localStorage.removeItem("userInfo");
    dispatch({ type: USER_LOGOUT });
    dispatch({ type: USER_REGISTER_RESET });
    dispatch({ type: USER_DETAILS_RESET });
    dispatch({ type: ORDER_MY_LIST_RESET });
    dispatch({ type: USER_UPDATE_PROFILE_RESET });
    dispatch({ type: ORDER_DETAILS_RESET });
    dispatch({ type: USER_LIST_RESET });
    dispatch({ type: CART_CLEAR_ITEMS });
    dispatch({ type: WISHLIST_CLEAR_ITEMS });
  };

  return (
    // <div>
    //   <Sidebar menu="Profile" />
    //   <section id="content">
    //     <main>
    <div className="p-2 md:p-4 border-2 border-top bg-white">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <form onSubmit={submitHandler}>
          <div className="space-y-4">
            <div className="border-b border-gray-900/10 pb-8">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Name
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        name="username"
                        id="username"
                        value={name}
                        contentEditable
                        disabled
                        autoComplete="username"
                        className="disabled:cursor-not-allowed block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="DeleteAcc"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Delete Account
                  </label>
                  <button
                    type="button"
                    className="rounded-md mt-2 bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setIsOpen(true)}
                  >
                    <RiDeleteBin5Line />
                  </button>
                </div>
              </div>
            </div>

            <div className="pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Personal Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Use a permanent address where you can receive mail.
              </p>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="relative mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      contentEditable
                      disabled
                      value={email}
                      autoComplete="email"
                      className="disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                      <button type="button" className="focus:outline-none">
                        {isEmailVerified ? (
                          <IoShieldCheckmarkSharp
                            className="h-4 w-4 text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <IoShieldCheckmarkSharp
                            className="h-4 w-4 text-red-500"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Phone
                  </label>
                  <div className="relative mt-2">
                    <input
                      id="phone"
                      name="phone"
                      type="text"
                      value={phone}
                      contentEditable
                      disabled
                      autoComplete="phone"
                      className="disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                      <button type="button" className="focus:outline-none">
                        {isPhoneVerified ? (
                          <IoShieldCheckmarkSharp
                            className="h-4 w-4 text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <IoShieldCheckmarkSharp
                            className="h-4 w-4 text-red-500"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="gender"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Gender
                  </label>

                  <div className="flex gap-4 items-center">
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="gender"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={(e) => setGender(e.target.value)}
                        className="form-radio text-[#000080]"
                      />
                      Male
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="gender"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={(e) => setGender(e.target.value)}
                        className="form-radio text-[#000080]"
                      />
                      Female
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="gender"
                        value="Other"
                        checked={gender === "Other"}
                        onChange={(e) => setGender(e.target.value)}
                        className="form-radio text-[#000080]"
                      />
                      Other
                    </label>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="mariedStatus"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Status
                  </label>

                  <div className="flex gap-4 items-center">
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="mariedStatus"
                        value="Single"
                        checked={mariedStatus === "Single"}
                        onChange={(e) => setMariedStatus(e.target.value)}
                        className="form-radio text-[#000080]"
                      />
                      Single
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="mariedStatus"
                        value="Married"
                        checked={mariedStatus === "Married"}
                        onChange={(e) => setMariedStatus(e.target.value)}
                        className="form-radio text-[#000080]"
                      />
                      Married
                    </label>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="dob"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Date Of Birth
                  </label>
                  <div className="mt-2">
                    <input
                      id="dob"
                      name="dob"
                      type="date"
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                      autoComplete="dob"
                      className="disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  {mariedStatus === "Married" && (
                    <>
                      <label
                        htmlFor="doa"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Date Of Anniversary
                      </label>
                      <div className="mt-2">
                        <input
                          id="doa"
                          name="doa"
                          type="date"
                          value={doa}
                          onChange={(e) => setDoa(e.target.value)}
                          autoComplete="doa"
                          className="disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </>
                  )}
                </div>

                {/* <div className="sm:col-span-3">Change Password</div>

                      <div className="sm:col-span-3"></div> */}

                {/* <div className="sm:col-span-3">
                        <label htmlFor="password" className="flex">
                          <p>Password</p>
                          <p className="text-red-500">*</p>
                        </label>
                        <div className="relative rounded-md shadow-sm">
                          <input
                            id="password"
                            name="password"
                            type={showPass ? "text" : "password"}
                            autoComplete="current-password"
                            className=" block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Password"
                            value={password}
                            onChange={handlePasswordChange}
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                            <button
                              type="button"
                              onClick={() => setShowPass(!showPass)}
                              className="text-gray-500 focus:outline-none focus:text-gray-600"
                            >
                              {showPass ? (
                                <AiFillEyeInvisible
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                />
                              ) : (
                                <AiFillEye
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                />
                              )}
                            </button>
                          </div>
                        </div>
                        {passwordError && (
                          <p className="text-red-500">{passwordError}</p>
                        )}
                      </div> */}

                {/* <div className="sm:col-span-3">
                        <label htmlFor="confirmpassword" className="flex">
                          <p>Confirm Password</p>
                          <p className="text-red-500">*</p>
                        </label>
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          autoComplete="current-password"
                          className="relative block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder=" Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div> */}
              </div>

              {message && (
                <div>
                  <Message type="error">{message}</Message>
                </div>
              )}
              <div className="flex items-center gap-x-6 mt-8">
                <button
                  type="submit"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Save
                </button>
              </div>
              <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10"
                  onClose={() => setIsOpen(false)}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title className="pb-2 text-lg font-bold">
                            Deactivate account
                          </Dialog.Title>
                          <Dialog.Description className="pb-2">
                            This will permanently deactivate your account
                          </Dialog.Description>

                          <p className="pb-2">
                            Are you sure you want to deactivate your account?
                            All of your data will be permanently removed. This
                            action cannot be undone.
                          </p>

                          <button
                            className="mx-1 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={(e) => handleDelete(user._id)}
                          >
                            Deactivate
                          </button>
                          <button
                            className="mx-1 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={() => setIsOpen(false)}
                          >
                            Cancel
                          </button>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </div>
          </div>
        </form>
      )}
    </div>
    //     </main>
    //   </section>
    // </div>
  );
}
