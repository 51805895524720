import axios from 'axios';

import {
    FAQ_CREATE_FAIL,
    FAQ_CREATE_REQUEST,
    FAQ_CREATE_SUCCESS,
    FAQ_DELETE_FAIL,
    FAQ_DELETE_REQUEST,
    FAQ_DELETE_SUCCESS,
    FAQ_DETAILS_FAIL,
    FAQ_DETAILS_REQUEST,
    FAQ_DETAILS_SUCCESS,
    FAQ_LIST_FAIL,
    FAQ_LIST_REQUEST,
    FAQ_LIST_SUCCESS,
    FAQ_UPDATE_FAIL,
    FAQ_UPDATE_REQUEST,
    FAQ_UPDATE_SUCCESS,
    SUPPORT_DETAILS_FAIL,
    SUPPORT_DETAILS_REQUEST,
    SUPPORT_DETAILS_SUCCESS,
    SUPPORT_UPDATE_FAIL,
    SUPPORT_UPDATE_REQUEST,
    SUPPORT_UPDATE_SUCCESS
} from '../constants/supportFaqConstants';
import { serverIp } from '../config/conf';

export const listSupportDetails = () => async (dispatch) => {
    try {
        dispatch({ type: SUPPORT_DETAILS_REQUEST });

        const { data } = await axios.get(`${serverIp}/api/info/support`);

        dispatch({ type: SUPPORT_DETAILS_SUCCESS, payload: data });
    } catch (err) {
        dispatch({
            type: SUPPORT_DETAILS_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const updateSupport = (support) => async (dispatch, getState) => {
    try {
        dispatch({ type: SUPPORT_UPDATE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.put(
            `${serverIp}/api/info/support/edit`,
            support,
            config
        );

        dispatch({ type: SUPPORT_UPDATE_SUCCESS, payload: data });
    } catch (err) {
        dispatch({
            type: SUPPORT_UPDATE_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const listFaqs = () => async (dispatch) => {
    try {
        dispatch({ type: FAQ_LIST_REQUEST });

        const { data } = await axios.get(`${serverIp}/api/info/FAQ`);

        dispatch({ type: FAQ_LIST_SUCCESS, payload: data });

    } catch (err) {
        dispatch({
            type: FAQ_LIST_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const listFaqDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: FAQ_DETAILS_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`${serverIp}/api/info/FAQ/${id}`, config);

        dispatch({ type: FAQ_DETAILS_SUCCESS, payload: data });
    } catch (err) {
        dispatch({
            type: FAQ_DETAILS_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const deleteFaq = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: FAQ_DELETE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`${serverIp}/api/info/FAQ/${id}`, config);

        dispatch({ type: FAQ_DELETE_SUCCESS });
    } catch (err) {
        dispatch({
            type: FAQ_DELETE_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const createFaq = (formData) => async (dispatch, getState) => {
    try {
        dispatch({ type: FAQ_CREATE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.post(`${serverIp}/api/info/FAQ`, formData, config);

        dispatch({ type: FAQ_CREATE_SUCCESS, payload: data });
    } catch (err) {
        dispatch({
            type: FAQ_CREATE_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const updateFaq = (faq) => async (dispatch, getState) => {
    try {
        dispatch({ type: FAQ_UPDATE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.put(
            `${serverIp}/api/info/FAQ/${faq._id}`,
            faq,
            config
        );

        dispatch({ type: FAQ_UPDATE_SUCCESS, payload: data });
    } catch (err) {
        dispatch({
            type: FAQ_UPDATE_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};