import { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Select,
  Button,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import { listProducts } from "../actions/productActions";
import { listSizes } from "../actions/sizeActions";
import { useTranslation } from "react-i18next";
import { addItemToCart } from "../actions/cartActions";
import Loader from "./Loader";
import Message from "./Message";
import Pagination from "./Pagination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import {
  addItemToWishlist,
  getWishlistItems,
  removeItemFromWishlist,
} from "../actions/wishListActions";
import { IoChevronDownSharp } from "react-icons/io5";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { listStyles } from "../actions/stylesActions";
import axios from "axios";
import { serverIp } from "../config/conf";
import ProductSet from "./ProductSet";
import SizeChart from "./SizeChart";
import MeasuringGuide from "./MeasuringGuide";
import AddEngravingSection from "./AddEngravingSection";
import Quantity from "./Quantity";
import ImageEngravingSection from "./ImageEngravingSection";
import RingSize from "./RingSize";
import CartDrawer from "./CartDrawer";

const sortOptions = [
  {
    name: "Newest",
    sortBy: "latest",
    order: "desc",
    href: "#",
    current: false,
  },
  // {
  //   name: "Discount",
  //   sortBy: "discount",
  //   order: "desc",
  //   href: "#",
  //   current: false,
  // },
  {
    name: "Price: Low to High",
    sortBy: "price",
    order: "asc",
    href: "#",
    current: false,
  },
  {
    name: "Price: High to Low",
    sortBy: "price",
    order: "desc",
    href: "#",
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CategoryFilter(props) {
  const filters = [
    {
      id: "bestOfSapphire",
      name: "Only For You",
      options: [{ value: "true", label: "Only For You", checked: false }],
    },
    {
      id: "latestArrivals",
      name: "Latest Arrivals",
      options: [{ value: "true", label: "Latest Arrivals", checked: false }],
    },
    {
      id: "giftingspecial",
      name: "Gifting Special",
      options: [{ value: "true", label: "Gifting Special", checked: false }],
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routePath = useLocation();

  const [page, setPage] = useState(1);

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const queryParams = new URLSearchParams(routePath.search);
  const categoriesQuery = queryParams.get("categories") || null;
  const subCategoriesQuery = queryParams.get("subcategories") || null;
  const collectionsQuery = queryParams.get("collections") || null;
  const stylesQuery = queryParams.get("styles") || null;
  const isLatestArrivalQuery = queryParams.get("isLatestArrival") || null;
  const genderQuery = queryParams.get("gender") || null;

  useEffect(() => {
    onTop();
    setPage(1);
  }, [
    categoriesQuery,
    collectionsQuery,
    subCategoriesQuery,
    isLatestArrivalQuery,
    genderQuery,
    stylesQuery,
  ]);

  const [stockCount, setStockCount] = useState(0);
  const [qty, setQty] = useState(1);
  const [size, setSize] = useState("0");
  const [id, setId] = useState("");
  // const [sizes, setSizes] = useState([]);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [categories, setCategories] = useState([]); // Use an array for multi-select
  const [subcategories, setSubCategories] = useState([]); // Use an array for multi-select
  const [subCategory, setSubCategory] = useState([]);
  const [productFlags, setProductFlags] = useState([]);
  const [bestOfSapphire, setBestOfSapphire] = useState([]);
  const [latest, setLatest] = useState([]);
  const [gift, setGift] = useState([]);
  const [gender, setGender] = useState([]);
  const [color, setColor] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 15000]);
  const [sortBy, setSortBy] = useState("latest");
  const [order, setOrder] = useState("desc");
  const [isInitialSetupComplete, setIsInitialSetupComplete] = useState(false);
  const [sentToCart, setSentToCart] = useState(false);
  const [isCustomized, setIsCustomized] = useState(false);
  const [numberOfCustomFields, setNumberOfCustomFields] = useState(0);
  const [customQuestions, setCustomQuestions] = useState([]);
  const [userResponses, setUserResponses] = useState([]);
  const [selectedSizeMen, setSelectedSizeMen] = useState(0);
  const [selectedSizeWomen, setSelectedSizeWomen] = useState(0);
  const [engravingTextMen, setEngravingTextMen] = useState(""); // State for engraving text
  const [engravingTextWomen, setEngravingTextWomen] = useState(""); // State for engraving text
  const [backEng, setbackEng] = useState(""); // State for engraving text
  const [engImage, setengImage] = useState(""); // State for engraving text
  const [isAccordionOpen, setIsAccordionOpen] = useState(false); // State to track Accordion state
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productSet, setProductSet] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemBasePrice, setSelectedItemBasePrice] = useState([]);
  const [allowRingSize, setAllowRingSize] = useState(false);

  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(40);

  const [isOpen2, setIsOpen] = useState(false);

  const [selectedCollections, setSelectedCollections] = useState(
    collectionsQuery ? collectionsQuery.split(",") : []
  );

  const [selectedStyles, setSelectedStyles] = useState(
    stylesQuery ? stylesQuery.split(",") : []
  );
  const [selectedPlating, setSelectedPlating] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(null);
  const [selectedGender, setSelectedGender] = useState(
    genderQuery ? genderQuery.split(",") : []
  );

  // Handle changes for collections checkboxes
  const handleCollectionChange = (e) => {
    const value = e.target.value;
    setIsInitialSetupComplete(true);
    setSelectedCollections((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  // Handle changes for styles checkboxes
  const handleStylesChange = (e) => {
    const value = e.target.value;
    setIsInitialSetupComplete(true);
    setSelectedStyles((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  // Handle changes for plating checkboxes
  const handlePlatingChange = (e) => {
    const { checked, value } = e.target;

    // setSelectedPlating((prev) =>
    //   prev.includes(value)
    //     ? prev.filter((item) => item !== value)
    //     : [...prev, value]
    // );
    setIsInitialSetupComplete(true);
    if (checked) {
      setSelectedPlating([value]);
    } else {
      setSelectedPlating([]);
    }
  };

  // Handle changes for gender checkboxes
  const handleGenderChange = (e) => {
    const value = e.target.value;
    setIsInitialSetupComplete(true);
    setSelectedGender((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  // Handle changes for price checkboxes
  // const handlePriceChange = (e) => {
  //   const { checked, value } = e.target;

  //   // Find the selected price range based on the option value
  //   const selectedRange = [
  //     {
  //       option: "Upto 2,000",
  //       minPrice: 0,
  //       maxPrice: 2000,
  //     },
  //     {
  //       option: "2,000 - 3,000",
  //       minPrice: 2000,
  //       maxPrice: 3000,
  //     },
  //     {
  //       option: "3,000 - 5,000",
  //       minPrice: 3000,
  //       maxPrice: 5000,
  //     },
  //     {
  //       option: "5,000 - 8,000",
  //       minPrice: 5000,
  //       maxPrice: 8000,
  //     },
  //     {
  //       option: "8,000 and Above",
  //       minPrice: 8000,
  //       maxPrice: null,
  //     },
  //   ].find((range) => range.option === value);

  //   if (checked) {
  //     setSelectedPrices([value]);
  //     setMinPrice(selectedRange.minPrice);
  //     setMaxPrice(selectedRange.maxPrice);
  //   } else {
  //     setSelectedPrices([]);
  //     setMinPrice(null);
  //     setMaxPrice(null);
  //   }
  // };

  // Function to handle the slider change
  const handlePriceChange = (value) => {
    setPriceRange(value);

    // Set minPrice and maxPrice based on the selected range
    if (value[1] === 15000) {
      // If max range is selected, set maxPrice to null
      setMinPrice(value[0]);
      setMaxPrice(null);
    } else {
      setMinPrice(value[0]);
      setMaxPrice(value[1]);
    }
    setIsInitialSetupComplete(true);
    // Call your product filtering logic with the updated minPrice and maxPrice
    // Example: filterProductsByPrice(value[0], value[1] === 8000 ? null : value[1]);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const handlePage = (page) => {
    if (window.innerWidth > 400) {
      window.scrollTo({
        top: 325,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setPage(page);
  };

  const {
    loading: loadingFilter,
    error: errorFilter,
    menuLinks: menuFilter,
  } = useSelector((state) => state.menuLinkList);

  const {
    loading: loadingCollection,
    error: errorCollection,
    collections,
  } = useSelector((state) => state.collectionList);

  const {
    loading: loadingStyles,
    error: errorStyles,
    styles,
  } = useSelector((state) => state.styleList);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const sizeList = useSelector((state) => state.sizeList);
  const { loading: loadingSize, error: errorSize, sizes } = sizeList;

  const wishList = useSelector((state) => state.wishlist);
  const { wishlistItems } = wishList;

  const [scrolling, setScrolling] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { i18n } = useTranslation();

  let lang = i18n.language;

  const handleScroll = () => {
    if (window.innerWidth >= 1080 && window.scrollY > 300) {
      setScrolling(true);
    } else if (window.innerWidth <= 400 && window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (wishlistItems && wishlistItems?.length > 0) {
      if (wishlistItems?.some((item) => !item._id)) {
        dispatch(getWishlistItems());
      }
    }
  }, [wishlistItems]);

  useEffect(() => {
    clearFilter();

    if (props.categoriesQuery) {
      const parseCommaSeparatedArray = (param) =>
        param ? param.split(",") : [];
      const categoryArray = parseCommaSeparatedArray(props.categoriesQuery);
      if (categoryArray.length > 0) {
        setCategories(categoryArray);
      }
    }

    if (props.subCategoriesQuery) {
      const parseCommaSeparatedArray = (param) =>
        param ? param.split(",") : [];
      const subCategoryArray = parseCommaSeparatedArray(
        props.subCategoriesQuery
      );
      if (subCategoryArray.length > 0) {
        setSubCategories(subCategoryArray);
      }
    }

    if (props.collectionsQuery) {
      const parseCommaSeparatedArray = (param) =>
        param ? param.split(",") : [];
      const collectionArray = parseCommaSeparatedArray(props.collectionsQuery);
      if (collectionArray.length > 0) {
        setSelectedCollections(collectionArray);
      }
    }

    if (props.genderQuery) {
      const parseCommaSeparatedArray = (param) =>
        param ? param.split(",") : [];
      const genderArray = parseCommaSeparatedArray(props.genderQuery);
      if (genderArray.length > 0) {
        setSelectedGender(genderArray);
      }
    }

    if (props.stylesQuery) {
      const parseCommaSeparatedArray = (param) =>
        param ? param.split(",") : [];
      const stylesArray = parseCommaSeparatedArray(props.stylesQuery);
      if (stylesArray.length > 0) {
        setSelectedStyles(stylesArray);
      }
    }

    // if (props.productFlags) {
    //   const parseCommaSeparatedArray = (param) =>
    //     param ? param.split(",") : [];
    //   const collectionArray = parseCommaSeparatedArray(props.productFlags);
    //   setProductFlags(collectionArray);
    //   console.log([props.productFlags]);
    // }

    // if (props.purpose) {
    //   setSubCategory([props.purpose]);
    // }
    // setColor([props.plating]);
    // setPriceRange([props.price]);
    // setGender([props.gender]);
    // setSort([props.sort]);

    // if (props.bestOfSapphire) {
    //   setBestOfSapphire([...bestOfSapphire, props.bestOfSapphire]);
    // }

    // if (props.latestArrivals) {
    //   setLatest([...latest, props.latestArrivals]);
    // }

    // if (props.gift) {
    //   setGift([...gift, props.gift]);
    // }

    setIsInitialSetupComplete(true);
  }, [
    props.categoriesQuery,
    props.subCategoriesQuery,
    props.collectionsQuery,
    props.genderQuery,
    props.stylesQuery,
  ]);

  useEffect(() => {
    // onTop();
    dispatch(listSizes());
    dispatch(listStyles());
    if (isInitialSetupComplete) {
      dispatch(
        listProducts(
          categoriesQuery,
          subCategoriesQuery,
          selectedCollections,
          isLatestArrivalQuery,
          selectedGender,
          selectedPlating,
          minPrice,
          maxPrice,
          sortBy,
          order,
          selectedStyles
        )
      );
      setIsInitialSetupComplete(false);
    }
  }, [
    categoriesQuery,
    subCategoriesQuery,
    selectedCollections,
    isLatestArrivalQuery,
    selectedGender,
    selectedPlating,
    minPrice,
    maxPrice,
    sortBy,
    order,
    selectedStyles,
  ]);

  const handleQuantityChange = (value) => {
    if (value >= 1 && value <= 5) {
      setQty(value);
    }
  };

  const handleEngravingInput = (gender, value) => {
    const lettersAndSpaces = /^[A-Za-z ]*$/; // Regex to allow only letters and spaces
    if (lettersAndSpaces.test(value)) {
      if (gender === "female") {
        setEngravingTextWomen(value); // Update engraving text if input is valid
      } else {
        setEngravingTextMen(value); // Update engraving text if input is valid
      }
    }
  };

  const handleAccordionToggle = (isOpen) => {
    setIsAccordionOpen(isOpen); // Toggle Accordion state
    if (!isOpen) {
      setEngravingTextMen(""); // Clear engraving text when Accordion is closed
      setEngravingTextWomen(""); // Clear engraving text when Accordion is closed
    }
  };

  const clearFilter = () => {
    setCategories([]);
    setSubCategories([]);
    setSelectedCollections([]);
    setSelectedStyles([]);
    setSelectedPlating([]);
    setSelectedPrices([]);
    setSelectedGender([]);
  };

  const handleAddToCart = (e, obj) => {
    e.preventDefault();
    if (productSet.length > 0 && productSet.length < 2) {
      notify("#", "Minimum 2 products required ", toast.error);
    } else if (qty !== 0) {
      dispatch(addItemToCart(obj));
      onClose();
      if (sentToCart) {
        navigate("/cart");
      } else {
        notify("/cart", "Item Added To Cart", toast.success);
      }
    } else {
      notify("#", "Please select size & quantity", toast.error);
    }
  };

  const uploadImageHandler = async (e, setImageCallback) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);
    // setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${serverIp}/api/uploads/single`,
        formData,
        config
      );

      // Use the passed callback to set the image URL
      setImageCallback(data);

      // setUploading(false);
    } catch (err) {
      console.error(err);
      // setUploading(false);
    }
  };

  const handleAddToCartCustom = (e) => {
    e.preventDefault();
    dispatch(
      addItemToCart(
        1,
        "1",
        id,
        "en",
        isCustomized,
        numberOfCustomFields,
        userResponses
      )
    );
    setIsOpen(false);
    if (sentToCart) {
      navigate("/cart");
    } else {
      notify("/cart", "Item Added To Cart", toast.success);
    }
  };

  const handleAddToCart2 = (pId) => {
    dispatch(addItemToCart(1, "1", pId, "en"));
    onClose();
    notify("/cart", "Item Added To Cart", toast.success);
  };

  const addItemToWishlistHandler = (productId) => {
    dispatch(addItemToWishlist(productId));
    notify("/wishlist", "Item Added To Wishlist", toast.success);
  };

  const removeItemFromWishlistHandler = (id) => {
    dispatch(removeItemFromWishlist(id));
    notify("/wishlist", "Item Removed from Wishlist", toast.error);
  };

  const handleSizeChange = (e, qty) => {
    const selectedSize = e.target.value;
    setSize(selectedSize);
    const selectedQuantity = qty > 0 ? 1 : 0;
    setQty(selectedQuantity);
    const selectedSizeObj = sizes.find(
      (sizeObj) =>
        (i18n.language === "de" ? sizeObj.germanSize : sizeObj.indianSize) ===
        selectedSize
    );
    setStockCount(selectedSizeObj ? selectedSizeObj.countInStock : 0);
  };

  // const handleQuantityChange = (e) => {
  //   const selectedQuantity = Number(e.target.value);
  //   if (selectedQuantity > 0) {
  //     setQty(selectedQuantity);
  //   }
  // };

  const handleModalOpen = (
    sizes,
    id,
    sent,
    customized,
    customFields,
    customQuestions
  ) => {
    onOpen();
    setSentToCart(sent);
    setIsCustomized(customized);
    setNumberOfCustomFields(customFields);
    setCustomQuestions(customQuestions);
    const initialResponses = customQuestions?.map((question) => ({
      question,
      answer: "",
    }));
    setUserResponses(initialResponses);
    // setSizes(sizes);
    setId(id);
  };

  const handleModalOpen2 = (id, customized, customFields, customQuestions) => {
    setIsOpen(true);
    setSentToCart(false);
    setIsCustomized(customized);
    setNumberOfCustomFields(customFields);
    setCustomQuestions(customQuestions);
    const initialResponses = customQuestions?.map((question) => ({
      question,
      answer: "",
    }));
    setUserResponses(initialResponses);
    setId(id);
  };

  const handleResponseChange = (index, event) => {
    const newResponses = [...userResponses];
    newResponses[index].answer = event.target.value;
    setUserResponses(newResponses);
  };

  const handleCheckboxChange = (event, setStateCallback) => {
    const { value, checked } = event.target;

    setPage(1);

    setStateCallback((prevValues) => {
      if (checked) {
        return [...prevValues, value];
      } else {
        return prevValues.filter((item) => item !== value);
      }
    });
  };

  const notify = (link, content, type) =>
    type(<RouterLink to={link}>{content}</RouterLink>);

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = products?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPrice = selectedItems.reduce((sum, price) => sum + price, 0);

  const totalBasePrice = selectedItemBasePrice.reduce(
    (sum, price) => sum + price,
    0
  );



useEffect(()=>{

if(selectedProduct && selectedProduct?.products && productSet?.length === selectedProduct?.products.length){

setSelectedSizeWomen(0);
setSelectedSizeMen(0);

}

},[productSet,
  // selectedProduct
])



  const selectedProductDetails = {
    name: selectedProduct?.name,
    image:
      selectedProduct?.variants?.length > 0 &&
      selectedProduct?.variants?.filter((variant) =>
        selectedPlating.length > 0
          ? selectedPlating.includes(variant.color)
          : variant.isPrimary
      )[0]?.images[0],
    qty,
    sizeMen: selectedSizeMen,
    sizeWomen: selectedSizeWomen,
    price: productSet.length > 0 ? totalPrice : selectedProduct?.price,
    basePrice:
      productSet.length > 0 ? totalBasePrice : selectedProduct?.basePrice,

    product: selectedProduct?._id,
    products: productSet,
    variant:
      selectedProduct?.variants?.length > 0 &&
      selectedProduct?.variants?.filter((variant) => variant?.isPrimary)[0]
        ?._id,
    sku: selectedProduct?.sku,
    giftWrap: false,
    message: "",
    engraveMen: engravingTextMen,
    engraveWomen: engravingTextWomen,
    isPair: false,
    backEng,
    engImage,
  };


  // console.log("selected product",selectedProduct);

  return (
    <div className="bg-white">
      <div>
        <Transition appear show={isOpen2} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Enter Custom Name
                    </Dialog.Title>
                    <form id="form1" onSubmit={handleAddToCartCustom}>
                      {isCustomized && (
                        <div className="flex gap-4 py-2">
                          {customQuestions.map((question, index) => (
                            <div key={index}>
                              <label className="text-base font-semibold">
                                {question}
                              </label>
                              <input
                                type="text"
                                value={userResponses[index].answer}
                                onChange={(event) =>
                                  handleResponseChange(index, event)
                                }
                                placeholder={`Enter ${question}`}
                                className="rounded-md w-full"
                                required
                              />
                            </div>
                          ))}
                        </div>
                      )}
                      <div className="mt-4">
                        <button
                          type="submit"
                          value="form1"
                          form="form1"
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>
            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <form className="mt-4 border-t border-gray-200">
                    <Disclosure
                      defaultOpen
                      as="div"
                      className="border-b border-gray-200"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                              <span className="text-md font-semibold text-gray-900">
                                Styles
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="py-4 px-2 bg-white">
                            <div className="space-y-4">
                              {styles.map((menu, idx) => (
                                <div className="flex items-center">
                                  <input
                                    id={`filter-${menu._id}-${idx}`}
                                    name={`${menu._id}[]`}
                                    type="checkbox"
                                    value={menu._id}
                                    checked={selectedStyles.includes(menu._id)}
                                    onChange={(e) => {
                                      handleStylesChange(e);
                                    }}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-${menu._id}-${idx}`}
                                    className="ml-3 text-sm text-gray-600"
                                  >
                                    {menu.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>

                    <Disclosure
                      defaultOpen
                      as="div"
                      className="border-b border-gray-200"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                              <span className="text-md font-semibold text-gray-900">
                                Plating
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="py-4 px-2 bg-white">
                            <div className="space-y-4">
                              {["silver", "gold", "rose gold"].map(
                                (plating, idx) => (
                                  <div className="flex items-center" key={idx}>
                                    <input
                                      id={`filter-${plating}`}
                                      name={plating}
                                      type="checkbox"
                                      value={plating}
                                      checked={selectedPlating.includes(
                                        plating
                                      )}
                                      onChange={handlePlatingChange}
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={`filter-${plating}`}
                                      className="ml-3 text-sm text-gray-600 capitalize"
                                    >
                                      {plating}
                                    </label>
                                  </div>
                                )
                              )}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>

                    <Disclosure
                      defaultOpen
                      as="div"
                      className="border-b border-gray-200"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                              <span className="text-md font-semibold text-gray-900">
                                Price
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="py-4 px-2 bg-white">
                            <div className="space-y-4 px-4">
                              <RangeSlider
                                width="200px"
                                aria-label={["min", "max"]}
                                defaultValue={priceRange}
                                min={0}
                                max={15000}
                                step={500} // Adjust step size as needed
                                onChange={handlePriceChange}
                              >
                                <RangeSliderTrack>
                                  <RangeSliderFilledTrack />
                                </RangeSliderTrack>
                                <RangeSliderThumb index={0}>
                                  <Box color="black" fontSize="sm" mt="-8">
                                    ₹{priceRange[0]}
                                  </Box>
                                </RangeSliderThumb>
                                <RangeSliderThumb index={1}>
                                  <Box color="black" fontSize="sm" mt="-8">
                                    {priceRange[1] === 15000
                                      ? "₹15000+"
                                      : `₹${priceRange[1]}`}
                                  </Box>
                                </RangeSliderThumb>
                              </RangeSlider>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>

                    <Disclosure
                      defaultOpen
                      as="div"
                      className="border-b border-gray-200"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                              <span className="text-md font-semibold text-gray-900">
                                Gender
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="py-4 px-2 bg-white">
                            <div className="space-y-4">
                              {["male", "female", "unisex"].map(
                                (gender, idx) => (
                                  <div className="flex items-center" key={idx}>
                                    <input
                                      id={`filter-${gender}`}
                                      name={gender}
                                      type="checkbox"
                                      value={gender}
                                      checked={selectedGender.includes(gender)}
                                      onChange={handleGenderChange}
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={`filter-${gender}`}
                                      className="ml-3 text-sm text-gray-600 capitalize"
                                    >
                                      {gender}
                                    </label>
                                  </div>
                                )
                              )}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                    <Disclosure
                      defaultOpen
                      as="div"
                      className="border-b border-gray-200"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                              <span className="text-md font-semibold text-gray-900">
                                Collections
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="py-4 px-2 bg-white">
                            <div className="space-y-4">
                              {collections.map((menu, idx) => (
                                <div className="flex items-center">
                                  <input
                                    id={`filter-${menu._id}-${idx}`}
                                    name={`${menu._id}[]`}
                                    type="checkbox"
                                    value={menu._id}
                                    checked={selectedCollections.includes(
                                      menu._id
                                    )}
                                    onChange={(e) => {
                                      handleCollectionChange(e);
                                    }}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-${menu._id}-${idx}`}
                                    className="ml-3 text-sm text-gray-600"
                                  >
                                    {menu.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div
          className={`flex w-full bg-white items-baseline justify-between border-b border-gray-200 px-2 md:px-14 py-4 ${
            scrolling ? "fixed z-10 top-[48px] md:top-[120px]" : ""
          }`}
        >
          <form className="hidden lg:flex w-full">
            <div className="flex items-center w-full gap-4">
              <h2 className="text-lg text-gray-400">FILTER BY :</h2>
              {/* Collection Filter */}
              <div className="flex gap-4">
                <Popover as="div">
                  {({ open }) => (
                    <>
                      <h3 className="flow-root">
                        <Popover.Button className="relative flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                          <span className="text-md font-semibold text-gray-900">
                            Collections
                          </span>
                          <span className="ml-2 mr-4 flex items-center">
                            {open ? (
                              <IoIosArrowUp
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <IoIosArrowDown
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Popover.Button>
                      </h3>
                      <Popover.Panel className="absolute min-w-32 z-20 py-4 px-2 bg-white border shadow-md">
                        {({ close }) => (
                          <div className="space-y-4">
                            {collections.map((menu, idx) => (
                              <div className="flex items-center" key={idx}>
                                <input
                                  id={`filter-${menu._id}-${idx}`}
                                  name={`${menu._id}[]`}
                                  type="checkbox"
                                  value={menu._id}
                                  checked={selectedCollections.includes(
                                    menu._id
                                  )}
                                  onChange={(e) => {
                                    handleCollectionChange(e);
                                    close();
                                  }}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor={`filter-${menu._id}-${idx}`}
                                  className="ml-3 text-sm text-gray-600"
                                >
                                  {menu.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              </div>

              {/* Styles Filter */}
              <div className="flex gap-4">
                <Popover as="div">
                  {({ open }) => (
                    <>
                      <h3 className="flow-root">
                        <Popover.Button className="relative flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                          <span className="text-md font-semibold text-gray-900">
                            Styles
                          </span>
                          <span className="ml-2 mr-4 flex items-center">
                            {open ? (
                              <IoIosArrowUp
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <IoIosArrowDown
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Popover.Button>
                      </h3>
                      <Popover.Panel className="absolute min-w-32 z-20 py-4 px-2 bg-white border shadow-md">
                        <div className="space-y-4">
                          {styles.map((menu, idx) => (
                            <div className="flex items-center" key={idx}>
                              <input
                                id={`filter-${menu._id}-${idx}`}
                                name={`${menu._id}[]`}
                                type="checkbox"
                                value={menu._id}
                                checked={selectedStyles.includes(menu._id)}
                                onChange={handleStylesChange}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`filter-${menu._id}-${idx}`}
                                className="ml-3 text-sm text-gray-600"
                              >
                                {menu.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              </div>

              {/* Plating Filter */}
              <div className="flex gap-4">
                <Popover as="div">
                  {({ open }) => (
                    <>
                      <h3 className="flow-root">
                        <Popover.Button className="relative flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                          <span className="text-md font-semibold text-gray-900">
                            Plating
                          </span>
                          <span className="ml-2 mr-4 flex items-center">
                            {open ? (
                              <IoIosArrowUp
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <IoIosArrowDown
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Popover.Button>
                      </h3>
                      <Popover.Panel className="absolute w-32 z-20 py-4 px-2 bg-white border shadow-md">
                        <div className="space-y-4">
                          {["silver", "gold", "rose gold"].map(
                            (plating, idx) => (
                              <div className="flex items-center" key={idx}>
                                <input
                                  id={`filter-${plating}`}
                                  name={plating}
                                  type="checkbox"
                                  value={plating}
                                  checked={selectedPlating.includes(plating)}
                                  onChange={handlePlatingChange}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor={`filter-${plating}`}
                                  className="ml-3 text-sm text-gray-600 capitalize"
                                >
                                  {plating}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              </div>

              {/* Price Filter */}
              <div className="flex gap-4">
                <Popover as="div">
                  {({ open }) => (
                    <>
                      <h3 className="flow-root">
                        <Popover.Button className="relative flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                          <span className="text-md font-semibold text-gray-900">
                            Price
                          </span>
                          <span className="ml-2 mr-4 flex items-center">
                            {open ? (
                              <IoIosArrowUp
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <IoIosArrowDown
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Popover.Button>
                      </h3>
                      <Popover.Panel>
                        <div className="absolute z-20 pt-6 pb-2 pl-6 pr-10 bg-white border shadow-md rounded-md w-[240px]">
                          <RangeSlider
                            aria-label={["min", "max"]}
                            defaultValue={priceRange}
                            min={0}
                            max={15000}
                            step={500}
                            onChange={handlePriceChange}
                            className="mx-2" // Center slider within the panel
                          >
                            <RangeSliderTrack bg="gray.300" height="6px">
                              <RangeSliderFilledTrack bg="blue.500" />
                            </RangeSliderTrack>
                            <RangeSliderThumb index={0}>
                              <Box
                                color="black"
                                fontSize="sm"
                                mt="-8"
                                bg="white"
                                px="2"
                                py="1"
                                rounded="md"
                                shadow="md"
                              >
                                ₹{priceRange[0]}
                              </Box>
                            </RangeSliderThumb>
                            <RangeSliderThumb index={1}>
                              <Box
                                color="black"
                                fontSize="sm"
                                mt="-8"
                                bg="white"
                                px="2"
                                py="1"
                                rounded="md"
                                shadow="md"
                              >
                                {priceRange[1] === 15000
                                  ? "₹15000+"
                                  : `₹${priceRange[1]}`}
                              </Box>
                            </RangeSliderThumb>
                          </RangeSlider>
                        </div>
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              </div>

              {/* Gender Filter */}
              <div className="flex gap-4">
                <Popover as="div">
                  {({ open }) => (
                    <>
                      <h3 className="flow-root">
                        <Popover.Button className="relative flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                          <span className="text-md font-semibold text-gray-900">
                            Gender
                          </span>
                          <span className="ml-2 mr-4 flex items-center">
                            {open ? (
                              <IoIosArrowUp
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <IoIosArrowDown
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Popover.Button>
                      </h3>
                      <Popover.Panel className="absolute w-32 z-20 py-4 px-2 bg-white border shadow-md">
                        <div className="space-y-4">
                          {["male", "female", "unisex"].map((gender, idx) => (
                            <div className="flex items-center" key={idx}>
                              <input
                                id={`filter-${gender}`}
                                name={gender}
                                type="checkbox"
                                value={gender}
                                checked={selectedGender.includes(gender)}
                                onChange={handleGenderChange}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`filter-${gender}`}
                                className="ml-3 text-sm text-gray-600 capitalize"
                              >
                                {gender}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              </div>

              {/* Button to apply filters */}
              {/* <button
                type="button"
                onClick={applyFilters}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Apply Filters
              </button> */}
            </div>
          </form>
          <div className="flex w-full md:w-auto justify-end items-center">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <ChevronDownIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option, idx) => (
                      <Menu.Item key={idx}>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              setIsInitialSetupComplete(true);
                              setSortBy(option.sortBy);
                              setOrder(option.order);
                            }}
                            className={classNames(
                              option.current
                                ? "font-medium text-gray-900"
                                : "text-gray-500",
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            {option.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <button
              type="button"
              className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
              onClick={() => {
                setToggle(!toggle);
                setItemsPerPage(toggle ? 40 : 40);
              }}
            >
              <span className="sr-only">View grid</span>
              <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
              onClick={() => {
                setMobileFiltersOpen(true);
              }}
            >
              <span className="sr-only">Filters</span>
              <FunnelIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>

        <main id="prodPage" className="mx-auto max-w-7xl">
          <section aria-labelledby="products-heading" className="pb-12 pt-6">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>
            <div className="">
              <div className="">
                <div className="bg-white">
                  <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                    <h2 className="sr-only">Products</h2>
                    {loading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : error ? (
                      <div>
                        <Message type="error">{error}</Message>
                      </div>
                    ) : (
                      <div
                        className={`relative grid mt-4 gap-x-8 gap-y-10 xl:gap-y-10 xl:gap-x-8 ${
                          toggle
                            ? "grid-cols-1 md:grid-cols-4"
                            : "grid-cols-2 md:grid-cols-5"
                        }`}
                      >
                        {currentItems?.map((product, index) => (
                          <div
                            key={index}
                            className="flex flex-col justify-between relative p-2 group/prod"
                          >
                            <RouterLink
                              to={`/product/${product.name?.replaceAll(
                                " ",
                                "-"
                              )}/${product._id}?variant=${
                                product?.variants?.length > 0 &&
                                product?.variants?.filter((variant) =>
                                  selectedPlating.length > 0
                                    ? selectedPlating.includes(variant.color)
                                    : variant.isPrimary
                                )[0]?._id
                              }`}
                            >
                              <div className="aspect-h-1 aspect-w-1 overflow-hidden bg-white">
                                {product?.variants?.length > 0 ? (
                                  <img
                                    src={
                                      product?.variants?.length > 0 &&
                                      product?.variants?.filter((variant) =>
                                        selectedPlating.length > 0
                                          ? selectedPlating.includes(
                                              variant.color
                                            )
                                          : variant.isPrimary
                                      )[0]?.images[0]
                                    }
                                    alt={product?.name}
                                    loading="lazy"
                                    className={
                                      "self-center hover:scale-125 transition-transform ease-in object-cover object-center m-auto bg-[#EBEAE5] text-center"
                                    }
                                  />
                                ) : (
                                  <div className="flex h-full w-full bg-gray-100 items-center justify-center">
                                    <span>Image</span>
                                  </div>
                                )}
                              </div>
                              <h3 className="py-1 mt-3 text-center px-2 text-base font-semibold text-gray-800 h-6 overflow-clip line-clamp-2">
                                {product.name}
                              </h3>
                              {/* <h3 className="py-1 mt-3 text-center px-2 text-[14px] text-gray-500 h-6 overflow-clip truncate">
                                {product.metaDescription}
                              </h3> */}
                              <div className="flex flex-col mt-3 px-2 gap-2 md:gap-4 justify-between items-center">
                                <div className="flex w-full items-center justify-center gap-1 md:gap-3 ">
                                  <p className="text-[14px] text-center text-gray-400 line-through">
                                    ₹{" "}
                                    {product.basePrice?.toLocaleString("en-IN")}
                                  </p>
                                  <p className="text-[14px] text-center md:text-base font-semibold text-[#000080]">
                                    {(
                                      ((product.basePrice - product.price) /
                                        product.basePrice) *
                                      100
                                    ).toFixed(0)}
                                    %
                                  </p>
                                </div>
                                <div className="flex w-full items-center justify-center gap-1 md:gap-3">
                                  <p className="text-sm text-center md:text-base font-bold text-gray-900">
                                    ₹ {product.price?.toLocaleString("en-IN")}
                                  </p>
                                </div>
                              </div>
                            </RouterLink>
                            {/* <div className="w-full pt-2 bg-white hidden group-hover/prod:block"> */}
                            <div className="absolute left-0 -bottom-10 w-full flex items-center bg-white opacity-100 md:opacity-0 md:group-hover/prod:opacity-100 transition-opacity duration-300">
                              {/* //hidden group-hover/prod: */}
                              <div className="flex w-full justify-between items-center gap-1 h-[40px]">
                                {product?.categories?.some(
                                  (cat) => cat.name === "Rings"
                                ) || product?.canEngrave ? (
                                  <button
                                    className="w-3/4 h-full bg-[#000080] rounded text-center self-center text-white text-sm font-medium"
                                    onClick={() => {
                                      if (userInfo && userInfo.firstName) {
                                        onOpen();
                                        setSelectedProduct(product);
                                      } else {
                                        navigate(
                                          `/signup?redirect=/product/${product.name?.replaceAll(
                                            " ",
                                            "-"
                                          )}/${product._id}?variant=${
                                            product?.variants?.length > 0 &&
                                            product?.variants?.filter(
                                              (variant) =>
                                                selectedPlating.length > 0
                                                  ? selectedPlating.includes(
                                                      variant.color
                                                    )
                                                  : variant.isPrimary
                                            )[0]?._id
                                          }`
                                        );
                                      }
                                    }}
                                  >
                                    Add to Cart
                                  </button>
                                ) : (
                                  <button
                                    className="w-3/4 h-full bg-[#000080] rounded text-center self-center text-white text-sm font-medium"
                                    onClick={(e) => {
                                      if (userInfo && userInfo.firstName) {
                                        handleAddToCart(e, {
                                          name: product?.name,
                                          image:
                                            product?.variants?.length > 0 &&
                                            product?.variants?.filter(
                                              (variant) =>
                                                selectedPlating.length > 0
                                                  ? selectedPlating.includes(
                                                      variant.color
                                                    )
                                                  : variant.isPrimary
                                            )[0]?.images[0],
                                          qty,
                                          sizeMen: "1",
                                          sizeWomen: "1",
                                          price:
                                            productSet.length > 0
                                              ? totalPrice
                                              : product?.price,
                                          basePrice:
                                            productSet.length > 0
                                              ? totalBasePrice
                                              : product?.basePrice,
                                          product: product?._id,
                                          products: productSet,
                                          variant:
                                            product?.variants?.length > 0 &&
                                            product?.variants?.filter(
                                              (variant) => variant?.isPrimary
                                            )[0]?._id,
                                          sku: product?.sku,
                                          giftWrap: false,
                                          message: "",
                                          engraveMen: engravingTextMen,
                                          engraveWomen: engravingTextWomen,
                                          backEng,
                                          engImage,
                                          isPair: false,
                                        });
                                      } else {
                                        navigate(
                                          `/signup?redirect=/product/${product.name?.replaceAll(
                                            " ",
                                            "-"
                                          )}/${product._id}?variant=${
                                            product?.variants?.length > 0 &&
                                            product?.variants?.filter(
                                              (variant) =>
                                                selectedPlating.length > 0
                                                  ? selectedPlating.includes(
                                                      variant.color
                                                    )
                                                  : variant.isPrimary
                                            )[0]?._id
                                          }`
                                        );
                                      }
                                    }}
                                  >
                                    Add to Cart
                                  </button>
                                )}

                                <button
                                  className="flex items-center justify-center w-1/4 h-full border border-[#000080] rounded"
                                  onClick={() => {
                                    wishlistItems?.some(
                                      (item) => item._id === product._id
                                    )
                                      ? removeItemFromWishlistHandler(
                                          product._id
                                        )
                                      : addItemToWishlistHandler(product._id);
                                  }}
                                >
                                  <Icon
                                    as={
                                      wishlistItems?.some(
                                        (item) => item._id === product._id
                                      )
                                        ? AiFillHeart
                                        : AiOutlineHeart
                                    }
                                    w="6"
                                    h="6"
                                    textColor="#000080"
                                    cursor="pointer"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}

                        <CartDrawer
                          onClose={onClose}
                          isOpen={isOpen}
                          selectedProductDetails={selectedProductDetails}
                          handleAddToCart={handleAddToCart}
                          selectedPlating={selectedPlating}
                          productSet={productSet}
                          setProductSet={setProductSet}
                          selectedItems={selectedItems}
                          setSelectedItems={setSelectedItems}
                          selectedItemBasePrice={selectedItemBasePrice}
                          setSelectedItemBasePrice={setSelectedItemBasePrice}
                          totalPrice={totalPrice}
                          totalBasePrice={totalBasePrice}
                          allowRingSize={allowRingSize}
                          setAllowRingSize={setAllowRingSize}
                          selectedProduct={selectedProduct}
                          handleAccordionToggle={handleAccordionToggle}
                          isAccordionOpen={isAccordionOpen}
                          engravingTextMen={engravingTextMen}
                          engravingTextWomen={engravingTextWomen}
                          handleEngravingInput={handleEngravingInput}
                          backEng={backEng}
                          setbackEng={setbackEng}
                          qty={qty}
                          handleQuantityChange={handleQuantityChange}
                          uploadImageHandler={uploadImageHandler}
                          setengImage={setengImage}
                          selectedSizeMen={selectedSizeMen}
                          setSelectedSizeMen={setSelectedSizeMen}
                          selectedSizeWomen={selectedSizeWomen}
                          setSelectedSizeWomen={setSelectedSizeWomen}
                          loadingSize={loadingSize}
                          errorSize={errorSize}
                          sizes={sizes}
                          // loadingSize={loadingSize}
                          // errorSize={errorSize}
                          // sizes={sizes}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {loading ? (
            <></>
          ) : error ? (
            <></>
          ) : (
            <Pagination
              page={page}
              setPage={setPage}
              handlePage={handlePage}
              totalItems={products?.length}
              itemsPerPage={itemsPerPage}
            ></Pagination>
          )}
        </main>
      </div>
    </div>
  );
}

{
  /* <Drawer onClose={onClose} isOpen={isOpen} size="lg">
<DrawerOverlay />
<DrawerContent>
  <DrawerCloseButton />
  <DrawerHeader>{`Select size and quantity`}</DrawerHeader>
  <DrawerBody>
    <form
      onSubmit={(e) =>
        handleAddToCart(e, {
          name: selectedProduct?.name,
          image:
            selectedProduct?.variants?.length > 0 &&
            selectedProduct?.variants?.filter(
              (variant) =>
                selectedPlating.length > 0
                  ? selectedPlating.includes(
                      variant.color
                    )
                  : variant.isPrimary
            )[0]?.images[0],
          qty,
          sizeMen: selectedSizeMen,
          sizeWomen: selectedSizeWomen,
          price:
            productSet.length > 0
              ? totalPrice
              : selectedProduct?.price,
          basePrice:
            productSet.length > 0
              ? totalBasePrice
              : selectedProduct?.basePrice,

          product: selectedProduct?._id,
          products: productSet,
          variant:
            selectedProduct?.variants?.length > 0 &&
            selectedProduct?.variants?.filter(
              (variant) => variant?.isPrimary
            )[0]?._id,
          sku: selectedProduct?.sku,
          giftWrap: false,
          message: "",
          engraveMen: engravingTextMen,
          engraveWomen: engravingTextWomen,
          isPair: false,
          backEng,
          engImage,
        })
      }
    >
      <Tabs isManual variant="enclosed">
        <TabList>
          <Tab>Select Size/Add Engraving</Tab>
          <Tab>Size Chart(Indian Size)</Tab>
          <Tab>How to measure</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {selectedProduct?.categories.some(
              (cat) => cat.name == "JEWELLERY SETS"
            ) ? (
              <ProductSet
                product={selectedProduct}
                variantId={
                  selectedProduct?.variants?.length >
                    0 &&
                  selectedProduct?.variants?.filter(
                    (variant) =>
                      selectedPlating.length > 0
                        ? selectedPlating.includes(
                            variant.color
                          )
                        : variant.isPrimary
                  )[0]?._id
                }
                productSet={productSet}
                setProductSet={setProductSet}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                selectedItemBasePrice={
                  selectedItemBasePrice
                }
                setSelectedItemBasePrice={
                  setSelectedItemBasePrice
                }
                totalPrice={totalPrice}
                allowRingSize={allowRingSize}
                setAllowRingSize={setAllowRingSize}
              />
            ) : null}

            {allowRingSize ||
            productSet.length === 0 ? (
              <Accordion
                allowMultiple
                onChange={() =>
                  handleAccordionToggle(
                    !isAccordionOpen
                  )
                }
                my="6"
              >
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <FaRegEdit className="w-5 h-5" />
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                      >
                        Add Engraving
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  {selectedProduct?.categories?.some(
                    (cat) =>
                      cat.name == "Rings" ||
                      cat.name == "JEWELLERY SETS"
                  ) && (
                    <>
                      {selectedProduct?.subcategories?.some(
                        (subcat) =>
                          subcat.name ===
                          "Couple Rings"
                      ) ? (
                        <>
                          <AccordionPanel pb={4}>
                            <input
                              type="text"
                              maxLength="10" // Limits input to 10 characters
                              pattern="[A-Za-z]*" // Allows only letters and spaces
                              value={engravingTextMen} // Controlled input
                              className="border px-2 py-1 rounded"
                              placeholder="Engraving for men"
                              onChange={(e) =>
                                handleEngravingInput(
                                  "male",
                                  e.target.value
                                )
                              } // Handle engraving input
                            />
                            <p className="text-sm text-gray-500">
                              Max 10 characters,
                              letters only.
                            </p>
                          </AccordionPanel>
                          <AccordionPanel pb={4}>
                            <input
                              type="text"
                              maxLength="10" // Limits input to 10 characters
                              pattern="[A-Za-z]*" // Allows only letters and spaces
                              value={
                                engravingTextWomen
                              } // Controlled input
                              className="border px-2 py-1 rounded"
                              placeholder="Engraving for women"
                              onChange={(e) =>
                                handleEngravingInput(
                                  "female",
                                  e.target.value
                                )
                              } // Handle engraving input
                            />
                            <p className="text-sm text-gray-500">
                              Max 10 characters,
                              letters only.
                            </p>
                          </AccordionPanel>
                        </>
                      ) : (
                        <AccordionPanel pb={4}>
                          <input
                            type="text"
                            maxLength="10" // Limits input to 10 characters
                            pattern="[A-Za-z]*" // Allows only letters and spaces
                            value={
                              selectedProduct?.gender ===
                              "female"
                                ? engravingTextWomen
                                : engravingTextMen
                            } // Controlled input
                            className="border px-2 py-1 rounded"
                            placeholder={`Engraving Text`}
                            onChange={(e) => {
                              if (
                                selectedProduct?.gender ===
                                "female"
                              ) {
                                handleEngravingInput(
                                  "female",
                                  e.target.value
                                );
                              } else {
                                handleEngravingInput(
                                  "male",
                                  e.target.value
                                );
                              }
                            }} // Handle engraving input
                          />
                          <p className="text-sm text-gray-500">
                            Max 10 characters, letters
                            only.
                          </p>
                        </AccordionPanel>
                      )}
                    </>
                  )}
                  {selectedProduct?.productCollections?.some(
                    (cat) =>
                      cat.name == "Pet Collection"
                  ) &&
                    selectedProduct?.canEngrave && (
                      <>
                        <AccordionPanel pb={4}>
                          <div className="w-full md:w-[60%] relative">
                            <img
                              className="w-full aspect-[3/2] object-cover object-center"
                              src={
                                selectedProduct.frontPreviewImg
                              }
                            />
                            <div className="flex text-4xl font-ScriptMTBold absolute inset-0 items-center justify-center z-10 text-center">
                              {engravingTextWomen}
                            </div>
                          </div>
                          <input
                            type="text"
                            maxLength="10" // Limits input to 10 characters
                            pattern="[A-Za-z]*" // Allows only letters and spaces
                            value={engravingTextWomen} // Controlled input
                            className="border px-2 py-1 rounded"
                            placeholder="Front Engraving Text"
                            onChange={(e) =>
                              handleEngravingInput(
                                "female",
                                e.target.value
                              )
                            } // Handle engraving input
                          />
                          <p className="text-sm text-gray-500">
                            Max 10 characters, letters
                            only.
                          </p>
                        </AccordionPanel>
                        {selectedProduct?.canBackEng && (
                          <AccordionPanel pb={4}>
                            <div className="w-full md:w-[60%] relative">
                              <img
                                className="w-full aspect-[3/2] object-cover object-center"
                                src={
                                  selectedProduct.backPreviewImg
                                }
                              />
                              <div className="flex text-4xl font-MvBoli absolute inset-0 items-center justify-center z-10 text-center">
                                {backEng}
                              </div>
                            </div>
                            <input
                              type="text"
                              maxLength="10" // Limits input to 10 characters
                              pattern="[A-Za-z]*" // Allows only letters and spaces
                              value={backEng} // Controlled input
                              className="border px-2 py-1 rounded"
                              placeholder="Back Engraving Text"
                              onChange={(e) =>
                                setbackEng(
                                  e.target.value
                                )
                              } // Handle engraving input
                            />
                            <p className="text-sm text-gray-500">
                              Max 10 characters,
                              letters only.
                            </p>
                          </AccordionPanel>
                        )}
                      </>
                    )}
                </AccordionItem>
              </Accordion>
            ) : null}

            <div className="flex flex-col gap-6 my-6">
              <div>
                <label htmlFor="quantitySelect">
                  Quantity:
                </label>
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() =>
                      handleQuantityChange(
                        qty > 1 ? qty - 1 : 1
                      )
                    }
                    className="px-4 py-2 bg-[#F3F3F3] text-[#000080] font-bold text-xl focus:outline-none"
                  >
                    -
                  </button>
                  <input
                    type="text"
                    id="quantitySelect"
                    value={qty}
                    onChange={(e) =>
                      handleQuantityChange(
                        Number(e.target.value)
                      )
                    }
                    className="px-4 py-2 w-[45px] text-center text-xl bg-[#F3F3F3] focus:outline-none border-none"
                    readOnly
                  />
                  <button
                    type="button"
                    onClick={() =>
                      handleQuantityChange(
                        qty < 5 ? qty + 1 : 5
                      )
                    }
                    className="px-4 py-2 bg-[#F3F3F3] text-[#000080] font-bold text-xl focus:outline-none"
                  >
                    +
                  </button>
                </div>
              </div>

              {selectedProduct?.canImgEng && (
                <div className="mb-4">
                  <label
                    htmlFor="image"
                    className="block text-gray-700"
                  >
                    Upload Image For Engraving
                  </label>
                  <input
                    type="file"
                    id="image"
                    onChange={(e) =>
                      uploadImageHandler(
                        e,
                        setengImage
                      )
                    }
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
              )}

              {
                // selectedProduct?.categories?.find(
                //   (cat) =>
                //     cat.name === "Rings" ||
                //     cat.name === "JEWELLERY SETS"
                // ) &&

                allowRingSize ||
                productSet.length === 0 ? (
                  <div>
                    <label htmlFor="sizeSelect">
                      Size:
                    </label>
                    {selectedProduct?.subcategories?.some(
                      (subcat) =>
                        subcat.name === "Couple Rings"
                    ) ? (
                      <div className="flex gap-6">
                        <Select
                          id="sizeSelect"
                          border="1px solid"
                          _hover="none"
                          className="pt-0"
                          pt="0px"
                          borderColor="[#000080]"
                          value={selectedSizeMen}
                          onChange={(e) =>
                            setSelectedSizeMen(
                              e.target.value
                            )
                          }
                          required
                        >
                          <option value={0}>
                            Select Size Men
                          </option>
                          {loadingSize ? (
                            <Loader />
                          ) : errorSize ? (
                            <Message type="error">
                              {errorSize}
                            </Message>
                          ) : (
                            sizes.map((num) => (
                              <option
                                key={num?.size}
                                value={num?.size}
                              >
                                {num?.size}
                              </option>
                            ))
                          )}
                        </Select>
                        <Select
                          id="sizeSelect"
                          border="1px solid"
                          _hover="none"
                          className="pt-0"
                          pt="0px"
                          borderColor="[#000080]"
                          value={selectedSizeWomen}
                          onChange={(e) =>
                            setSelectedSizeWomen(
                              e.target.value
                            )
                          }
                          required
                        >
                          <option value={0}>
                            Select Size Women
                          </option>
                          {loadingSize ? (
                            <Loader />
                          ) : errorSize ? (
                            <Message type="error">
                              {errorSize}
                            </Message>
                          ) : (
                            sizes.map((num) => (
                              <option
                                key={num?.size}
                                value={num?.size}
                              >
                                {num?.size}
                              </option>
                            ))
                          )}
                        </Select>
                      </div>
                    ) : (
                      <Select
                        id="sizeSelect"
                        border="1px solid"
                        _hover="none"
                        className="pt-0"
                        pt="0px"
                        borderColor="[#000080]"
                        value={
                          selectedProduct?.gender ===
                          "female"
                            ? selectedSizeWomen
                            : selectedSizeMen
                        }
                        onChange={(e) => {
                          if (
                            selectedProduct?.gender ===
                            "female"
                          ) {
                            setSelectedSizeWomen(
                              e.target.value
                            );
                          } else {
                            setSelectedSizeMen(
                              e.target.value
                            );
                          }
                        }}
                        required
                      >
                        <option value={0}>
                          Select Size
                        </option>
                        {loadingSize ? (
                          <Loader />
                        ) : errorSize ? (
                          <Message type="error">
                            {errorSize}
                          </Message>
                        ) : (
                          sizes.map((num) => (
                            <option
                              key={num?.size}
                              value={num?.size}
                            >
                              {num?.size}
                            </option>
                          ))
                        )}
                      </Select>
                    )}
                  </div>
                ) : null
              }
            </div>
          </TabPanel>
          <TabPanel>
            <div className="mt-2 max-h-[60vh] overflow-y-auto">
              <SizeChart loadingSize={loadingSize} errorSize={errorSize} sizes={sizes}/>
            </div>
          </TabPanel>
          <TabPanel>
              <MeasuringGuide/>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Button
        type="submit"
        isDisabled={
          productSet.length <= 1
            ? selectedProduct?.categories.some(
                (prod) =>
                  prod.name === "Rings" ||
                  prod.name == "JEWELLERY SETS"
              ) &&
              selectedProduct?.gender === "female"
              ? selectedSizeWomen === 0
              : selectedProduct?.categories.some(
                  (prod) =>
                    prod.name === "Rings" ||
                    prod.name == "JEWELLERY SETS"
                ) &&
                selectedProduct?.gender === "male"
              ? selectedSizeMen === 0
              : selectedProduct?.productCollections.some(
                  (prod) =>
                    prod.name === "Pet Collection"
                ) && selectedProduct?.canImgEng
              ? engImage === ""
              : false
            : allowRingSize
            ? selectedSizeWomen === 0
            : false
        }
        bgColor="#000080"
        textColor="white"
        _hover={{ bgColor: "#000090" }}
      >
        Next
      </Button>
    </form>
  </DrawerBody>
</DrawerContent>
</Drawer>  */
}
