import { BsShieldCheck } from "react-icons/bs";
import { IoAirplaneOutline } from "react-icons/io5";
import { RiForward30Fill } from "react-icons/ri";

export default function Features() {
  return (
    <div className="bg-[#F5F5F5]">
      <h2 className="uppercase text-center text-xl font-semibold py-10">
        THE <span className="text-[#000080]">SAIRAH</span> EXPERIENCE
      </h2>
      <div className="grid gap-8 grid-cols-2 md:grid-cols-5 px-6 md:px-16 py-6 md:pb-10">
        <div className="flex flex-col gap-4 items-center w-[80%] mx-auto">
          <img
            src="https://d8v879q3glvao.cloudfront.net/products/1730092654701.png"
            alt="fet1"
            loading="lazy"
            className="w-12 h-12 object-cover object-center"
          />
          {/* <RiForward30Fill className="text-[#7b7b7b] text-[14px] w-12 h-12" /> */}
          <h3 className="text-center text-[12px] text-[#7b7b7b] md:text-[14px] font-semibold">
            Easy 90 Days Return & Exchange
          </h3>
        </div>
        <div className="flex flex-col gap-4 items-center w-[80%] mx-auto">
          {/* <img
            src="https://d8v879q3glvao.cloudfront.net/Static_images/fet2.png"
            alt="fet2"
            className="w-[30px] h-[30px] md:w-[60px] md:h-[60px]"
          /> */}
          <IoAirplaneOutline className="text-[#7b7b7b] text-[14px] w-12 h-12 -rotate-45" />
          <h3 className="text-center text-[12px] text-[#7b7b7b] md:text-[14px] font-semibold">
            Free Shipping on all Orders
          </h3>
        </div>
        <div className="flex flex-col gap-4 items-center w-[80%] mx-auto">
          <img
            src="https://d8v879q3glvao.cloudfront.net/products/1730092512579.png"
            alt="fet4"
            loading="lazy"
            className="w-16 h-12 md:w-16 md:h-12 object-cover object-center"
          />
          <h3 className="text-center text-[12px] text-[#7b7b7b] md:text-[14px] font-semibold">
            BIS Hallmarked Jewellery
          </h3>
        </div>
        <div className="flex flex-col gap-4 items-center w-[80%] mx-auto">
          {/* <img
            src="https://d8v879q3glvao.cloudfront.net/Static_images/fet3.png"
            alt="fet3"
            className="w-[30px] h-[30px] md:w-[60px] md:h-[60px]"
          /> */}
          <BsShieldCheck className="text-[#7b7b7b] text-[14px] w-12 h-12" />
          <h3 className="text-center text-[12px] text-[#7b7b7b] md:text-[14px] font-semibold">
            100% Safe Secure Checkout
          </h3>
        </div>
        <div className="flex flex-col gap-4 items-center w-[80%] mx-auto col-span-2 md:col-span-1">
          <img
            src="https://d8v879q3glvao.cloudfront.net/products/1730092565148.png"
            alt="fet3"
            className="w-12 h-12"
            loading="lazy"
          />
          {/* <BsShieldCheck className="text-[#7b7b7b] text-[14px] w-12 h-12" /> */}
          <h3 className="text-center text-[12px] text-[#7b7b7b] md:text-[14px] font-semibold">
            Customized Engraving
          </h3>
        </div>
      </div>
    </div>
  );
}
