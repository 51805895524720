import {
  STYLE_CREATE_REQUEST,
  STYLE_CREATE_SUCCESS,
  STYLE_CREATE_FAIL,
  STYLE_CREATE_RESET,
  STYLE_LIST_REQUEST,
  STYLE_LIST_SUCCESS,
  STYLE_LIST_FAIL,
  STYLE_DETAILS_REQUEST,
  STYLE_DETAILS_SUCCESS,
  STYLE_DETAILS_FAIL,
  STYLE_UPDATE_REQUEST,
  STYLE_UPDATE_SUCCESS,
  STYLE_UPDATE_FAIL,
  STYLE_UPDATE_RESET,
  STYLE_DELETE_REQUEST,
  STYLE_DELETE_SUCCESS,
  STYLE_DELETE_FAIL,
} from "../constants/stylesConstants";

const initialState = {
  styles: [],
};

export const styleCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case STYLE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case STYLE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        success: true,
      };
    case STYLE_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case STYLE_CREATE_RESET:
      return {
        ...state,
        styles: [],
        loading: false,
        error: null,
        success: false,
      };
    default:
      return state;
  }
};

export const styleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case STYLE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case STYLE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case STYLE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const styleDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STYLE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case STYLE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case STYLE_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const styleUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case STYLE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case STYLE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        success: true,
      };
    case STYLE_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case STYLE_UPDATE_RESET:
      return {
        ...state,
        styles: [],
        loading: false,
        error: null,
        success: false,
      };
    default:
      return state;
  }
};

export const styleDeleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case STYLE_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case STYLE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case STYLE_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
};
