// import React, { Fragment, useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { updateCartItem } from "../actions/cartActions";
// import { MdOutlineFileDownloadDone } from "react-icons/md";
// import { IoMdAdd } from "react-icons/io";
// import { Dialog, Transition } from "@headlessui/react";
// import { useSelector } from "react-redux";
// import Loader from "./Loader";
// import Message from "./Message";
// import { listSizes } from "../actions/sizeActions";
// import { Select } from "@chakra-ui/react";
// import { BiMessageSquareEdit } from "react-icons/bi";

// const EngravingInput = ({ item, setIsAccordionOpen }) => {
//   const dispatch = useDispatch();

//   const [addEngrave, setAddEngrave] = useState(
//     item.engraveMen !== "" || item.engraveWomen !== "" ? true : false
//   );

//   const [isConsentChecked, setIsConsentChecked] = useState(
//     item.engraveMen !== "" || item.engraveWomen !== "" ? true : false
//   );

//   const [engraveMenText, setEngraveMenText] = useState(item.engraveMen || "");
//   const [engraveWomenText, setEngraveWomenText] = useState(
//     item.engraveWomen || ""
//   );

//   const [giftWrap, setGiftWrap] = useState(item.giftWrap || false);
//   const [message, setMessage] = useState(item.message || "");

//   const [sizeMen, setSizeMen] = useState(item.sizeMen || 1);
//   const [sizeWomen, setSizeWomen] = useState(item.sizeWomen || 1);
//   let [isOpen, setIsOpen] = useState(false);

//   const sizeList = useSelector((state) => state.sizeList);
//   const { loading: loadingSize, error: errorSize, sizes } = sizeList;

//   useEffect(() => {
//     dispatch(listSizes());
//   }, [dispatch]);

//   const handleEngravingInputChange = (e, gender) => {
//     const value = e.target.value;
//     const lettersAndSpaces = /^[A-Za-z ]*$/; // Regex to allow only letters and spaces
//     if (lettersAndSpaces.test(value)) {
//       if (gender === "male") {
//         setEngraveMenText(value); // Update local state for men
//       } else {
//         setEngraveWomenText(value); // Update local state for women
//       }
//     }
//   };

//   const handleUpdateClick = () => {
//     // if (gender === "male") {
//     dispatch(
//       updateCartItem(
//         item?.product?._id,
//         item?.variant,
//         item?.qty,
//         sizeMen,
//         sizeWomen,
//         giftWrap,
//         message,
//         engraveMenText, // Use the local state for men's engraving
//         engraveWomenText // Keep the existing engraving for women
//       )
//     );
//     // } else {
//     //   dispatch(
//     //     updateCartItem(
//     //       item?.product?._id,
//     //       item?.variant,
//     //       item?.qty,
//     //       item?.sizeMen,
//     //       item?.sizeWomen,
//     //       item?.giftWrap,
//     //       item?.message,
//     //       item?.engraveMen, // Keep the existing engraving for men
//     //       engraveWomenText // Use the local state for women's engraving
//     //     )
//     //   );
//     // }
//     setIsAccordionOpen(false);
//   };

//   function closeModal() {
//     setIsOpen(false);
//   }

//   return (
//     <div className="flex flex-col gap-3">
//       <Transition appear show={isOpen} as={Fragment}>
//         <Dialog as="div" className="relative z-20" onClose={closeModal}>
//           <Transition.Child
//             as={Fragment}
//             enter="ease-out duration-300"
//             enterFrom="opacity-0"
//             enterTo="opacity-100"
//             leave="ease-in duration-200"
//             leaveFrom="opacity-100"
//             leaveTo="opacity-0"
//           >
//             <div className="fixed inset-0 bg-black/25" />
//           </Transition.Child>

//           <div className="fixed inset-0 overflow-y-auto">
//             <div className="flex min-h-full items-center justify-center p-4 text-center">
//               <Transition.Child
//                 as={Fragment}
//                 enter="ease-out duration-300"
//                 enterFrom="opacity-0 scale-95"
//                 enterTo="opacity-100 scale-100"
//                 leave="ease-in duration-200"
//                 leaveFrom="opacity-100 scale-100"
//                 leaveTo="opacity-0 scale-95"
//               >
//                 <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
//                   <Dialog.Title
//                     as="h3"
//                     className="text-lg font-medium leading-6 text-gray-900"
//                   >
//                     Size Chart(Indian Size)
//                   </Dialog.Title>
//                   <div className="mt-2">
//                     <table className="w-full border-collapse border border-slate-500">
//                       <tr className="text-center">
//                         <th className="w-1/3 border border-slate-600 p-1">
//                           Size
//                         </th>
//                         <th className="w-2/3 border border-slate-600 p-1">
//                           Internal Diameter(mm.)
//                         </th>
//                       </tr>
//                       {loadingSize ? (
//                         <Loader />
//                       ) : errorSize ? (
//                         <Message type="error">{errorSize}</Message>
//                       ) : (
//                         sizes?.map((size) => (
//                           <tr className="text-center">
//                             <td className="border border-slate-700 p-1">
//                               {size?.size}
//                             </td>
//                             <td className="border border-slate-700 p-1">
//                               {size?.internalDiameterMM} mm.
//                             </td>
//                           </tr>
//                         ))
//                       )}
//                     </table>
//                   </div>

//                   <div className="mt-4">
//                     <button
//                       className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
//                       onClick={closeModal}
//                     >
//                       Close
//                     </button>
//                   </div>
//                 </Dialog.Panel>
//               </Transition.Child>
//             </div>
//           </div>
//         </Dialog>
//       </Transition>

//       <div
//         className={`${
//           item?.product?.categories?.some((cat) => cat.name === "Rings")
//             ? "flex"
//             : "hidden"
//         } justify-between items-center`}
//       >
//         <div className="font-medium">Select Size</div>
//         <div
//           className="underline cursor-pointer text-[#000080] text-sm"
//           onClick={() => setIsOpen(true)}
//         >
//           Size Chart
//         </div>
//       </div>

//       <div
//         className={`${
//           item?.product?.categories?.some((cat) => cat.name === "Rings")
//             ? "flex"
//             : "hidden"
//         } items-center gap-4 border border-black p-2`}
//       >
//         {item?.product?.subcategories?.some(
//           (subcat) => subcat?.name === "Couple Rings"
//         ) ? (
//           <div className="flex gap-4">
//             <div className="flex flex-col gap-2">
//               <label htmlFor="sizeSelectMen" className="text-sm">
//                 Men
//               </label>
//               <Select
//                 id="sizeSelectMen"
//                 border="1px solid"
//                 rounded="none"
//                 _hover="none"
//                 className="px-4 pt-0"
//                 borderColor="#D1D5DB"
//                 value={sizeMen}
//                 onChange={(e) => setSizeMen(e.target.value)}
//                 required
//               >
//                 <option value={1}>Men Size</option>
//                 {loadingSize ? (
//                   <Loader />
//                 ) : errorSize ? (
//                   <Message type="error">{errorSize}</Message>
//                 ) : (
//                   sizes.map((num) => (
//                     <option key={num?.size} value={num?.size}>
//                       {num?.size}
//                     </option>
//                   ))
//                 )}
//               </Select>
//             </div>
//             <div className="flex flex-col gap-2">
//               <label htmlFor="sizeSelectWomen" className="text-sm">
//                 Women
//               </label>
//               <Select
//                 id="sizeSelectWomen"
//                 border="1px solid"
//                 rounded="none"
//                 _hover="none"
//                 className="px-4 pt-0"
//                 borderColor="#D1D5DB"
//                 value={sizeWomen}
//                 onChange={(e) => setSizeWomen(e.target.value)}
//                 required
//               >
//                 <option value={1}>Women Size</option>
//                 {loadingSize ? (
//                   <Loader />
//                 ) : errorSize ? (
//                   <Message type="error">{errorSize}</Message>
//                 ) : (
//                   sizes.map((num) => (
//                     <option key={num?.size} value={num?.size}>
//                       {num?.size}
//                     </option>
//                   ))
//                 )}
//               </Select>
//             </div>
//           </div>
//         ) : (
//           <div className="flex gap-4">
//             <div className="flex flex-col gap-2">
//               <label htmlFor="sizeSelectWomen" className="text-sm">
//                 Size
//               </label>
//               <Select
//                 id="sizeSelectWomen"
//                 border="1px solid"
//                 rounded="none"
//                 _hover="none"
//                 className="px-4 pt-0"
//                 borderColor="#D1D5DB"
//                 value={item?.product?.gender === "female" ? sizeWomen : sizeMen}
//                 onChange={(e) => {
//                   item?.product?.gender === "female"
//                     ? setSizeWomen(e.target.value)
//                     : setSizeMen(e.target.value);
//                 }}
//                 required
//               >
//                 <option value={1}>Size</option>
//                 {loadingSize ? (
//                   <Loader />
//                 ) : errorSize ? (
//                   <Message type="error">{errorSize}</Message>
//                 ) : (
//                   sizes.map((num) => (
//                     <option key={num?.size} value={num?.size}>
//                       {num?.size}
//                     </option>
//                   ))
//                 )}
//               </Select>
//             </div>
//           </div>
//         )}
//       </div>

//       <div
//         className={`${
//           item?.product?.categories?.some((cat) => cat.name === "Rings")
//             ? "flex"
//             : "hidden"
//         } items-center gap-1`}
//       >
//         <div className="font-medium">Engraving</div>
//         <div className="text-[12px]">(Charge Rs. 10 Per Character)</div>
//       </div>

//       <div
//         className={`${
//           item?.product?.categories?.some((cat) => cat.name === "Rings")
//             ? "flex"
//             : "hidden"
//         } flex-col gap-4 border border-black p-2`}
//       >
//         {/* <button
//           className="uppercase"
//           onClick={() => {
//             setEngraveMenText("");
//             setEngraveWomenText("");
//             setAddEngrave(!addEngrave);
//           }}
//         >
//           {addEngrave ? "Remove Engraving" : "Add Engraving"}
//         </button> */}
//         <button
//           onClick={() => {
//             setEngraveMenText("");
//             setEngraveWomenText("");
//             setAddEngrave(!addEngrave);
//             setIsConsentChecked(false);
//           }}
//           className={`flex gap-4 px-2 py-1 rounded-md text-sm uppercase ${
//             addEngrave ? "text-red-500" : "text-black"
//           }`}
//         >
//           <BiMessageSquareEdit className="w-5 h-5" />
//           <p>{addEngrave ? "Remove Engraving" : "Add Engraving"}</p>
//         </button>
//         {addEngrave && (
//           <div className="flex gap-4">
//             {item?.product?.subcategories?.some(
//               (subcat) => subcat?.name === "Couple Rings"
//             ) ? (
//               <div className="flex flex-col gap-4 w-full">
//                 <div className="flex flex-col gap-2 border-b border-gray-300">
//                   <label>Women</label>
//                   <input
//                     type="text"
//                     maxLength="10" // Limits input to 10 characters
//                     value={engraveMenText} // Controlled input using local state for men
//                     className="px-2 py-1.5 rounded text-sm w-full border-none"
//                     placeholder="Max 10 characters, letters only."
//                     onChange={(e) => handleEngravingInputChange(e, "male")} // Handle local input change for men
//                   />
//                 </div>
//                 <div className="flex flex-col gap-2 border-b border-gray-300">
//                   <label>Men</label>
//                   <input
//                     type="text"
//                     maxLength="10" // Limits input to 10 characters
//                     value={engraveWomenText} // Controlled input using local state for women
//                     className="px-2 py-1.5 rounded text-sm w-full border-none"
//                     placeholder="Max 10 characters, letters only."
//                     onChange={(e) => handleEngravingInputChange(e, "female")} // Handle local input change for women
//                   />
//                 </div>
//               </div>
//             ) : (
//               <div className="flex flex-col gap-4 w-full">
//                 <div className="flex flex-col gap-2 border-b border-gray-300">
//                   <lable>Engraving</lable>
//                   <input
//                     type="text"
//                     maxLength="10" // Limits input to 10 characters
//                     value={
//                       item?.product?.gender === "female"
//                         ? engraveWomenText
//                         : engraveMenText
//                     } // Controlled input using local state for men
//                     className="px-2 py-1.5 rounded text-sm w-full border-none"
//                     placeholder="Engraving text"
//                     onChange={(e) =>
//                       handleEngravingInputChange(
//                         e,
//                         item?.product?.gender === "female" ? "female" : "male"
//                       )
//                     } // Handle local input change for men
//                   />
//                 </div>
//               </div>
//             )}
//           </div>
//         )}
//         {addEngrave && (
//           <div className="flex gap-1">
//             <input
//               type="checkbox"
//               className="m-1"
//               checked={isConsentChecked}
//               onChange={(e) => setIsConsentChecked(e.target.checked)}
//             />
//             <p className="text-[12px]">
//               Please be aware that personalised products cannot be returned and
//               personalisation may affect the delivery time of your creation.
//               Please carefully read the{" "}
//               <a
//                 href="../terms-and-conditions.html"
//                 className="underline text-[#000080]"
//               >
//                 Terms & Conditions
//               </a>{" "}
//               and{" "}
//               <a
//                 href="../privacy-policy.html"
//                 className="underline text-[#000080]"
//               >
//                 Privacy Policy
//               </a>
//               .
//             </p>
//           </div>
//         )}
//       </div>

//       <div className="flex items-center gap-1">
//         <div className="font-medium">Gift Wrap</div>
//         <div className="text-[12px]">(Charge Rs. 50 Per Item Qty.)</div>
//       </div>

//       <div className="flex flex-col gap-4 border border-black p-2">
//         <div className="flex justify-around">
//           <div className="flex gap-6 items-center">
//             <input
//               type="radio"
//               name="giftWrap"
//               value="yes"
//               radioGroup="giftWrap"
//               checked={giftWrap === true}
//               onChange={() => setGiftWrap(true)}
//             />
//             <label>Yes</label>
//           </div>
//           <div className="flex gap-6 items-center">
//             <input
//               type="radio"
//               name="giftWrap"
//               value="no"
//               radioGroup="giftWrap"
//               checked={giftWrap === false}
//               onChange={() => setGiftWrap(false)}
//             />
//             <label>No</label>
//           </div>
//         </div>
//         {giftWrap && (
//           <textarea
//             placeholder="Enter Message For Gift"
//             value={message}
//             className="p-3 bg-slate-100 rounded-md"
//             onChange={(e) => setMessage(e.target.value)}
//             maxLength={100}
//           />
//         )}
//       </div>

//       <div className="flex gap-4 ml-2">
//         <button
//           onClick={() => handleUpdateClick()}
//           disabled={
//             (addEngrave && isConsentChecked) ||
//             (!addEngrave && !isConsentChecked)
//               ? false
//               : true
//           }
//           className={`bg-[#000080] text-white text-[14px] font-[600] px-2 py-1 rounded-md ${
//             (addEngrave && isConsentChecked) ||
//             (!addEngrave && !isConsentChecked)
//               ? ""
//               : "cursor-not-allowed"
//           }`}
//         >
//           Update
//         </button>
//         <button
//           onClick={() => setIsAccordionOpen(false)}
//           className="bg-red-700 text-white text-[14px] font-[600] px-2 py-1 rounded-md"
//         >
//           Cancel
//         </button>
//       </div>
//     </div>
//   );
// };

// export default EngravingInput;


























import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateCartItem } from "../actions/cartActions";
import { MdOutlineFileDownloadDone } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { Dialog, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import Loader from "./Loader";
import Message from "./Message";
import { listSizes } from "../actions/sizeActions";
import { Select } from "@chakra-ui/react";
import { BiMessageSquareEdit } from "react-icons/bi";
import ProductSet from "../components/ProductSet";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";

const EngravingInput = ({ item, setIsAccordionOpen }) => {

  // console.log(item.products);
  const [productSet, setProductSet] = useState(
    item.products.map((product) => product._id) || []
  );
  const [allowRingSize, setAllowRingSize] = useState(
    item.products.some((product) => product.sku.toUpperCase().includes("SAIR")) || false
  );
  const [selectedItems, setSelectedItems] = useState(item.products.map((product) => product.price) || []);
  const [selectedItemBasePrice, setSelectedItemBasePrice] = useState(item.products.map((product) => product.basePrice) || []);


  const dispatch = useDispatch();

  const [addEngrave, setAddEngrave] = useState(
    item.engraveMen !== "" || item.engraveWomen !== "" ? true : false
  );

  const [isConsentChecked, setIsConsentChecked] = useState(
    item.engraveMen !== "" || item.engraveWomen !== "" ? true : false
  );

  const [engraveMenText, setEngraveMenText] = useState(item.engraveMen || "");
  const [backEng, setbackEng] = useState(item.backEng || "");
  const [engImage, setEngImage] = useState(item.engImage || "");
  const [engraveWomenText, setEngraveWomenText] = useState(
    item.engraveWomen || ""
  );

  const [giftWrap, setGiftWrap] = useState(item.giftWrap || false);
  const [message, setMessage] = useState(item.message || "");

  const [sizeMen, setSizeMen] = useState(item.sizeMen || 1);
  const [sizeWomen, setSizeWomen] = useState(item.sizeWomen || 1);
  let [isOpen, setIsOpen] = useState(false);

  const sizeList = useSelector((state) => state.sizeList);
  const { loading: loadingSize, error: errorSize, sizes } = sizeList;

  useEffect(() => {
    dispatch(listSizes());
    
  }, [dispatch]);

  // console.log("Item:",item);

  const totalPrice =  productSet.length > 0 ? selectedItems.reduce((sum, price) => sum + price, 0) : item.product?.price;

  const totalBasePrice = productSet.length > 0 ? selectedItemBasePrice.reduce(
    (sum, basePrice) => sum + basePrice,
    0
  ) : item.product?.basePrice;


  const handleEngravingInputChange = (e, gender) => {
    const value = e.target.value;
    const lettersAndSpaces = /^[A-Za-z ]*$/; // Regex to allow only letters and spaces
    if (lettersAndSpaces.test(value)) {
      if (gender === "male") {
        setEngraveMenText(value); // Update local state for men
      } else {
        setEngraveWomenText(value); // Update local state for women
      }
    }
  };

    const notify = (link, content, type) =>
      type(<RouterLink to={link}>{content}</RouterLink>);

  const handleUpdateClick = () => {
    // if (gender === "male") {
  //   console.log("Selected Product:",productSet);
  // console.log("price:",totalPrice);
  // console.log("Base price:",totalBasePrice);

  console.log(allowRingSize);

if (productSet.length > 0 && productSet.length < 2) {
        notify("#", "Minimum 2 products required ", toast.error);
}else if(sizeMen == "0" && sizeWomen == "0"  && allowRingSize ){
    notify("#", "Please Select Size", toast.error);
}
else{
        dispatch(
          updateCartItem(
            item?.product?._id,
            item?.variant,
            item?.qty,
            sizeMen,
            sizeWomen,
            giftWrap,
            message,
            engraveMenText, // Use the local state for men's engraving
            engraveWomenText,
            backEng,
            engImage,
            productSet,
            totalPrice,
            totalBasePrice,
            // }
  
            // Keep the existing engraving for women
          )
        );

        setIsAccordionOpen(false);
      }


  
    // } else {
    //   dispatch(
    //     updateCartItem(
    //       item?.product?._id,
    //       item?.variant,
    //       item?.qty,
    //       item?.sizeMen,
    //       item?.sizeWomen,
    //       item?.giftWrap,
    //       item?.message,
    //       item?.engraveMen, // Keep the existing engraving for men
    //       engraveWomenText // Use the local state for women's engraving
    //     )
    //   );
    // }
    
  };

  function closeModal() {
    setIsOpen(false);
  }


  useEffect(()=>{

    if(item && item.product.products && productSet.length === item.product.products.length){
    
    setSizeMen(0);
    setSizeWomen(0); 
    }
    
    },[productSet,
      // item
      ])



        // console.log("prodeuct set",productSet?.length);
    // console.log("produxts",item.product.products.length);
    
// console.log(item);
//       console.log("Men",sizeMen);
//     console.log("Women",sizeWomen);




  return (
    <div className="flex flex-col gap-3">
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Size Chart(Indian Size)
                  </Dialog.Title>
                  <div className="mt-2">
                    <table className="w-full border-collapse border border-slate-500">
                      <tr className="text-center">
                        <th className="w-1/3 border border-slate-600 p-1">
                          Size
                        </th>
                        <th className="w-2/3 border border-slate-600 p-1">
                          Internal Diameter(mm.)
                        </th>
                      </tr>
                      {loadingSize ? (
                        <Loader />
                      ) : errorSize ? (
                        <Message type="error">{errorSize}</Message>
                      ) : (
                        sizes?.map((size) => (
                          <tr className="text-center">
                            <td className="border border-slate-700 p-1">
                              {size?.size}
                            </td>
                            <td className="border border-slate-700 p-1">
                              {size?.internalDiameterMM} mm.
                            </td>
                          </tr>
                        ))
                      )}
                    </table>
                  </div>

                  <div className="mt-4">
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
{
    allowRingSize || productSet.length === 0 ? (<>
    <div
          className={`${
            item?.product?.categories?.some(
              (cat) => cat.name === "Rings" || cat.name == "JEWELLERY SETS"
            )
              ? "flex"
              : "hidden"
          } justify-between items-center`}
        >
          <div className="font-medium">Select Size</div>
          <div
            className="underline cursor-pointer text-[#000080] text-sm"
            onClick={() => setIsOpen(true)}
          >
            Size Chart
          </div>
    </div>
      <div
        className={`${
          item?.product?.categories?.some(
            (cat) => cat.name === "Rings" || cat.name == "JEWELLERY SETS"
          )
            ? "flex"
            : "hidden"
        } items-center gap-4 border border-black p-2`}
      >
        {item?.product?.subcategories?.some(
          (subcat) => subcat?.name === "Couple Rings"
        ) ? (
          <div className="flex gap-4">
            <div className="flex flex-col gap-2">
              <label htmlFor="sizeSelectMen" className="text-sm">
                Men
              </label>
              <Select
                id="sizeSelectMen"
                border="1px solid"
                rounded="none"
                _hover="none"
                className="px-4 pt-0"
                borderColor="#D1D5DB"
                value={sizeMen}
                onChange={(e) => setSizeMen(e.target.value)}
                required
              >
                <option value={1}>Men Size</option>
                {loadingSize ? (
                  <Loader />
                ) : errorSize ? (
                  <Message type="error">{errorSize}</Message>
                ) : (
                  sizes.map((num) => (
                    <option key={num?.size} value={num?.size}>
                      {num?.size}
                    </option>
                  ))
                )}
              </Select>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="sizeSelectWomen" className="text-sm">
                Women
              </label>
              <Select
                id="sizeSelectWomen"
                border="1px solid"
                rounded="none"
                _hover="none"
                className="px-4 pt-0"
                borderColor="#D1D5DB"
                value={sizeWomen}
                onChange={(e) => setSizeWomen(e.target.value)}
                required
              >
                <option value={1}>Women Size</option>
                {loadingSize ? (
                  <Loader />
                ) : errorSize ? (
                  <Message type="error">{errorSize}</Message>
                ) : (
                  sizes.map((num) => (
                    <option key={num?.size} value={num?.size}>
                      {num?.size}
                    </option>
                  ))
                )}
              </Select>
            </div>
          </div>
        ) : 
        
        ( 
          <div className="flex gap-4">
            <div className="flex flex-col gap-2">
              <label htmlFor="sizeSelectWomen" className="text-sm">
                Size
              </label>
              <Select
                id="sizeSelectWomen"
                border="1px solid"
                rounded="none"
                _hover="none"
                className="px-4 pt-0"
                borderColor="#D1D5DB"
                value={item?.product?.gender === "female" ? sizeWomen : sizeMen}
                onChange={(e) => {
                  item?.product?.gender === "female"
                    ? setSizeWomen(e.target.value)
                    : setSizeMen(e.target.value);
                }}
                required
              >
                <option value={1}>Size</option>
                {loadingSize ? (
                  <Loader />
                ) : errorSize ? (
                  <Message type="error">{errorSize}</Message>
                ) : (
                  sizes.map((num) => (
                    <option key={num?.size} value={num?.size}>
                      {num?.size}
                    </option>
                  ))
                )}
              </Select>
            </div>
          </div>
        )}
      </div>
    </>) : null
 }
 




{
  item?.product?.categories?.some((cat) =>cat.name == "JEWELLERY SETS") ? (
    // <div><h1>Jewellery Set</h1></div>
    <ProductSet
    product={item.product}
    variantId={item.variant}
    productSet={productSet}
    setProductSet={setProductSet}
    selectedItems={selectedItems}
    setSelectedItems={setSelectedItems}
    selectedItemBasePrice={selectedItemBasePrice}
    setSelectedItemBasePrice={setSelectedItemBasePrice}
    totalPrice={totalPrice}
    totalbasePrice={totalBasePrice}
    allowRingSize={allowRingSize}
    setAllowRingSize={setAllowRingSize}
  />
  ): null
}
      <div
        className={`${
          item?.product?.categories?.some((cat) => cat.name === "Rings"  || cat.name == "JEWELLERY SETS")
            ? "flex"
            : "hidden"
        } items-center gap-1`}
      >
        <div className="font-medium">Engraving</div>
        <div className="text-[12px]">(Charge Rs. 10 Per Character)</div>
      </div>

      <div
        className={`${
          item?.product?.categories?.some((cat) => cat.name === "Rings"  || cat.name == "JEWELLERY SETS")
            ? "flex"
            : "hidden"
        } flex-col gap-4 border border-black p-2`}
      >
        {/* <button
          className="uppercase"
          onClick={() => {
            setEngraveMenText("");
            setEngraveWomenText("");
            setAddEngrave(!addEngrave);
          }}
        >
          {addEngrave ? "Remove Engraving" : "Add Engraving"}
        </button> */}
        <button
          onClick={() => {
            setEngraveMenText("");
            setEngraveWomenText("");
            setAddEngrave(!addEngrave);
            setIsConsentChecked(false);
          }}
          className={`flex gap-4 px-2 py-1 rounded-md text-sm uppercase ${
            addEngrave ? "text-red-500" : "text-black"
          }`}
        >
          <BiMessageSquareEdit className="w-5 h-5" />
          <p>{addEngrave ? "Remove Engraving" : "Add Engraving"}</p>
        </button>
        {addEngrave && (
          <div className="flex gap-4">
            {item?.product?.subcategories?.some(
              (subcat) => subcat?.name === "Couple Rings"
            ) ? (
              <div className="flex flex-col gap-4 w-full">
                <div className="flex flex-col gap-2 border-b border-gray-300">
                  <label>Women</label>
                  <input
                    type="text"
                    maxLength="10" // Limits input to 10 characters
                    value={engraveMenText} // Controlled input using local state for men
                    className="px-2 py-1.5 rounded text-sm w-full border-none"
                    placeholder="Max 10 characters, letters only."
                    onChange={(e) => handleEngravingInputChange(e, "male")} // Handle local input change for men
                  />
                </div>
                <div className="flex flex-col gap-2 border-b border-gray-300">
                  <label>Men</label>
                  <input
                    type="text"
                    maxLength="10" // Limits input to 10 characters
                    value={engraveWomenText} // Controlled input using local state for women
                    className="px-2 py-1.5 rounded text-sm w-full border-none"
                    placeholder="Max 10 characters, letters only."
                    onChange={(e) => handleEngravingInputChange(e, "female")} // Handle local input change for women
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-4 w-full">
                <div className="flex flex-col gap-2 border-b border-gray-300">
                  <lable>Engraving</lable>
                  <input
                    type="text"
                    maxLength="10" // Limits input to 10 characters
                    value={
                      item?.product?.gender === "female"
                        ? engraveWomenText
                        : engraveMenText
                    } // Controlled input using local state for men
                    className="px-2 py-1.5 rounded text-sm w-full border-none"
                    placeholder="Engraving text"
                    onChange={(e) =>
                      handleEngravingInputChange(
                        e,
                        item?.product?.gender === "female" ? "female" : "male"
                      )
                    } // Handle local input change for men
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {addEngrave && (
          <div className="flex gap-1">
            <input
              type="checkbox"
              className="m-1"
              checked={isConsentChecked}
              onChange={(e) => setIsConsentChecked(e.target.checked)}
            />
            <p className="text-[12px]">
              Please be aware that personalised products cannot be returned and
              personalisation may affect the delivery time of your creation.
              Please carefully read the{" "}
              <a
                href="../terms-and-conditions.html"
                className="underline text-[#000080]"
              >
                Terms & Conditions
              </a>{" "}
              and{" "}
              <a
                href="../privacy-policy.html"
                className="underline text-[#000080]"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        )}
      </div>

      {/* {product?.productCollections?.some(
        (cat) => cat.name == "Pet Collection"
      ) &&
        product?.canEngrave && (
          <Accordion
            allowMultiple
            onChange={() => handleAccordionToggle(!isAccordionOpen)}
            my="6"
          >
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <FaRegEdit className="w-5 h-5" />
                  <Box as="span" flex="1" textAlign="left">
                    Add Engraving
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <div className="w-full md:w-[60%] relative">
                  <img
                    className="w-full aspect-[3/2] object-cover object-center"
                    src={product.frontPreviewImg}
                  />
                  <div className="flex text-4xl font-ScriptMTBold absolute inset-0 items-center justify-center z-10 text-center">
                    {engravingTextWomen}
                  </div>
                </div>
                <input
                  type="text"
                  maxLength="10" // Limits input to 10 characters
                  pattern="[A-Za-z]*" // Allows only letters and spaces
                  value={engravingTextWomen} // Controlled input
                  className="border px-2 py-1 rounded"
                  placeholder="Front Engraving Text"
                  onChange={(e) =>
                    handleEngravingInput(e.target.value, "female")
                  } // Handle engraving input
                />
                <p className="text-sm text-gray-500">
                  Max 10 characters, letters only.
                </p>
              </AccordionPanel>
              {product?.canBackEng && (
                <AccordionPanel pb={4}>
                  <div className="w-full md:w-[60%] relative">
                    <img
                      className="w-full aspect-[3/2] object-cover object-center"
                      src={product.backPreviewImg}
                    />
                    <div className="flex text-4xl font-MvBoli absolute inset-0 items-center justify-center z-10 text-center">
                      {backEng}
                    </div>
                  </div>
                  <input
                    type="text"
                    maxLength="10" // Limits input to 10 characters
                    pattern="[A-Za-z]*" // Allows only letters and spaces
                    value={backEng} // Controlled input
                    className="border px-2 py-1 rounded"
                    placeholder="Back Engraving Text"
                    onChange={(e) => setbackEng(e.target.value)} // Handle engraving input
                  />
                  <p className="text-sm text-gray-500">
                    Max 10 characters, letters only.
                  </p>
                </AccordionPanel>
              )}
            </AccordionItem>
          </Accordion>
        )} */}

      <div
        className={`${
          item?.product?.productCollections?.some(
            (cat) => cat.name === "Pet Collection"
          )
            ? "flex"
            : "hidden"
        } flex-col gap-4 border border-black p-2`}
      >
        {/* <button
          className="uppercase"
          onClick={() => {
            setEngraveMenText("");
            setEngraveWomenText("");
            setAddEngrave(!addEngrave);
          }}
        >
          {addEngrave ? "Remove Engraving" : "Add Engraving"}
        </button> */}
        <button
          onClick={() => {
            setEngraveMenText("");
            setbackEng("");
            setEngraveWomenText("");
            setAddEngrave(!addEngrave);
            setIsConsentChecked(false);
          }}
          className={`flex gap-4 px-2 py-1 rounded-md text-sm uppercase ${
            addEngrave ? "text-red-500" : "text-black"
          }`}
        >
          <BiMessageSquareEdit className="w-5 h-5" />
          <p>{addEngrave ? "Remove Engraving" : "Add Engraving"}</p>
        </button>
        {addEngrave && (
          <div className="flex gap-4">
            <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-col gap-2 border-b border-gray-300">
                <label>Front Engraving Text</label>
                <input
                  type="text"
                  maxLength="10" // Limits input to 10 characters
                  value={engraveWomenText} // Controlled input using local state for men
                  className="px-2 py-1.5 rounded text-sm w-full border-none"
                  placeholder="Max 10 characters, letters only."
                  onChange={(e) => handleEngravingInputChange(e, "female")} // Handle local input change for men
                />
              </div>
              {item?.product?.canBackEng && (
                <div className="flex flex-col gap-2 border-b border-gray-300">
                  <label>Back Engraving Text</label>
                  <input
                    type="text"
                    maxLength="10" // Limits input to 10 characters
                    value={backEng} // Controlled input using local state for women
                    className="px-2 py-1.5 rounded text-sm w-full border-none"
                    placeholder="Max 10 characters, letters only."
                    onChange={(e) => setbackEng(e.target.value)} // Handle local input change for women
                  />
                </div>
              )}
            </div>
            {/* <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-col gap-2 border-b border-gray-300">
                <lable>Engraving</lable>
                <input
                  type="text"
                  maxLength="10" // Limits input to 10 characters
                  value={
                    item?.product?.gender === "female"
                      ? engraveWomenText
                      : engraveMenText
                  } // Controlled input using local state for men
                  className="px-2 py-1.5 rounded text-sm w-full border-none"
                  placeholder="Engraving text"
                  onChange={(e) =>
                    handleEngravingInputChange(
                      e,
                      item?.product?.gender === "female" ? "female" : "male"
                    )
                  } // Handle local input change for men
                />
              </div>
            </div> */}
          </div>
        )}
        {addEngrave && (
          <div className="flex gap-1">
            <input
              type="checkbox"
              className="m-1"
              checked={isConsentChecked}
              onChange={(e) => setIsConsentChecked(e.target.checked)}
            />
            <p className="text-[12px]">
              Please be aware that personalised products cannot be returned and
              personalisation may affect the delivery time of your creation.
              Please carefully read the{" "}
              <a
                href="../terms-and-conditions.html"
                className="underline text-[#000080]"
              >
                Terms & Conditions
              </a>{" "}
              and{" "}
              <a
                href="../privacy-policy.html"
                className="underline text-[#000080]"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        )}
      </div>

      <div className="flex items-center gap-1">
        <div className="font-medium">Gift Wrap</div>
        <div className="text-[12px]">(Charge Rs. 50 Per Item Qty.)</div>
      </div>

      <div className="flex flex-col gap-4 border border-black p-2">
        <div className="flex justify-around">
          <div className="flex gap-6 items-center">
            <input
              type="radio"
              name="giftWrap"
              value="yes"
              radioGroup="giftWrap"
              checked={giftWrap === true}
              onChange={() => setGiftWrap(true)}
            />
            <label>Yes</label>
          </div>
          <div className="flex gap-6 items-center">
            <input
              type="radio"
              name="giftWrap"
              value="no"
              radioGroup="giftWrap"
              checked={giftWrap === false}
              onChange={() => setGiftWrap(false)}
            />
            <label>No</label>
          </div>
        </div>
        {giftWrap && (
          <textarea
            placeholder="Enter Message For Gift"
            value={message}
            className="p-3 bg-slate-100 rounded-md"
            onChange={(e) => setMessage(e.target.value)}
            maxLength={100}
          />
        )}
      </div>

      <div className="flex gap-4 ml-2">
        <button
          onClick={() => handleUpdateClick()}
          disabled={
            (addEngrave && isConsentChecked) ||
            (!addEngrave && !isConsentChecked)
              ? false
              : true
          }
          className={`bg-[#000080] text-white text-[14px] font-[600] px-2 py-1 rounded-md ${
            (addEngrave && isConsentChecked) ||
            (!addEngrave && !isConsentChecked)
              ? ""
              : "cursor-not-allowed"
          }`}
        >
          Update
        </button>
        <button
          onClick={() => setIsAccordionOpen(false)}
          className="bg-red-700 text-white text-[14px] font-[600] px-2 py-1 rounded-md"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EngravingInput;
