// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAZDRWxNzFScemA9mFZSg9jNq6bwcK14xw",
  authDomain: "sairahjewels-e5606.firebaseapp.com",
  projectId: "sairahjewels-e5606",
  storageBucket: "sairahjewels-e5606.appspot.com",
  messagingSenderId: "400603973389",
  appId: "1:400603973389:web:8ce6597b86e8bdc7101244"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);