import { Carousel } from "react-responsive-carousel";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { listHeroFooter } from "../actions/heroActions";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Message from "./Message";
import Loader from "./Loader";

const getConfigurableProps = () => ({
  showArrows: true,
  showStatus: false,
  showIndicators: true,
  infiniteLoop: true,
  showThumbs: false,
  useKeyboardArrows: true,
  autoPlay: true,
  stopOnHover: true,
  swipeable: true,
  dynamicHeight: false,
  emulateTouch: true,
  autoFocus: false,
  thumbWidth: 100,
  selectedItem: 0,
  interval: 4000,
  transitionTime: 900,
  ariaLabel: "ariaLabel",
});

export default function FooterHero() {
  const dispatch = useDispatch();

  const heroListFooter = useSelector((state) => state.heroListFooter);
  const { loading, heros, error } = heroListFooter;

  const [country, setCountry] = useState("india");

  const { t, i18n } = useTranslation();
  let lang = i18n.language;

  useEffect(() => {
    if (lang === "en") {
      setCountry("india");
    } else {
      setCountry("germany");
    }
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(listHeroFooter(country, "footerHero"));
  }, [country, dispatch]);

  return (
    <div className="bg-white">
      {loading ? (
        <div className="w-full bg-white animate-pulse">
          <div className="flex items-center justify-center bg-slate-200 bg-no-repeat bg-animate animate-pulse">
            <Loader />
          </div>
        </div>
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <div className="w-full bg-white">
          <Carousel {...getConfigurableProps()}>
            {heros.map((hero, index) => (
              <div key={index}>
                <RouterLink className="block h-full" to={`${hero.link}`}>
                  <img loading="lazy" src={`${hero.url}`} alt="banner" />
                </RouterLink>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
}
