import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Message from "./Message";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import "./Category.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { listProducts } from "../actions/productActions";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <MdNavigateNext
      className={className}
      style={{
        color: "gray",
        fontSize: "45px",
        height: "55px",
        width: "55px",
        paddingLeft: "20px",
      }}
      onClick={onClick}
    ></MdNavigateNext>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <MdNavigateBefore
      className={className}
      style={{
        color: "gray",
        fontSize: "45px",
        height: "55px",
        width: "55px",
        paddingRight: "20px",
      }}
      onClick={onClick}
    ></MdNavigateBefore>
  );
}

export default function NewArrivals() {
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    // prevArrow: <SamplePrevArrow />,
    // nextArrow: <SampleNextArrow />,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(
      listProducts(
        null,
        null,
        null,
        true,
        null,
        null,
        null,
        null,
        "latest",
        "desc",
        null
      )
    );
  }, [dispatch]);

  return (
    <div className={`mx-auto max-w-7xl px-4 sm:px-6 lg:px-8`}>
      <p className="text-center text-xl font-semibold py-10">NEW ARRIVALS</p>
      {loading ? (
        <div></div>
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <Slider
          {...settings}
        >
          {products?.map((prod) => (
            <div
              className={`relative cursor-pointer px-3 md:px-6 ease-in transition hover:scale-105`}
            >
              <RouterLink
                className="block"
                to={`/product/${prod.name?.replaceAll(" ", "-")}/${
                  prod._id
                }?variant=${
                  prod?.variants?.length > 0 &&
                  prod?.variants?.filter((variant) => variant.isPrimary)[0]?._id
                }`}
              >
                <img
                  src={
                    prod?.variants?.filter((vari) => vari.isPrimary)[0]
                      ?.images[0]
                  }
                  loading="lazy"
                  alt="prod1"
                  className="aspect-1 object-center object-cover"
                />
              </RouterLink>
              <div className="absolute text-[12px] text-[500] px-3 py-0.5 top-2 left-8 bg-[#0A38DB] text-white">
                NEW
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
}
