import { Flex, Image } from "@chakra-ui/react";

const Loader = () => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Image
        width="150px"
        src="https://d8v879q3glvao.cloudfront.net/products/1730092605925.gif"
      />
    </Flex>
  );
};

export default Loader;
