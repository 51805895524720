import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Select,
  Button,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from "@chakra-ui/react";
import React from "react";
import ProductSet from "./ProductSet";
import RingSize from "./RingSize";
import SizeChart from "./SizeChart";
import MeasuringGuide from "./MeasuringGuide";
import AddEngravingSection from "./AddEngravingSection";
import Quantity from "./Quantity";
import ImageEngravingSection from "./ImageEngravingSection";

const CartDrawer = ({ ...props }) => {
  // const selectedProductDetails = {
  //     name: props.selectedProduct?.name,
  //     image:
  //     props.selectedProduct?.variants?.length > 0 &&
  //     props.selectedProduct?.variants?.filter((variant) =>
  //         props.selectedPlating.length > 0
  //           ? props.selectedPlating.includes(variant.color)
  //           : variant.isPrimary
  //       )[0]?.images[0],
  //     qty: props.qty,
  //     sizeMen: props.selectedSizeMen,
  //     sizeWomen: props.selectedSizeWomen,
  //     price: props.productSet.length > 0 ? props.totalPrice : props.selectedProduct?.price,
  //     basePrice:
  //     props.productSet.length > 0 ? props.totalBasePrice : props.selectedProduct?.basePrice,

  //     product: props.selectedProduct?._id,
  //     products: props.productSet,
  //     variant:
  //     props.selectedProduct?.variants?.length > 0 &&
  //     props.selectedProduct?.variants?.filter((variant) => variant?.isPrimary)[0]
  //         ?._id,
  //     sku: props.selectedProduct?.sku,
  //     giftWrap: false,
  //     message: "",
  //     engraveMen: props.engravingTextMen,
  //     engraveWomen: props.engravingTextWomen,
  //     isPair: false,
  //     backEng: props.backEng,
  //     engImage: props.engImage,
  //   };


//   console.log(props.selectedSizeWomen);
// console.log(props.selectedSizeMen);

  return (
    <Drawer onClose={props.onClose} isOpen={props.isOpen} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{`Select size and quantity`}</DrawerHeader>
        <DrawerBody>
          <form
            onSubmit={(e) =>
              props.handleAddToCart(e, props.selectedProductDetails)
            }
          >
            <Tabs isManual variant="enclosed">
              <TabList>
                <Tab>Select Size/Add Engraving</Tab>
                <Tab>Size Chart(Indian Size)</Tab>
                <Tab>How to measure</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {props.selectedProduct?.categories.some(
                    (cat) => cat.name == "JEWELLERY SETS"
                  ) ? (
                    <ProductSet
                      product={props.selectedProduct}
                      variantId={
                        props.selectedProduct?.variants?.length > 0 &&
                        props.selectedProduct?.variants?.filter((variant) =>
                          props.selectedPlating.length > 0
                            ? props.selectedPlating.includes(variant.color)
                            : variant.isPrimary
                        )[0]?._id
                      }
                      productSet={props.productSet}
                      setProductSet={props.setProductSet}
                      selectedItems={props.selectedItems}
                      setSelectedItems={props.setSelectedItems}
                      selectedItemBasePrice={props.selectedItemBasePrice}
                      setSelectedItemBasePrice={props.setSelectedItemBasePrice}
                      totalPrice={props.totalPrice}
                      totalbasePrice={props.totalbasePrice}
                      allowRingSize={props.allowRingSize}
                      setAllowRingSize={props.setAllowRingSize}
                    />
                  ) : null}

                  {props.allowRingSize || props.productSet.length === 0 ? (
                    <AddEngravingSection
                      selectedProduct={props.selectedProduct}
                      handleAccordionToggle={props.handleAccordionToggle}
                      isAccordionOpen={props.isAccordionOpen}
                      engravingTextMen={props.engravingTextMen}
                      engravingTextWomen={props.engravingTextWomen}
                      handleEngravingInput={props.handleEngravingInput}
                      backEng={props.backEng}
                      setbackEng={props.setbackEng}
                    />
                  ) : null}

                  <div className="flex flex-col gap-6 my-6">
                    <Quantity
                      qty={props.qty}
                      handleQuantityChange={props.handleQuantityChange}
                    />

                    {props.selectedProduct?.canImgEng && (
                      <ImageEngravingSection
                        uploadImageHandler={props.uploadImageHandler}
                        setengImage={props.setengImage}
                      />
                    )}

                    {
                      // selectedProduct?.categories?.find(
                      //   (cat) =>
                      //     cat.name === "Rings" ||
                      //     cat.name === "JEWELLERY SETS"
                      // ) &&

                      props.allowRingSize || props.productSet.length === 0 ? (
                        <RingSize
                          selectedProduct={props.selectedProduct}
                          selectedSizeMen={props.selectedSizeMen}
                          setSelectedSizeMen={props.setSelectedSizeMen}
                          selectedSizeWomen={props.selectedSizeWomen}
                          setSelectedSizeWomen={props.setSelectedSizeWomen}
                          loadingSize={props.loadingSize}
                          errorSize={props.errorSize}
                          sizes={props.sizes}
                        />
                      ) : null
                    }
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="mt-2 max-h-[60vh] overflow-y-auto">
                    <SizeChart
                      loadingSize={props.loadingSize}
                      errorSize={props.errorSize}
                      sizes={props.sizes}
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <MeasuringGuide />
                </TabPanel>
              </TabPanels>
            </Tabs>
            <Button
              type="submit"
              isDisabled={
                props.productSet.length <= 1
                  ? props.selectedProduct?.categories.some(
                      (prod) =>
                        prod.name === "Rings" || prod.name == "JEWELLERY SETS"
                    ) && props.selectedProduct?.gender === "female"
                    ? props.selectedSizeWomen === 0
                    : props.selectedProduct?.categories.some(
                        (prod) =>
                          prod.name === "Rings" || prod.name == "JEWELLERY SETS"
                      ) && props.selectedProduct?.gender === "male"
                    ? props.selectedSizeMen === 0
                    : props.selectedProduct?.productCollections.some(
                        (prod) => prod.name === "Pet Collection"
                      ) && props.selectedProduct?.canImgEng
                    ? props.engImage === ""
                    : false
                  : props.allowRingSize
                  ? props.selectedSizeWomen === 0
                  : false
              }
              bgColor="#000080"
              textColor="white"
              _hover={{ bgColor: "#000090" }}
            >
              Next
            </Button>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default CartDrawer;
