import { Carousel } from "react-responsive-carousel";
// import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { listHeroLatest } from "../actions/heroActions";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Message from "./Message";
import Loader from "./Loader";
import axios from "axios";

const getConfigurableProps = () => ({
    showArrows: true,
    showStatus: false,
    showIndicators: true,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: true,
    autoPlay: true,
    stopOnHover: true,
    swipeable: true,
    dynamicHeight: false,
    emulateTouch: true,
    autoFocus: false,
    thumbWidth: 100,
    selectedItem: 0,
    interval: 4000,
    transitionTime: 900,
    ariaLabel: "ariaLabel",
});


function HomeBanner({ category }) {

    // const dispatch = useDispatch();

    // const heroListLatest = useSelector((state) => state.heroListLatest);
    // const { loading, heros, error } = heroListLatest;

    const [loading, heros, error] = CustomReactQuery(category);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // const [country, setCountry] = useState("india");

    const { t, i18n } = useTranslation();
    let lang = i18n.language;

    // useEffect(() => {
    //     dispatch(listHeroLatest(country, category));
    // }, [country, dispatch]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="bg-white pt-10">
            {loading ? (
                <div className="w-full min-h-[20vh] md:min-h-[70vh] bg-white animate-pulse">
                    <div className="flex items-center justify-center min-h-[20vh] md:min-h-[70vh] bg-slate-200 bg-no-repeat bg-animate animate-pulse">
                        <Loader />
                    </div>
                </div>
                // ) : error ? (
                //     <Message type="error">{error}</Message>
                // renderIndicator={heros.length < 2 ? (onClickHandler, isSelected, index, label) => <div></div> : null}
            ) : (
                <div className="w-full bg-white">
                    <Carousel {...getConfigurableProps()} > 
                        {heros.map((hero, index) => (
                            <div key={index}>
                                <RouterLink className="block h-full" to={`${hero.link}`}>
                                <img
                                    loading="lazy"
                                    src={windowWidth <= 768 ? hero.urlMobImg : hero.url}
                                    alt="banner"
                                    className="w-full h-full object-cover object-center"
                                />
                                </RouterLink>
                            </div>
                        ))}

                    </Carousel>
                </div>
            )}
        </div>
    )
}

export default HomeBanner


const CustomReactQuery = (category) => {
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        ; (async () => {
            try {
                setLoading(true)
                setError(null)
                const response = await axios.get(`https://sairahjewels.com/api/hero?country=india&category=${category}`)
                console.log(`Banner Data -> ${response.data}`)
                setData(response.data.heros)
                setLoading(false)
            } catch (error) {
                console.log(error.response.data.message)
                setError(error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message)
                setLoading(false)
            }
        })()
    }, [])

    return [loading, data, error]
}