// sizeConstants.js
export const SIZE_LIST_REQUEST = 'SIZE_LIST_REQUEST';
export const SIZE_LIST_SUCCESS = 'SIZE_LIST_SUCCESS';
export const SIZE_LIST_FAIL = 'SIZE_LIST_FAIL';

export const SIZE_DETAILS_REQUEST = 'SIZE_DETAILS_REQUEST';
export const SIZE_DETAILS_SUCCESS = 'SIZE_DETAILS_SUCCESS';
export const SIZE_DETAILS_FAIL = 'SIZE_DETAILS_FAIL';

export const SIZE_CREATE_REQUEST = 'SIZE_CREATE_REQUEST';
export const SIZE_CREATE_SUCCESS = 'SIZE_CREATE_SUCCESS';
export const SIZE_CREATE_FAIL = 'SIZE_CREATE_FAIL';
export const SIZE_CREATE_RESET = 'SIZE_CREATE_RESET';

export const SIZE_UPDATE_REQUEST = 'SIZE_UPDATE_REQUEST';
export const SIZE_UPDATE_SUCCESS = 'SIZE_UPDATE_SUCCESS';
export const SIZE_UPDATE_FAIL = 'SIZE_UPDATE_FAIL';
export const SIZE_UPDATE_RESET = 'SIZE_UPDATE_RESET';

export const SIZE_DELETE_REQUEST = 'SIZE_DELETE_REQUEST';
export const SIZE_DELETE_SUCCESS = 'SIZE_DELETE_SUCCESS';
export const SIZE_DELETE_FAIL = 'SIZE_DELETE_FAIL';
