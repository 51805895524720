import React from 'react'

const Quantity = ({handleQuantityChange,qty}) => {
  return (
    <div>
                                          <label htmlFor="quantitySelect">
                                            Quantity:
                                          </label>
                                          <div className="flex items-center">
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleQuantityChange(
                                                  qty > 1 ? qty - 1 : 1
                                                )
                                              }
                                              className="px-4 py-2 bg-[#F3F3F3] text-[#000080] font-bold text-xl focus:outline-none"
                                            >
                                              -
                                            </button>
                                            <input
                                              type="text"
                                              id="quantitySelect"
                                              value={qty}
                                              onChange={(e) =>
                                                handleQuantityChange(
                                                  Number(e.target.value)
                                                )
                                              }
                                              className="px-4 py-2 w-[45px] text-center text-xl bg-[#F3F3F3] focus:outline-none border-none"
                                              readOnly
                                            />
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleQuantityChange(
                                                  qty < 5 ? qty + 1 : 5
                                                )
                                              }
                                              className="px-4 py-2 bg-[#F3F3F3] text-[#000080] font-bold text-xl focus:outline-none"
                                            >
                                              +
                                            </button>
                                          </div>
                                        </div>
  )
}

export default Quantity